export default {
  inclusions : {
    showWithPlanInclusion:false,
    showInclusion: false,
    showTitpInclusion:false,
    showSmartPlannerInclusion:false,
  },
  employerdata:'',
  paymentData:'',
};
