export default {
    jobseeker:'',
    commonJobTypes:[
        {"name": "Permanent" , "key": "em-tbl1-chkbox1"},
        {"name": "Temporary", "key": "em-tbl1-chkbox2"},
        {"name": "Contract", "key": "em-tbl1-chkbox3"},
        {"name": "Part Time", "key": "em-tbl1-chkbox4"},
        {"name": "Freelance", "key": "em-tbl1-chkbox5"},
        {"name": "Intern", "key": "em-tbl1-chkbox6"},
        {"name": "Other", "key": "em-tbl1-chkbox7"}
    ],
    commonExperienceLevel: [
        {"name": "Internship", "key":"eanj-rd2"},
        {"name": "Entry level", "key":"eanj-rd3"},
        {"name": "Associate", "key":"eanj-rd4"},
        {"name": "Mid-Senior level", "key":"eanj-rd5"},
        {"name": "Director", "key":"eanj-rd7"},
        {"name": "Executive", "key":"eanj-rd6"}
    ],
    commonJobStatus: [
        {"name": "OPEN"},
        {"name": "INTERVIEW"},
        {"name": "OFFER"},
        {"name": "SIGNED OFF"},
        {"name": "CLOSED"}
    ],
    ///////////////
    webLang:null,
    UserPersonalData:[],
    UserEducationData:[],
    UserEmploymentData:[],
    UserMatchingData:[],
    UserDocumentData:[],
    profileTypeCount: '',
    resumeName: '',
    resumeLanguage: '',
    sendingOrganizations : [],

}