export default {
    agreemntList: {
		data: null,
		links: null,
		meta: null
	},
    paymentStatus :'',
    inclusionsSettings:'',
    smartPlannerData:'',
    jiipaData:'',
    employerData:'',
    // agreements: [],
    // metaData: {},
    // agreementId: '',
}
