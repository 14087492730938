export const getshowJobSeekerRegistered = (state) => {
	return state.showJobSeekerRegistered 
}

export const getEmployerAnalytics = (state) => {
	return state.analytics.employer
}

export const getJobseekerAnalytics = (state) => {
	return state.analytics.jobseeker
}

export const getEmployerJobseekerAnalytics = (state) => {
	return state.analytics.employerJobseeker
}

export const getJobfairAdvertisementReferralAnalytics = (state) => {
	return state.analytics.jobfairAdvertisementReferral
}