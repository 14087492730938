export default [
	{
		path: '/my-tasks/payment',
		component: () => import('../components/MyTaskParent.vue'),
		name: 'my-tasks',
		meta: {			
		},
		children:[
			{
				path: '/my-tasks/payment',
				component: () => import('../components/MyTaskPayment.vue'),
				name: 'my-tasks-payment',
			},
			{
				path: '/my-tasks/jobs',
				component: () => import('../components/MyTaskJobs.vue'),
				name: 'my-tasks-jobs',
			},
			{
				path: '/my-tasks/projects',
				component: () => import('../components/MyTaskProjects.vue'),
				name: 'my-tasks-projects',
			},
			{
				path: '/my-tasks/advertisements',
				component: () => import('../components/MyTaskAdvertisements.vue'),
				name: 'my-tasks-advertisements',
			},
			{
				path: '/my-tasks/job-fairs',
				component: () => import('../components/MyTaskJobFairs.vue'),
				name: 'my-tasks-job-fairs',
			},
			{
				path: '/my-tasks/refferals',
				component: () => import('../components/MyTaskRefferals.vue'),
				name: 'my-tasks-refferals',
			},
			{
				path: '/my-tasks/agreements',
				component: () => import('../components/MyTaskAgreements.vue'),
				name: 'my-tasks-agreements',
			},
			{
				path: '/my-tasks/scout-mail',
				component: () => import('../components/MyTaskScoutMail.vue'),
				name: 'my-tasks-scout-mail',
			},
		]
	},

]