export default [
  {
    path: "/request-relocation",
    component: () => import("../components/RequestForRelocation.vue"),
    name: "relocationService",
    meta: {},
  },
  {
    path: "/relocation-service",
    component: () => import("../components/Relocation.vue"),
    name: "relocationServiceHome",
  },
];
