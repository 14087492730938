<template>
	<div>
		<div class="container mx-auto flex justify-center h-screen">
			<div class="space-y-3 py-16 lg:py-24">
				<div class="text-9xl text-extrabold text-center text-red-600 mb-10 mt-10">
					<label>404</label>
				</div>
				<div class="text-xl font-bold text-white w-72 px-4 border bg-gray-600 py-3 rounded-lg text-center">
					<label>This is not the web page you are looking for. </label>					
				</div>
				<div class="text-center py-4">
					<a href="/" class="text-blue-500 hover:text-blue-600 hover:underline font-semibold ">Visit Homepage</a>					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PageNotFound',
	
};
</script>