import { createRouter, createWebHistory } from "vue-router";
import { routes } from "../app/index";

import Profile from '../views/Profile.vue'
import SignUpJob from '../views/SignUpJob.vue'

// import ResetPassword from '../views/ResetPassword.vue'
// import ForgotPassword from '../views/ForgotPassword.vue'
// import SignUpEmployer from '../views/SignUpEmployer.vue'
// import ResetPassword from '../views/ResetPassword.vue'
// import ForgotPassword from '../views/ForgotPassword.vue'
// import Home from "../views/Home.vue";
import DataTable from '../views/DataTable.vue'

import PageNotFound from '../views/PageNotFound.vue'
import ManageJobs from "../views/ManageJobs.vue";
import AddNewJob from "../views/AddNewJob.vue";
import Table from "../views/Table.vue";
import Trial from "../views/Trial.vue";

// import OutsourcingTender from "../views/OutsourcingTender.vue";

import beforeEach from './beforeEach'

const otherRoutes = [
  {
    path: "/Profile",
    name: "Profile",
    component: () => import("../views/Profile.vue"),
  },
  {
    path: "/maanage-jobss",
    name: "managejobs",
    component: () => import("../views/ManageJobs.vue"),
  },

  /*{
  path: '/sign-in',
  name: 'sign-in',
  component: () => import('../views/SignIn.vue')

},*/

  /*{
  path: '/sign-up',
  name: 'sign-up',
  component: () => import('../views/SignUp.vue')
},*/

  {
    path: "/sign-up-job",
    name: "sign-up-job",
    component: () => import("../views/SignUpJob.vue"),
  },
  // {
  //   path: "/job-seeker",
  //   name: "job-seeker",
  //   component: () => import("../views/JobSeeker.vue"),
  // },


  // {
  //   path: "/employer",
  //   name: "employer",
  //   component: () => import("../views/Employeer.vue"),
  // },


  //  {

  //   path: "/relocation-service",
  //   name: "relocationServiceHome",
  //   component: () => import("../views/Relocation.vue"),
  // },
  // {
  //   path: "/request-relocation",
  //   name: "request-relocation",
  //   component: () => import("../views/RequestForRelocation.vue"),

  // },

  // {
  //   path: "/titp",
  //   name: "titp",
  //   component: () => import("../views/Titp.vue"),
  // },

  // {
  //   path: '/sign-up-employer',
  //   name: 'sign-up-employer',
  //   component: () => import('../views/SignUpEmployer.vue')
  // },

  /*{
},
{
  path: '/sign-up-job',
  name: 'sign-up-job',
  component: () => import('../views/SignUpJob.vue')
},
{
  path: '/sign-up-employer',
  name: 'sign-up-employer',
  component: () => import('../views/SignUpEmployer.vue')
},


 {
     path: '/datatable',
     name: 'datatable',
     component: () => import('../views/DataTable.vue')
{
    path: '/change-password',
    name: 'change-password',
    component: () => import('../views/ChangePassword.vue')
 },  */
  {
    path: "/datatable",
    name: "datatable",
    component: () => import("../views/DataTable.vue"),
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
  // {
  //   path: "/dashboard",
  //   name: "DashBoard",
  //   component: () => import("../views/DashBoard.vue"),
  // },

  {
    path: "/manage-projects",
    name: "manage-projects",
    // component: () => import('../views/manage-projects')
  },
 
  
  // {
  //   path: "/job-fair",
  //   name: "job-fair",
  //   component: () => import('../views/JobFairPage')
  // },

  // {
  //   path: "/sign-up-jobseekers",
  //   name: "sign-up-jobseekers",
  //   component: () => import("../views/SignUpJobseeker.vue"),
  // },

  {
    path: "/add-new-job",
    name: "AddNewJob",
    component: AddNewJob,
  },
  {
    path: "/trial",
    name: "Trial",
    component: () => import('../views/Trial.vue')
  },

  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: PageNotFound,
  },

  // {
  //   path: "/outsourcing-tender",
  //   name: "OutsourcingTender",
  //   component: () => import('../views/OutsourcingTender')
  // },

  // {
  //   path: "/advertisements",
  //   name: "Advertisements",
  //   component: () => import('../views/Advertisements')
  // },

];

for (let i = 0; i < otherRoutes.length; i++) {
  routes.push(otherRoutes[i]);
}

  const router = createRouter({
    mode: "history",
    history: createWebHistory(process.env.BASE_URL),
    routes,
    /*scrollBehavior() {
        document.getElementById('app').scrollIntoView({ top: 0, behavior: "instant" });
    }*/

    /*scrollBehavior() {
      document.getElementById("app").scrollTo({ top: 0, behavior: "instant" });
    },*/
    // scrollTop() {
    //   // window.scrollTo({ top: 400, behavior: "instant" });
    //   window.
    // },
  });
  
  router.beforeEach(beforeEach)
  
  export default router
