export const setshowJobSeekerRegistered = (state, data) => {
	return state.showJobSeekerRegistered = data
}

export const setEmployerAnalytics = (state, data) => {
	return state.analytics.employer = data
}

export const setJobseekerAnalytics = (state, data) => {
	return state.analytics.jobseeker = data
}

export const setEmployerJobseekerAnalytics = (state, data) => {
	return state.analytics.employerJobseeker  = data
}

export const setJobfairAdvertisementReferralAnalytics = (state, data) => {
	return state.analytics.jobfairAdvertisementReferral  = data
}