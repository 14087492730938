export default [
    {
    path: "/employer-profile/:componentName",
    name: "employerProfile",
    component: () => import('../components/SignUpEmployerSection.vue'),
    
    },
    {
        path: "/success",
        name: "stripPaymentSuccess",
        component: () => import('../components/partials/stripPaymentSuccess.vue'),
        
    },
    {
        path: "/paypal-payment-success",
        name: "paypalPaymentSuccess",
        component: () => import('../components/partials/paypalPaymentSuccess.vue'),
        
    },
    {
        path: "/paypal-payment-error",
        name: "paypalPaymentError",
        component: () => import('../components/partials/paypalPaymentError.vue'),
        
    },
]