export const fetchAdvertisement = ({commit, dispatch},  query ='') => {
    let url = process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/advertisement'+ query;
    return window.axios.get(url).then((response) => {
        commit('setAdvertisementData', response.data);
        return true;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    })
}

export const buildJobSearchQuery = ({commit, state}) => {
    let query = ''

    query += state.commonSearchQuery != '' ? '&common_search=' + state.commonSearchQuery : '';
    query += state.locationSearchQuery != '' ? '&location=' + state.locationSearchQuery : '';
    query += state.matchingPercetageSearchQuery != '' ? '&matching_percentage=' + state.matchingPercetageSearchQuery : '';
    query += state.daysSearchQuery != '' ? '&posted_days=' + state.daysSearchQuery : '';
    query += state.titpSearchQuery != '' ? '&titp=' + state.titpSearchQuery : '';

    let salaryRangeQuery = '';

    if (state.salaryFromQuery != '' && state.salaryToQuery != '') {
        salaryRangeQuery = state.salaryFromQuery + '|' + state.salaryToQuery + '|' + state.salaryCurrencyQuery
        query += '&salary_range=' + salaryRangeQuery;
    } else if (state.salaryRangeQuery != '') {
        query += '&salary_range=' + state.salaryRangeQuery;
    }

    query += state.jobTypeQuery != '' ? '&job_type=' + state.jobTypeQuery : '';

    query += state.cityQuery != '' ? '&city=' + state.cityQuery : '';
    query += state.stateQuery != '' ? '&state=' + state.stateQuery : '';
    query += state.countryQuery != '' ? '&country=' + state.countryQuery : '';
    query += state.experienceQuery != '' ? '&experiance=' + state.experienceQuery : '';
    query += state.positionQuery != '' ? '&position=' + state.positionQuery : '';
    query += state.titleQuery != '' ? '&title=' + state.titleQuery : '';
    query += state.workStyleQuery != '' ? '&work_style=' + state.workStyleQuery : '';
    query += state.industryQuery != '' ? '&industry_type=' + state.industryQuery : '';
    query += state.industrySubQuery != '' ? '&industry_sub_type=' + state.industrySubQuery : '';
    query += state.strengthQuery != '' ? '&strength=' + state.strengthQuery : '';
    query += state.skillQuery != '' ? '&key_skill=' + state.skillQuery : '';
    query += state.functionQuery != '' ? '&function_area=' + state.functionQuery : '';
    query += state.languageQuery != '' ? '&language=' + state.languageQuery : '';
    query += state.educationQuery != '' ? '&education=' + state.educationQuery : '';
    query += state.courseQuery != '' ? '&course_minor=' + state.courseQuery : '';
    query += state.employerTypeQuery != '' ? '&employer_type=' + state.employerTypeQuery : '';

    query += state.perPageQuery != '' ? '&perPage=' + state.perPageQuery : '';
    query += state.pageQuery != '' ? '&page=' + state.pageQuery : '';
    query += state.companySearchQuery != '' ? '&company_name=' + state.companySearchQuery : '';
    return query;
}

export const fetchSearchedJobs = ({commit, dispatch},  query ='') => {
    let url = process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/open-search/jobs?fullReadMode=true'+ query;
    return window.axios.get(url).then((response) => {
        commit('setSearchedJobs', response.data.data)
        commit('setSearchedJobPagination', response.data)
    }).catch((error) => {
        console.log("Error in fetching open search jobs", error)
    })
}

export const topCompaniesLists = ({ commit},payload) => {
  return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/top-companies-list").then((response) => {
    
      return response.data;
    }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });   
};

export const fetchSearchedEmployers = ({commit, dispatch},  query ='') => {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '';
    let authUserId = user.id;
    query = authUserId && authUserId != null && authUserId != 'undefined' ? query+'&authUserId='+authUserId : '';
    let url = process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/open-search/employers?fullReadMode=true'+ query;
    return window.axios.get(url).then((response) => {
        commit('setSearchedEmployers', response.data.data)
        commit('setSearchedEmployerPagination', response.data)
    }).catch((error) => {
        console.log("Error in fetching open search jobs", error)
    })
}

export const buildEmpSearchQuery = ({commit, state}) => {
    let query = ''

    query += state.empCompanyNameSearchQuery != '' ? '&company_name=' + state.empCompanyNameSearchQuery : '';
    query += state.empLocationSearchQuery != '' ? '&location=' + state.empLocationSearchQuery : '';
    query += state.empTitpSearchQuery != '' ? '&titp=' + state.empTitpSearchQuery : '';

    query += state.perPageEmployerQuery != '' ? '&perPage=' + state.perPageEmployerQuery : '';
    query += state.empPageQuery != '' ? '&page=' + state.empPageQuery : '';
    return query;
}


