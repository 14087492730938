<template>
	<div>   
		<div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
			<div class="relative w-full mx-4 md:mx-0 px-4 md:px-0">       
				<div class="border-2 rounded-2xl shadow-lg rounded-2xl relative flex  bg-white outline-none focus:outline-none max-w-xl xl:max-w-4xl mx-auto">        
					<div class="p-5 w-full">
						<div class="flex justify-end">                                
							<div class="relative">
								<!--button class="absolute inset-x-2 -top-7 bg-white rounded-full" type="button"  @click="$store.commit('hideChangePasswordModel', true)" :disabled="checkIfPassChangeReq()">
									<svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 .424c-5.522 0-10 4.305-10 9.615 0 5.31 4.478 9.616 10 9.616s10-4.306 10-9.616S15.522.424 10 .424Zm3.692 13.268-1.473-.006L10 11.142l-2.217 2.542-1.475.006a.174.174 0 0 1-.179-.172c0-.04.016-.08.043-.111l2.904-3.327-2.904-3.325a.175.175 0 0 1-.043-.111c0-.095.08-.172.179-.172l1.475.007L10 9.022l2.216-2.541 1.474-.007c.098 0 .178.075.178.172 0 .04-.015.08-.042.112l-2.9 3.324 2.902 3.327c.027.032.043.07.043.111 0 .095-.08.172-.179.172Z" fill="#DA5352"/></svg>
								</button-->
							</div>
						</div>
						<div class="flex justify-center">
							<label class="uppercase text-xl sm:text-3xl font-bold mb-4 text-center">Welcome to smart recruit
							</label>
						</div>
						<div class="flex justify-center mb-10">
							<label class="w-28 border-b-4 border-red-600"></label>
						</div>

						<div class="flex xl:space-x-6 md:px-10 xl:px-0">
							<div class="w-6/12 hidden xl:block">
								<div class="w-full relative">
									<div><img src="images/Group.png" class="rounded-md w-full h-changePasswordHeight"></div>
									<div class="absolute bottom-6 left-6 top-6 right-6 border-2 border-white rounded-lg flex items-center justify-center">
										<div class="flex flex-col items-center space-y-4">
											<label class="uppercase text-lg text-white">Welcome To</label>
											<label class="uppercase text-4xl font-semibold text-white ">SMART RECRUIT
											</label>
											<div class="border-t-2 w-28"></div>
											<div class="  text-sm text-white mx-12 text-center tracking-wider">Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard. 
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="w-full xl:w-6/12">
								<form class="h-full" @submit.prevent="submit">
									<div class="border shadow-md rounded-lg py-2  space-y-4 h-full px-6 md:px-16 flex items-center justify-center w-full">
										<div class="space-y-4 w-full">
											<div class="flex justify-center">
												<label class=" font-sans font-bold text-xl xl:text-3xl mb-4 tracking-tighter text-red-600 ">
													Change Password
												</label>
											</div>
											<div class="flex flex-col space-y-4">
												<div>
                                                    <div class="flex">
													    <input :type="type1" v-model.trim="change.old_password" ref="old_password" @blur="!errors.isOldPasswordMatch ? clearValidity('isOldPasswordMatch') : clearValidity('old_password')" class="border shadow-md w-full py-2 px-4 rounded-lg focus:outline-none focus:ring-1 focus:ring-green-300" placeholder="Current Password" />
                                                        <svg v-if="!showPassword1" @click="handleShowPassword1(true)" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                                                        <svg v-if="showPassword1" @click="handleShowPassword1(false)" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"></path></svg>
                                                    </div>
                                                    <span v-if="!errors.old_password" class="pt-2 text-xs text-red-500">{{$t('please-enter-old-password')}}</span>
                                                    <span v-if="!errors.isOldPasswordMatch" class="text-xs text-red-600">Old Password does not matched.</span>
												</div>
												<div>
                                                    <div class="flex">
													    <input :type="type2" v-model.trim="change.password" ref="new_password" @blur="!errors.p_size ? clearValidity('p_size') : clearValidity('password')" class="border shadow-md w-full py-2 px-4 rounded-lg focus:outline-none focus:ring-1 focus:ring-green-300" placeholder="New Password" />
                                                        <svg v-if="!showPassword2" @click="handleShowPassword2(true)" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                                                        <svg v-if="showPassword2" @click="handleShowPassword2(false)" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"></path></svg>
                                                    </div>
                                                    <span v-if="!errors.password" class="pt-2 text-xs text-red-500">{{$t('please-enter-old-password')}}</span>
                                					<span v-else-if="!errors.p_size" class="pt-2 text-xs text-red-500">The password must be at least 6 characters.</span>
												</div>
												<div>
                                                    <div class="flex">
													    <input :type="type3" v-model.trim="change.confirm_password" ref="confirm_password" @blur="!errors.is_match ? clearValidity('is_match') : clearValidity('confirm_password')" class="border shadow-md w-full py-2 px-4 rounded-lg focus:outline-none focus:ring-1 focus:ring-green-300" placeholder="Confirm New Password" />
                                                        <svg v-if="!showPassword3" @click="handleShowPassword3(true)" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                                                        <svg v-if="showPassword3" @click="handleShowPassword3(false)" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"></path></svg>
                                                    </div>
                                                    <span v-if="!errors.confirm_password" class="pt-2 text-xs text-red-500">{{$t('please-enter-confirm-password')}}</span>
					                                <span v-else-if="!errors.is_match" class="pt-2 text-xs text-red-500">{{$t('password-confirm-password-not-match')}}</span>
												</div>             
											</div>  
											<div class="flex justify-center py-2">
												<button class="flex border bg-red-600 hover:bg-red-700 text-white text-sm font-semibold tracking-tighter hover:bg-red-800 rounded-lg py-2 px-12" @click="$store.commit('showChangePasswordModel', false)">
													Cancel
												</button>
												<button class="flex border bg-red-600 hover:bg-red-700 text-white text-sm font-semibold tracking-tighter hover:bg-red-800 rounded-lg py-2 px-12">
													{{submitButtonText}}
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>                                 
				</div>
			</div>
		</div>
		<div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
	</div>
</template>

<script>
// @ is an alias to /src
import { mapActions } from 'vuex'

export default {

  data: function() {
    return {
        change: {
            old_password: '',
            password: '',
            confirm_password:'',
        },
        errors: {
            password:true,
            old_password:true,
            confirm_password:true,
            is_match : true,
            p_size: true,
            isOldPasswordMatch:true
        },
        submitted: false,
        submitButtonText: 'Submit',
		userData: this.$store.getters['auth/user'],
        type1:'password',
        type2:'password',
        type3:'password',
        showPassword1:false,
        showPassword2:false,
        showPassword3:false
    }
},

components: {

},
methods: {
    ...mapActions({
        changepassword: 'profile/changePassword',
		restChangePassword : 'profile/restChangePassword',
    }),
	clearValidity(input){
        this.errors[input] = true;
    },
	validation(){
        var formIsValid = true;
        if(!this.change.old_password && !this.change.password && !this.change.confirm_password){
            formIsValid = false;
            this.errors.old_password = false;
            this.errors.password = false;
            this.errors.confirm_password = false;
            this.$refs.old_password.focus();
        }else if(!this.change.old_password){
            formIsValid = false;
            this.errors.old_password = false;
            this.$refs.old_password.focus();
        }else if(!this.change.password){
            formIsValid = false;
            this.errors.password = false;
            this.$refs.new_password.focus();
        }else if(this.change.password.length < 6){
            formIsValid = false;
            this.errors.p_size = false;
            this.$refs.new_password.focus();
        }else if(this.change.password == this.change.old_password){
            formIsValid = false;
            this.$refs.new_password.focus();
            this.$store.commit('SET_ALERT', 'warning', { root: true })
            this.$store.commit('SET_ALERT_MESSAGE', this.$t('old-new-password-should-not-be-same'),{ root: true })
        }else if(!this.change.confirm_password){
            formIsValid = false;
            this.errors.confirm_password = false;
            this.$refs.confirm_password.focus();
        }else if(this.change.password != this.change.confirm_password){
            formIsValid = false;
            this.is_match = false;
            this.$refs.confirm_password.focus();
        }else{
            return formIsValid;
        }
    },
    resetForm() {
        this.change.old_password= '';
        this.change.password= '';
        this.change.confirm_password='';
    },
    submit() {
		try{
			if(this.validation()){
				this.gIndicator('start');
				let vm = this
				this.submitted = true
				if (this.submitted) {
					this.submitButtonText = 'Please wait..'
				}
				this.isbeingLoggedIn = true
				this.changepassword({
					payload: this.change,
					context: this
				}).then((success) => {
					if(success){
						const payload = {email: localStorage.getItem('email')};
						this.restChangePassword({
							payload: payload,
							context: this,
						}).then((res)=>{
							if(res){
								vm.resetForm()
								vm.submitted = false;
								this.gIndicator('stop');
								this.$store.commit('hideChangePasswordModel', true, { root: true });
								vm.submitButtonText = 'Submit';
								// if (success) {
								// 	window.location = "/my-profile";
								// }
							}
						})
					}
				})
			}
		}catch(error){
			this.submitButtonText = 'Submit';
			this.gIndicator('stop');
			console.log('error',error);
		}finally{
			this.submitButtonText = 'Submit';
			this.gIndicator('stop');
		}
	},
    handleShowPassword3(value){
        this.showPassword3 = value;
        if(this.showPassword3){
            this.type3 = 'text';
        }else{
            this.type3 = 'password';
        }
    },
    handleShowPassword2(value){
        this.showPassword2 = value;
        if(this.showPassword2){
            this.type2 = 'text';
        }else{
            this.type2 = 'password';
        }
    },
    handleShowPassword1(value){
        this.showPassword1 = value;
        if(this.showPassword1){
            this.type1 = 'text';
        }else{
            this.type1 = 'password';
        }
    }
},
 mounted() {
    this.$store.commit('isAuthenticated', false);
    this.$refs.old_password.focus();
  }
};
</script>