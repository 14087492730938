export const getJobListData = (state) => {
	return state.jobList.data
}

export const getJobListLinks = (state) => {
	return state.jobList.links
}

export const getJobListMeta = (state) => {
	return state.jobList.meta
}

export const getAppliedJobsData = (state) => {
	return state.appliedJobs.data
}

export const getAppliedJobsLinks = (state) => {
	return state.appliedJobs.links
}

export const getAppliedJobsMeta = (state) => {
	return state.appliedJobs.meta
}

export const getJobDetails = (state) => {
	return state.jobDetails
}

export const getJobApplication = (state) => {
	return state.jobApplication
}

export const getMyResume = (state) => {
	return state.myResume
}
export const getApplicants = (state) => {
	return state.applicants
}
export const getMatchingApplicant = (state) => {
	return state.matching_applicants
}

export const getJobId = (state) => {
    return  state.jobs.jobId;
}

export const getShowApplyBox = (state) => {
	return state.showApplyBox
}

export const getHasApplied = (state) => {
	return state.has_applied
}

export const getForEmployer = (state, data) => {
    return state.forEmployer
}
export const getJobSeekerId = (state, data) => {
    return state.jobSeekerId
}

export const getInterviewMessage = (state, data) => {
    return state.interviewMessage
}

export const getVisaInterviewMessage = (state, data) => {
    return state.visaInterviewMessage
}

export const GET_HIRING_SOURCE = (state) => {
    return {
    	titp: state.hiring_source_titp,
    	nontitp: state.hiring_source_nontitp
    }
} 

export const getShowRejectModel = (state) => {
    return state.showRejectModel
}

export const GET_EMPLOYER_DATA = (state, data) => {
    return state.employers
}

export const getJobseekerResume = (state) => {
	return state.jobseekerResume
}

export const getProfileList = (state) => {
	return state.ProfileList.data	
}
export const getResumeLanguage = (state) => {
	return state.languageList.data	
}

// delete job seeker profilw model 11-08-022
export const getDeleteJobseekerProfile = (state) => {	
	return state.showDeleteJobseekerProfile
}
export const hideDeleteJobseekerProfile = (state) => {
	return state.hideDeleteJobseekerProfile
}
export const profileIdDeleted = (state) => {
	return state.profileIdDeleted
}
export const profileIdLanguage = (state) => {
	return state.profileIdLanguage
}
/////

// make master profile model 12-08-022
export const getMakeMasterProfile = (state) => {	
	return state.showMakeMasterProfile
}
export const hideMakeMasterProfile = (state) => {
	return state.hideMakeMasterProfile
}
export const profileIdMaster = (state) => {
	return state.profileIdMaster
}
/////

///// make master profile model 12-08-022
export const getDeactiveProfile = (state) => {	
	return state.showDeactiveProfile
}
export const hideDeactiveProfile = (state) => {
	return state.hideDeactiveProfile
}
export const activeDeactive = (state) => {
	return state.activeDeactive
}
/////

///// activate warning model 16-08-022
export const getActivateWarning = (state) => {	
	return state.showActivateWarning
}
export const hideActivateWarning = (state) => {
	return state.hideActivateWarning
}
///////

// export const getshowAddNewLanguageOther = (state) => {	
// 	return state.showAddNewLanguageOther
// }
export const getResumeDetails = (state) => {	
	return state.resumeDetails.profile_type
}
export const getResumeName = (state) => {	
	return state.resumeDetails.resume_name
}
export const getMasterLanguage = (state) => {	
	return state.resumeDetails.resume_language
}

export const getJobDropdownList = (state) => {
	return state.setJobDropdownList
}
export const getChatbotData = (state) => {
	return state.setChatbotData
}