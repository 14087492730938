export default [
    {
       path: "/job-seeker-profile/",
       name: "jobseekerProfile",
       component: () => import('../components/SignUpJobseekerSection.vue'),
       
    },
    {
        path: '/job-seeker-profile/:componentName',
        name: "jobseekerProfile",
        component: () => import('../components/SignUpJobseekerSection.vue'),
    },
   ]