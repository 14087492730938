<template>
  
    <div class="container mx-auto ">
      <div class="border-none bg-gray-50 pb-24 xl:pb-1">

        <div class="block lg:hidden py-3">
          <button @click="show = !show">
            <svg xmlns="http://www.w3.org/2000/svg" class=" mx-3 h-7 w-7 fill-current text-gray-500" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd"/>
            </svg>
          </button>
        </div>

          <!-- ============= Responsive SideBar ============ -->
          <div class="block lg:hidden bg-white w-screen lg:w-auto h-auto pr-10">
            <section v-if="show" class="absolute -mt-14 "> 
              <div class="  bg-white pb-1 shadow h-auto">
                <button @click="show = !show" class="m-3 fill-current text-gray-800"> 
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                  </svg>
                </button>
                <ProfileSidebar/> 
              </div>
            </section>
          </div>
        <!--============= Responsive SideBar End ============  -->

        <!-- ======== Open Tabs ========== -->
        <div class="pt-16 box-border border-b-2 border-red-300 w-40 mx-8 h-full">
        </div>
        <div class="grid grid-cols-2 lg:grid-cols-3 place-items-center gap-7 lg:gap-6  mx-28 my-10">
          <div class=" box-border border-2 border-gray-200 h-32 w-32 p-6 m-3 md:m-0">
            <span class="flex justify-center mx-2  text-red-500 font-bold ">200</span>
            <div class="mx-2 text-center text-gray-700 font-semibold text-sm p-2">Active Ads</div>
          </div> 
          <div class=" box-border border-2 border-gray-200 h-32 w-32 p-6 m-3 md:m-0">
            <span class="flex justify-center mx-2  text-red-500 font-bold">75</span>
            <div class="mx-1 text-center text-gray-700 font-semibold text-sm p-2">Active Projects</div>
          </div> 
          <div class="box-border border-2 border-gray-200 h-32 w-32 p-6 m-3 md:m-0">
            <span class="flex justify-center mx-2 text-red-500 font-bold ">14</span>
            <div class=" mx-1 text-center text-gray-700 font-semibold text-sm p-2">Job Fair Projects</div>
          </div> 
          <div class="box-border border-2 border-gray-200 h-32 w-32 p-6 m-3 md:m-0">
            <span class="flex justify-center mx-2  text-red-500 font-bold ">20</span>
            <div class=" mx-1 text-center text-gray-700 font-semibold text-sm p-2">Job Posted</div>
          </div> 
          <div class="box-border border-2 border-gray-200 h-32 w-32 p-6 m-3 md:m-0">
            <span class="flex justify-center mx-2 text-red-500 font-bold ">11</span>
            <div class="mx-1 text-center text-gray-700 font-semibold text-sm p-2">New Applies</div>
          </div> 
          <div class="box-border border-2 border-gray-200 h-32 w-32 p-6 m-3 md:m-0">
            <span class=" flex justify-center  text-red-500 font-bold ">8</span>
            <div class=" mx-1 text-center text-gray-700 font-semibold text-sm p-2">Message</div>
          </div>
        </div>
        <!-- ======== Application ========== -->

        <div class=" box-border border-b-2 border-red-300 w-40 mx-8 font-bold text-lg pb-1 ">Recent Application
        </div>
    
        <div class=" mx-36 p-1 grid lg:grid-cols-1 place-items-center bg-white  mt-10 xl:mt-5 box-border border-2 h-full md:h-36 w-80 md:w-auto overflow-auto">
          <div class="flex flex-row lg:flex ">
            <span class="flex justify-center items-center py-14 md:p-2 ">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20 pl-4 fill-current text-gray-700 items-center" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
              </svg>
            </span>
            <div class="p-4 ">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse euismod imperdiet metus sed tempus. Nullam ornare lorem vel metus gravida volutpat eget et arcu.
            </div>
          </div>
        </div>
       
        <div class="flex justify-end mr-36 mt-3 mb-8 ">
            <span class="box-border border-2 h-7 w-9 pl-1 bg-white">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 items-center" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
              </svg>
            </span>
          </div>
      </div>
    </div>


</template>

<script>
   
// @ is an alias to /src

import ProfileSidebar from '../components/ProfileSidebar.vue'
export default {
   name: "ToggleDiv",
  el: '#showModal',
  data: function() {
    return {
      roleModelBox : false,
      isShow: false,
      show: false,
      sidebarview: false,
      slides: [
        {
          title: 'Slide #1',
          content: 'Slide content.',
           
        },
      ]
    }
  },
   methods: {
      openSidebar() {
        this.sidebarview = true
      },
      closeSidebar() {
        this.sidebarview = false
      }
    }, 
  components: {
            ProfileSidebar 
  },

  props: {
  msg: String,
},
};
</script>

