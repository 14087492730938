export const fetchJobseekerData = ({ commit},{payload}) => {
    let query = "?language="+payload.language+'&profile_name='+payload.profile_name;
    if(payload.profile_id){
        query = query+'&personal_profile_id='+payload.profile_id;
    }
    let url = process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/get-js-profile-details"+query;
    return window.axios.get(url).then((response) => {
        return response.data;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return false;
    });   
};
export const setMasterProfileLanguage = ({ commit},{payload}) => {
    let url = process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/set-master-profile";
    return window.axios.post(url,payload).then((response) => {
        return response.data;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return false;
    });   
};