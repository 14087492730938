export const jobListing = ({ commit, context }, payload) => {
    let query =''
    if(payload.industry_id){
        query = '?industry_id='+payload.industry_id;
    }
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/get-job-list" + query ).then((response) => {
        return response.data;
    }).catch((error) => {
        return false;
    });
};
export const registeredEmployer = ({ commit, context }, payload) => {
    let query =''
    if(payload.employer_type){
        query = '?employer_type='+ payload.employer_type;
    }
    return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/get-registered-employer" + query ).then((response) => {
        return response.data;
    }).catch((error) => {
        return false;
    });
};