export const user = (state) => {
	return state.user
}

export const authenticated = (state) => {
	return state.user.authenticated
}

export const loggedInUser = (state) => {
  return state.user.data;
}
export const getShowTermsConditions = (state) =>{
  return state.showTermsConditions
}

export const getHideTermsConditions =(state) => {
  return state.hideTermsConditions
}

export const getshowVerifyOtpModel = (state) =>{
  return state.showVerifyOtpModel
}
