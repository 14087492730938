/*
export const getAgreementId = (state) => {
	return state.agreementId
}*/
export const getAgreementListData = (state) => {
	return state.agreemntList.data;
}

export const getAgreementListLinks = (state) => {
	return state.agreemntList.links;
}

export const getAgreementListMeta = (state) => {
	return state.agreemntList.meta;
}
export const getSettingInclusion = (state) => {
	return state.inclusionsSettings;
}
export const getPlannerData = (state) => {
	return state.smartPlannerData;
}
export const getJiipaData = (state) => {
	return state.jiipaData;
}
export const getEmployerData = (state) => {
	return state.employerData;
}