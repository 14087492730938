export default {
    state:{
        showPaymentModal:false,
        hidePaymentModal:false,
        
    },
    jobfairs:{
        all_data:[],
        all_links:[],
        industryTypes:[],
        jobFairTypes:[],
        phoneCode:[],
        jobfairId:"",

        organiser_list_data:[],
        organiser_list_links:[],

        org_jf_by_id:[],
        org_jf_by_id_links:[],

        exhibitorNAttendeeList:[],
        exhibitorNAttendeeListLinks:[],
        
        nonSubscribedList:[],
        nonSubscribedListLinks:[],
        
        reschedule:{
            reschedule:false,
            jobfair_id:'',
        },

        cancel:{
            cancelJobfair:false,
            jobfair_id:'',
        },

        subscriberList:{
            subscribers:[],
            subscribers_links:[],
        },
        subscriberList_attendee:{
            subscribers:[],
            subscribers_links:[],
        },

        acceptDeclineModal:{

            acceptDecline:false,
            msg:'',
            title:'',
        },
        subscribed_as:'',
        showDeleteJobFair:false,
        commonJobfairById:'',
    },

    datetime:{
        startDate:'',
        endDate:'',

        startTime:'',
        endTime:'',

        timezone:'',
    },

    client:{
        timezone:'',
    },
    converter:{
        data:null,
    },
    plan:{
        membershipPlan:[],
    },
    job_fair: {
		id:"",
		value:"",
		type:"",
		
	},
}