export const JobSeekerRegistered = ({ commit }) => {

    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/employer-job-seekers-registered').then((response) => {

        commit('setshowJobSeekerRegistered', response);
    })
}

export const fetchEmployerAnalytics = ({commit, dispatch}) => {
    let url = process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/employer-analytics';
    return window.axios.get(url).then((response) => {
        commit('setEmployerAnalytics', response.data);
        return true;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    })
}

export const fetchJobseekerAnalytics = ({commit, dispatch}) => {
    let url = process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/jobseeker-analytics';
    return window.axios.get(url).then((response) => {
        commit('setJobseekerAnalytics', response.data);
        return true;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    })
}

export const fetchEmployerJobseekerAnalytics = ({commit, dispatch}) => {
    let url = process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/employer-jobseeker-analytics';
    return window.axios.get(url).then((response) => {
        commit('setEmployerJobseekerAnalytics', response.data);
        return true;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    })
}

export const fetchJobfairAdvertisementReferralAnalytics = ({commit, dispatch}) => {
    let url = process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/jobfair-advertisement-referral-analytics';
    return window.axios.get(url).then((response) => {
        commit('setJobfairAdvertisementReferralAnalytics', response.data);
        return true;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    })
}

export const titpJobCategory = ({ commit }) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/titp-category").then((response) => {
            return response.data.data;
        })
    .catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return false;
    });   
};