import localforage from 'localforage'
import { setHttpToken, setHttpBaseUrl, setCSRFToken } from '../../../helpers'
import { isEmpty } from 'lodash'
import router from '@/router'
import { locale } from 'core-js'

export const login = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post('api/signin', payload).then((response) => {

        //set User details
        if(response.data.success && payload.keepMeLoggedIn){
            commit('setUser', {
                "email" : payload.email,
                "password" : payload.password,
                "keepMeLoggedIn": payload.keepMeLoggedIn,
                "data":response.data.user,
            });
        }else{
            commit('setUser', {
                "email" : payload.email,
                "password" : '',
                "keepMeLoggedIn": payload.keepMeLoggedIn,
                "data":response.data.user,
            });
        }
        // Setting up auth token ad fetching user data
        dispatch('setToken', response.data.token).then(() => {
            const ualPayload = {
                "email" : payload.email,
                "token": response.data.token
            }
        })
        return response.data;       
    }).catch((error) => {

        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true });
        context.isbeingLoggedIn = false;
        
        return false;
    });
}

export const setToken = ({ commit, dispatch }, token) => {
    if (isEmpty(token)) {
        return dispatch('checkTokenExists').then((token) => {
            setHttpToken(token);
            setHttpBaseUrlAfterLogin(token);
        })
    }
    commit('setToken', token)
    setHttpToken(token)
}

export const checkTokenExists = ({ commit, dispatch }, token) => {
    return localforage.getItem('authtoken').then((token) => {
        if (isEmpty(token)) {
            return Promise.reject('NO_STORAGE_TOKEN');
        }

        return Promise.resolve(token);
    })
}

export const setHttpBaseUrlAfterLogin = (token) => {
    if (isEmpty(token)) {
        return Promise.reject('NO_STORAGE_TOKEN');
    }
    setHttpBaseUrl(token);
    return Promise.resolve(token);
}

export const clearAuth = ({commit,dispatch}) => {
    commit('setAuthenticated', false)
    commit('setUserData', null)
    setHttpToken(null)
    setHttpBaseUrl(null)
    localStorage.setItem('loginUserData', null);

    commit('HANDLE_MAIN_HEADER_BASED_ON_AUTH', null, { root: true })
}

export const loginRESTApi = ({ commit, dispatch },{payload, context}) => {

    return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/sign-in',payload).then((response) => {
        try{
            if(response.data.success){
                commit('setLoginUser', response.data)
                commit('setPersonalProfileId',response.data.personal_profile_id)
                commit('setAuthUserData',response.data.user);
                localStorage.setItem('last_login',response.data.last_login_details.created_at);
                // Showing  success message
                // commit('SET_ALERT', 'success', { root: true })
                // commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
            }else{
                commit('setUser', {
                    "email" : payload.email,
                    "password" : '',
                    "keepMeLoggedIn": payload.keepMeLoggedIn,
                    'data':''
                });
                commit('SET_ALERT', 'warning', { root: true });
                commit('SET_ALERT_MESSAGE', response.data.message, { root: true });
                dispatch('logoutUnautorizedUser','');
            }
                 
        }catch(error){
            commit('setUser', {
                "email" : payload.email,
                "password" : '',
                "keepMeLoggedIn": payload.keepMeLoggedIn,
                "data":'',
            });
        }
        
        return response.data;
    }).catch((error) => {
        context.isbeingLoggedIn = false;
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false
    });
}
export const logoutUnautorizedUser = (context,payload) => {
    let userPayload = null;
    try{
        if(payload !== null) {
            if(payload && payload.keepMeLoggedIn === "true") {
                userPayload = {
                    "email" : payload.email,
                    "password" : payload.password,
                    "token" : '',
                    "keepMeLoggedIn": payload.keepMeLoggedIn,
                }
            } else {
                userPayload = {
                    "email" : '',
                    "password" : '',
                    "token" : '',
                    "keepMeLoggedIn": false,
                }
            }
            context.commit('setUser', userPayload);
            context.commit('setToken', userPayload.token);
            context.commit('setPersonalProfileId','');
        }
    }catch($e){
        console.log($e);
    }
}
export const verifiedEmailCheck = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/verified-email', payload).then((response) => {
        return response.data;
    }).catch((error) => {
        if (!isEmpty(error.response.data.message)) {
            commit('SET_ALERT', 'warning', { root: true });
            commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true });
        }
        return false;
    })
}
export const sendOtp = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/get-otp', payload).then((response) => {
        if(response.data.success){
            return response.data;
        }
        return false;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', "Something went wrong", { root: true });
        return false;
    })
}
export const restSendOtp = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/send-otp', payload).then((response) => {
        if(response.data.success){
            // commit('SET_ALERT', 'success', { root: true });
            // commit('SET_ALERT_MESSAGE', response.data.success, { root: true });
            return response.data;
        }
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', "Something went wrong", { root: true });
        return false;
    })
}
export const verifyOtp = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/verify-email', payload).then((response) => {
        if(response.data.success){
            // commit('SET_ALERT', 'success', { root: true });
            // commit('SET_ALERT_MESSAGE', response.data.message, { root: true });
            return response.data;
        }else{
            // commit('SET_ALERT', 'warning', { root: true });
            // commit('SET_ALERT_MESSAGE', response.data.message, { root: true });
            return false;
        }
        
    }).catch((error) => {
        context.isDisabled = false;
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true });
        return false;
    })
}
export const fetchAgreement = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/get-contract-agreement', payload).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'There is no contract agreement available for this role.', { root: true });
        return false;
    })
}

export const register = ({ commit, dispatch }, { payload, context }) => {
    let sso_res_obj = {
        data: {
            success: true,
            token_from_sso: '',
            message: "Error"
        },
     };
     const sso_res = window.axios.post('api/signup', payload).then((response) => {
        // Setting up auth token ad fetching user data
        dispatch('setToken', response.data.token).then();
        if (response.data.success == true) {
            localStorage.setItem('sso_data',JSON.stringify(response.data.user));
            sso_res_obj.data.success = true;
            sso_res_obj.data.token_from_sso = response.data.token;
            sso_res_obj.data.message = '';
        }
        return sso_res_obj;

    }).catch((error) => {
        switch (error.response.status) {
            case 422:
                sso_res_obj.data.success = false;
                sso_res_obj.data.message = "The email has already been taken.";
            break;
        }
        
        context.beingRegistered = false;

        return sso_res_obj;
    })
    return sso_res;
}
export const localRegister = ({ commit, dispatch }, { payload, context }) => {

    return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL +'api/sign-up', payload).then((response) => {
        commit('setPersonalProfileId',response.data.personal_profile_id)
        commit('setAuthUserData',response.data.user);
        return response.data;

    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true });
        context.beingRegistered = false;
        return false;
    })
}

export const externalSignIn = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post (process.env.VUE_APP_SSO_API_URL + 'api/signin/external-signin', payload,{ headers: { ExternalApiToken: payload.ExternalApiToken, ProductCode: payload.product_code} }).then((response) => {
        return response.data;
    }).catch((error) => {
        context.beingRegistered = false;
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        return error.response.data;
    })
}

export const logout = ({ dispatch, commit },payload) => {
    setHttpBaseUrl(null);
    commit('setAuthUserData','');
    dispatch('clearAuth').then(()=>{
        dispatch('logoutUnautorizedUser',payload);
        window.location.href = window.location.origin + "/";
    });
}
export const errorHandler = ({dispatch, commit },payload) => {
    try{
        if(payload === 401){
            commit('setAuthUserData','');
            dispatch('logoutUnautorizedUser','');
            window.location.href = window.location.origin + "/";
        }
    }catch($e){
        console.log($e);
    }
}
export const forgotpassword = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/password/forgot-password', payload).then((response) => {
        // Showing  success message
        // commit('SET_ALERT', 'success', { root: true })
        // commit('SET_ALERT_MESSAGE', 'Email send successfully.', { root: true })
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.submitButtonText = 'fp-col2-button';
        context.submitted = false;
        context.errors = error.response.data.errors
        return false
    })
}
export const restforgotpassword = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/forgot-password', payload).then((response) => {
        // Showing  success message
        // commit('SET_ALERT', 'success', { root: true })
        // commit('SET_ALERT_MESSAGE', 'Email send successfully.', { root: true })
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.submitButtonText = 'fp-col2-button';
        context.submitted = false;
        context.errors = error.response.data.errors
        return false
    })
}

export const resetpassword = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/password/reset', payload).then((response) => {
        // Showing  success message
        // commit('SET_ALERT', 'success', { root: true })
        // commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data.errors
        return false
    })
}
export const fetchUser = ({ commit, dispatch }) => {

    const email = localStorage.getItem('email');
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/profile-details/'+email).then((response) => {
        commit('setAuthenticated', true);
        
        let userData = response.data
        if (userData.details.change_password_at_login) {
            commit('showChangePasswordModel', true, { root: true })
        }
        commit('setUserData', response.data)
        commit('HANDLE_MAIN_HEADER_BASED_ON_AUTH', null, { root: true })
        //dispatch('fetchBellNotifications')
        return response.data;
    })
}
export const industryList = ({commit, dispatch}) =>{
    return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/master-industry-list').then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
    })
}
export const getIndustrySubTypeList = ({commit, dispatch},{payload}) =>{
    return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/master-industry-sub-list/'+payload.id).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
    })
}
export const industrySubCatData = ({ commit, context }, ids) => {
    return window.axios.get( process.env.VUE_APP_SSO_API_URL + "api/master-multiple-industry-sub-list", 
    {params: { "industryList": ids },
    }
    ).then((response) => {
        console.log("industrySubCatData", response)
        return response.data.data;
    }).catch((error) => {
        context.errors = error.response.data.errors;
        return false;
    });
};
export const getDefaultRoles = ({commit, dispatch},{payload}) =>{
    let query = '?product_code='+payload.product_code+'&guard_name='+payload.guard_name;
    return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/get-default-roles'+query).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
    })
}

export const signInUserlogout = ({commit, dispatch},{payload}) =>{
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/user-sign-in-logout',payload).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
    })
}
