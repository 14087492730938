export const getSkill = (state) => {
	return state.search.skill;	
}
export const getLocation = (state) => {
	return state.search.location
}
export const getJobType = (state) => {
	return state.search.job_type
}
export const getLastUpdated = (state) => {
	return state.search.last_updated
}
export const getSearchCategory = (state) => {
	return state.search.search_category
}