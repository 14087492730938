//import retriveSidebar from '../helpers/sidebar.js'
import localforage from 'localforage'

// get phonecode data api
const phoneData = ({ commit }) => {
  return window.axios.get(process.env.VUE_APP_SSO_API_URL  +"api/phone-code").then((response) => {
    return response.data.data
  }).catch((error) => {
    commit("SET_ALERT", "warning", { root: true });
    commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
    return false;
  });
};

const fetchLanguageList = ({ commit, context }) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/languages-list').then((response) => {  
        return response.data.data;
    })
}

const getCitiesFromBackOffice = ({ commit}, { payload, context}) => {
  var string = payload ? payload : 'a';
  return window.axios({
      method: 'GET',
      url: process.env.VUE_APP_SSO_API_URL + `api/get-city/` + string,
  }).then((response) => {
      return response.data;
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true });
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
      return false;
  })
};
const getCountryState = ({ commit}, { payload, context}) => {
  return window.axios({
      method: 'GET',
      url: process.env.VUE_APP_SSO_API_URL + `api/get-country-state-by-city/` + payload.id,
  }).then((response) => {
      return response.data;
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true });
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
      return false;
  })
};
const visaData = ({ commit}) => {
  return window.axios({
      method: 'GET',
      url: process.env.VUE_APP_SMART_RECRUIT_API_URL + `api/visa-status`,
  }).then((response) => {
      return response.data;
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true });
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
      return false;
  })
};
const fetchCommonData = ({ commit}, {payload, context})=>{
  return window.axios({
      method: 'GET',
      url: process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/get-common-data/'+payload.type+'?search=' +payload.search,
  }).then((res) => {
      return res.data; 
  }).catch(e => {
    commit('SET_ALERT', 'warning', { root: true })
    commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
    return false
  });
};
const getNationality = ({ commit}, { payload, context}) => {
  var string = payload ? payload : 'a';
  return window.axios({
      method: 'GET',
      url: process.env.VUE_APP_SSO_API_URL + `api/get-nationality-by-name/` + string,
  }).then((response) => {
      return response.data;
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true });
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
      return false;
  })
};
export default {
  phoneData,
  //retriveSidebar,
  fetchLanguageList,
  getCitiesFromBackOffice,
  getCountryState,
  visaData,
  fetchCommonData,
  getNationality
}