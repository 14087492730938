export const fetchPayementHistoryList = ({ commit, dispatch }, query = '') => {
    // return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/payment-history' + query).then((response) => {
    //     commit('setPayementHistory', response)
    //     return response
    // })
}

// get by ID payment history
export const payementHistoryByID = ({commit}, id) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + `api/payment-view/${id}`).then((response) => {          
        return response.data.paymentInfo
    })
}