export default {

   	showDeleteModel: false,
   	hideDeleteModel: false,

   	showInviteReferrals: false,
   	hideInviteReferrals: false,

	//    referralList: {
	// 	data: [],
	// 	links: [],
	// 	referralId:[]
	// },
	referralList: {
		data: null,
		links: null,
		meta: null,
		referralId:''
	},

}