export default [    
    {
        path: '/manage-jobs',
        name: 'job-view-list',
        component: () => import ('../components/JobViewList.vue'),
        meta: {
            guest: false,
            needsAuth: true
        }
    },
    {
        path: '/manage-jobs-create-edit/:id',
        name: 'JobCreateEdit',
        component: () => import ('../components/JobCreateEdit.vue'),
        meta: {
            guest: false,
            needsAuth: true
        }
    },
    // {
    //     path: '/manage-jobs-create',
    //     name: 'JobCreate',
    //     component: () => import ('../components/JobCreate.vue'),
    //     meta: {
    //         guest: false,
    //         needsAuth: true
    //     }
    // },
    // {
    //     path: '/manage-jobs-edit/:id',
    //     component: () => import('../components/JobEdit.vue'),
    //     name: 'edit-job-by-id',
    //     meta: { 
    //         guest: false,
    //         needsAuth: true,        
    //     }
    // },
    {
        path: '/view-job/:id',
        component: () => import('../components/ViewJob.vue'),
        name: 'view-job',
        meta: { 
            guest: true,
            needsAuth: false,        
        }
    },
    {
        path: '/employer-view-job/:id',
        component: () => import('../components/EmployerViewJob.vue'),
        name: 'employer-view-job',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
    {
        path: '/view-job-application/:id',
        component: () => import('../components/ViewJobApplication.vue'),
        name: 'view-job-application',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
    {
        path: '/job-seeker-resume',
        component: () => import('../components/JobSeekerResumeOld.vue'),
        name: 'job-seeker-resume',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
    {
        path: '/applications-recived-list/:id',
        component: () => import('../components/ApplicationsRecivedList.vue'),
        name: 'applications-recived-list',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
    {
        path: '/job-seeker-resume-for-employer/:id',
        component: () => import('../components/JobSeekerResumeForEmployer.vue'),
        name: 'job-seeker-resume-for-employer',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
    {
        path: '/matching-candidates/:id',
        component: () => import('../components/MatchingCandidates.vue'),
        name: 'matching-candidates',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
    {
        path: '/jobseeker-resume',
        component: () => import('../components/JobSeekerResume.vue'),
        name: 'jobseeker-resume',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },

]