export default {
	jobs: [],
	search: {
		skill:"",
		location:"",
		job_type:"",
		last_updated:"",
		search_category:"",
	},
	emp: [],
	empJobs: {
		data:null,
	},
	client:{
        timezone:'',
    },
}