import localforage from 'localforage'
import { isEmpty } from 'lodash'

export const setUser = (state, payload) => {
    localStorage.setItem('email', payload.email);
    localStorage.setItem('password', payload.password);
    localStorage.setItem('keepMeLoggedIn', payload.keepMeLoggedIn);
    localStorage.setItem('data', JSON.stringify(payload.data));
}
export const setPersonalProfileId = (state, payload) => {
    localStorage.setItem('setPersonalProfileId', payload);
}
export const setToken = (state, token) => {
    if (isEmpty(token)) {
        localforage.removeItem('authtoken', token)
        return
    }

    localforage.setItem('authtoken', token)
}
export const setAuthenticated = (state, flag) => {
    state.user.authenticated = flag;
}
export const setUserData = (state, data) => {
    state.user.data = data
}
export const setRoleSlug = (state, slug) => {
    state.user.roleSlug = slug;
    state.showTab = true;
    if (slug == 'job-seeker') {
        state.showTab = false;
    }
}
export const showTermsConditions = (state, flag) => {
    state.showTermsConditions = flag
}
export const hideTermsConditions = (state) => {
    state.showTermsConditions = false
}

export const showVerifyOtpModel = (state, flag) => {
    state.showVerifyOtpModel = flag
}
export const changeLanguageSlug = (state, locale) => {
    state.locale = locale;
    localforage.setItem('locale', locale);
    window.location = window.location.href;
 }
 export const setAuthUserData = (state, data) => {
    localStorage.setItem('user', JSON.stringify(data));
 }

 export const setLoginUser = (state, data) => {
    localStorage.setItem('loginUserData', JSON.stringify(data));
    localStorage.setItem('loginUserProfileStep', JSON.stringify(data.current_step_no));
}