export default {
    state:{
        showBlockModal:false,
        showUnblockModal:false,
        hideUnblockModal:false,
    },
    blocklist:{
        data:[],
        links:[],       
        id:[],       
        name:'',       
        block:'',       
    }
}