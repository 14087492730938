<template>
 <div>
  <div>
    <HeroSection style="background-image:linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(images/contactUs.webp)" title="ADD NEW JOB"/>
  </div>

    <div>
      <div class="container mx-auto mx-6">
        <div class="flex justify-center mt-20">
              <label class="text-4xl font-bold box-border border-b-2 border-darkRed w-68 py-2">{{$t('ejl-btn1')}}</label>
        </div>
        <div class="ml-16 mr-12 flex justify-center lg:flex ">
          <div class="hidden lg:block w-9/12 lg:w-36/100">
            <SideBar/> 
          </div>
          <div class="lg:w-64/100">
            
              <AddNewJobContainer/> 
          </div>
        </div>
      </div>     
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SideBar from '../components/SideBar.vue'
import AddNewJobContainer from '../components/AddNewJobContainer.vue'
import HeroSection from '../components/HeroSection.vue'

export default {
   data: function() {
      return {
      
    }
  },

  name: 'AddNewJob',
  components: {
   SideBar,
    AddNewJobContainer,
    HeroSection
  },
  methods: {
      
    }, 
};
</script>

<style>

html {
  scroll-behavior: smooth;
}

</style>