<template>
  <div class="container mx-auto my-4 lg:my-16 bg-white w-screen lg:w-auto"> 
    <div class="border-2 border-gray-300 px-5 pb-10 pt-4 mx-5 h-auto">
      <div class="">
        <ProfileCard/>
      </div>

      <div>
        <ProfileEmploySidebar/>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ProfileCard from '../components/ProfileCard.vue'
import ProfileEmploySidebar from '../components/ProfileEmploySidebar.vue'


export default {
  name: "ProfileSidebar",
  components: {
    ProfileCard,
    ProfileEmploySidebar,
  },
  data() {
    return {
      openTab: 1
    }
  },

  methods: {
    toggleTabs: function(tabNumber){
      this.openTab = tabNumber
    }
  }
}

</script>


