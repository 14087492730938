export default [
    {
        path: '/job-seeker-profile',
        component: () => import('../components/MyProfile.vue'),
        name: 'job-seeker-profile',
        meta: {
            guest: false,
            needsAuth: true
        }
    },
    {
        path: '/job-seeker-edit-profile/:componentName',
        component: () => import('../components/EditProfile.vue'),
        name: 'job-seeker-edit-profile',
        meta: {
            guest: false,
            needsAuth: true
        }
    },
    {
        path: '/job-seeker-add-profile/:componentName',
        component: () => import('../components/AddNewProfile/AddNewProfile.vue'),
        name: 'job-seeker-add-profile',
        meta: {
            guest: false,
            needsAuth: true
        }
    },

]