export const getShowRejectCompanyApplication = (state) => {
	return state.showRejectCompanyApplication
}
export const getShowSendScoutMailModel = (state) => {
	return state.showSendScoutMailModel
}
export const getProjectList = (state) => {
	return state.projectList.data
}
 // export const getShowViewProject = (state) => {
 //    return state.showViewProject
 //  }

export const getProjectListMeta = (state) => {
    return state.projectList.meta
    

}
export const getProjectListLinks = (state) => {
    return state.projectList.links
}
export const getProjectId = (state) =>{
    return state.projectId;
}