import { isEmpty } from 'lodash'
export default {
  methods: {
    assetUrl(url) {
      // return process.env.BASE_URL + '/' + url.replace(/^\/|\/$/g, '')
      return process.env.BASE_URL + url.replace(/^\/|\/$/g, '')
    },
    gFormatedAmtNumWithoutDecimal(num) {
      let newNumber = Math.round(num);
      //let newNumber = Math.floor(num);
      //newNumber = newNumber.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
     
      return newNumber;
    },
    gFormatedAmount(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },

    gFormatedNumber(num) {
        return parseFloat(num).toFixed(2);
    },

    gFormatedAmountNumber(num) {
        let amt = this.gFormatedAmount(this.gFormatedNumber(num));
        return amt;
    },
    gIndicator(status){
      if(status == 'start'){
          document.getElementById("template_loader_indicator").style.display="block";
      }
      if(status == 'stop'){
          document.getElementById("template_loader_indicator").style.display="none";
      }
    },
    getRecordListingSummaray(metaData) {
        if (metaData != null) {
            let from = (metaData.from != null) ? metaData.from : 0
            let to = (metaData.to != null) ? metaData.to : 0
            if (from == 0 && to == 0) {
                return this.$t('no-data-found')
            }
            return this.$t('ro-txt6')  + ' '+ from + ' '+this.$t('sds-menu3-txt3')+' ' + to + ' ' +this.$t('ro-txt8')+' '  + metaData.total + ' ' + this.$t('ejl-txt3')
        }
        return this.$t('loading-data')
    },
  }
}