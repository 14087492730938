export default [
	{
        path: '/agreements/scheduled-payment',
        component: () => import('../components/AgreementList.vue'),
        meta: { 
            guest: false,
            needsAuth: true,        
        },
        children:[
            {
                path: '/agreements/scheduled-payment',
                component: () => import('../components/ScheduledPayment.vue'),
                name: 'agreement-scheduled-payment',
            },
            {
                path: '/agreements/paid',
                component: () => import('../components/PaidHistory.vue'),
                name: 'paid-agreement',
            },
        ]
    },
    {
        path: '/view-agreement/:id',
        component: () => import('../components/ViewAgreement.vue'),
        name: 'view-agreement',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
    {
        path: '/edit-agreement/:id/:componentName',
        component: () => import('../components/EditAgreement.vue'),
        name: 'edit-agreement',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
]