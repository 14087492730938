<template>
  <div class="container mx-auto my-4 lg:my-20">
    <div class="border-2 border-borderGray rounded-md py-6 pr-20">
      <div class="block lg:hidden py-3">
        <button @click="show = !show">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class=" mx-3 h-7 w-7 fill-current text-gray-500"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>

      <!-- ============= Responsive SideBar ============ -->
      <div class="block lg:hidden bg-white w-screen lg:w-auto h-auto pr-10">
        <section v-if="show" class="absolute -mt-14 ">
          <div class="  bg-white pb-1 shadow h-auto">
            <button
              @click="show = !show"
              class="m-3 fill-current text-gray-800"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-6 h-6 "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <ProfileJobSidebar />
          </div>
        </section>
      </div>
      <!--============= Responsive SideBar End ============  -->

      <!-- ======== Open Tabs ========== -->
      <!-- <div class="pt-16 box-border border-b-2 border-red-300 w-40 mx-8 h-full">Jobs
        </div> -->
      <!-- <div class="pt-16 mx-8 font-bold text-lg pb-2 h-full">
            </div> -->

      <!--============= Form Start ============  -->
      <Form action="" @submit="checkForm" :validation-schema="schema">
        <div class="pl-20 ml-lg">
          <div class="">
            <div class="flex mb-8">
              <input
                v-model="selectedTITP"
                type="radio"
                class="radioGender self-center mr-4"
                id="TitpJob"
                name="selectedTITP"
                value="0"
                required
              />
              <label class="text-lg font-bold mr-12" for="TitpJob"
                ><!-- NON-TITP Job --> {{$t('mjc-tbl1-radio1')}}</label
              ><br />
              <input
                v-model="selectedTITP"
                type="radio"
                class="radioGender self-center mr-4"
                id="nonTitp"
                name="selectTitp"
                value="1"
              />
              <label class="text-lg font-bold" for="nonTitp">{{$t('mjc-tbl1-radio2')}}</label
              ><br />
            </div>
          </div>
        </div>

        <div>
          <div v-show="selectedTITP === '1'">
            <div class="pl-20">
              <div class="flex">
                <label
                  class="inline-block w-32 text-base text-gray2 font-normal mr-11 self-center"
                  >{{$t('mjc-titp-tbl1-col1-row1')}}</label
                >
                <VueMultiselect
                  class="w-96 my-2.5"
                  v-model="selectedTitpJobTypes"
                  tag-placeholder="Add this as new position"
                  :placeholder="$t('eanj-para10')"
                  label="job_type"
                  track-by="id"
                  :options="TitpjobTypes"
                  openDirection="bottom"
                  @select="getJobSubTypes"
                  @tag="addTitpJob"
                  :taggable="true"
                ></VueMultiselect>
                <!-- <pre class="language-json"><code>{{selectedPosition}}</code></pre> -->
              </div>
            </div>
            <div class="pl-20">
              <div class="flex">
                <label
                  class="inline-block w-32 text-base text-gray2 font-normal mr-11 self-center"
                  >{{$t('mjc-titp-tbl1-col2-row1')}}</label
                >
                <VueMultiselect
                  class="w-96 my-2.5"
                  v-model="selectedJobSubTypes"
                  tag-placeholder="Add this as new position"
                  :placeholder="$t('eanj-para10')"
                  label="job_sub_type"
                  track-by="id"
                  :options="jobSubTypes"
                  openDirection="bottom"
                  @select="getJobSubTypeOperations"
                  @tag="addTitpJobSubType"
                  :taggable="true"
                ></VueMultiselect>
                <!-- <pre class="language-json"><code>{{selectedPosition}}</code></pre> -->
              </div>
            </div>
            <div class="pl-20">
              <div class="flex">
                <label
                  class="inline-block w-32 text-base text-gray2 font-normal mr-11 self-center"
                  >{{$t('mjc-titp-tbl1-col3-row1')}}</label
                >
                <VueMultiselect
                  class="w-96 my-2.5"
                  v-model="selectedJobSubTypeOperations"
                  tag-placeholder="Add this as new position"
                  :placeholder="$t('eanj-para10')"
                  label="operations"
                  track-by="id"
                  :options="jobSubTypeOperations"
                  openDirection="bottom"
                  @tag="addTitpJobSubTypeOperations"
                  :taggable="true"
                ></VueMultiselect>
                <!-- <pre class="language-json"><code>{{selectedPosition}}</code></pre> -->
              </div>
            </div>
          </div>
          <div>
            <!--============= Position ============  -->
            
              <div class="flex pl-20">
                <div class="self-center w-32 mr-11">
                  <label
                    class="inline-block text-base text-gray2 font-normal "
                    >{{$t('mjc-tbl1-col1-row2-txt')}}<span class="text-jobRed"
                                            >*</span
                                          ></label
                  >
                  </div>

                <div class="flex flex-col w-96">
                  <div>
                    <VueMultiselect
                      class=" my-2.5"
                      v-model="selectedPosition"
                      tag-placeholder="Add this as new position"
                      :placeholder="$t('eanj-para10')"
                      label="position"
                      track-by="id"
                      :options="positions"
                      :multiple="false"
                      :taggable="true"
                      @tag="addPosition"
                      openDirection="bottom"
                      @select="positionSelected"
                      
                    ></VueMultiselect>
                  </div>

                  <span v-if="positionErrMsg === 'invalid'" class="text-xs text-jobRed"
                      >{{$t('eanj-para8')}}</span
                    >
                  
                </div>
              </div>
         

            <!--============= Job Title ============  -->
            
              <div class="flex pl-20">
                <label
                  class="inline-block w-32 text-base text-gray2 font-normal mr-11 self-center"
                  >{{$t('mjc-tbl1-col2-row1')}}<span class="text-jobRed"
                                            >*</span
                                          ></label
                >
                <div class="flex flex-col w-96">
                  <div>
                    <VueMultiselect
                      class="my-2.5"
                      v-model="selectedJobTitle"
                      tag-placeholder="Add this as new job title"
                      :placeholder="$t('eanj-para11')"
                      label="job_title"
                      track-by="id"
                      :options="jobTitles"
                      :multiple="false"
                      :taggable="true"
                      @tag="addJobTitle"
                      openDirection="bottom"
                      @select="jobTitleSelected"
                      
                    ></VueMultiselect>
                </div>
                <span v-if="jobTitleErrMsg === 'invalid'" class="text-xs text-jobRed"
                      ><!-- {{$t('eanj-para8')}} -->{{$t('mjc-tbl1-col2-row1')}}</span>
               
              </div>
              </div>
            

            <!--============= Summary ============  -->
            <div class="flex flex-col">
            <div class="pl-20 flex ">
              <div class="w-32 mr-11">
                  <label
                    class="inline-block text-base text-gray2 font-normal pt-4"
                    for="summary"
                    >{{$t('eanj-txt1')}}<span class="text-jobRed"
                    >*</span
                    ></label
                  >
                </div>
                  <textarea
                    class="border border-textGray w-96 my-2.5 pl-5 rounded-md outline-none"
                    name="Summary"
                    id="summary"
                    cols=""
                    rows="3"
                    :placeholder="$t('eanj-txt1')"
                    v-model="summaryAdded"
                                  
                  ></textarea>
              
            </div>
              <span v-if="summaryErrMsg === 'invalid'" class="text-xs text-jobRed ml-64"
                      >{{$t('eanj-para8')}} </span
                    >
            </div>
          

            <!--============= Job Description ============  -->
            <div class="flex flex-col">
            <div class="pl-20 flex">
              <div class="w-32 mr-11">
                  <label
                    class="inline-block text-base text-gray2 items-start font-normal   pt-4"
                    for="jobDescription"
                    >{{$t('eanj-txt2')}}<span class="text-jobRed"
                        >*</span
                       ></label
                  >
                </div>
                  <textarea
                    class="border border-textGray my-2.5 pl-5 w-96 rounded-md outline-none"
                    name="Job Description"
                    id="jobDescription"
                    cols=""
                    rows="3"
                    :placeholder="$t('eanj-txt2')"
                    v-model="jobDescriptionAdded"
                    @blur="checkJobDescription"
                  ></textarea>
               
            </div>
                <span v-if="jobDescriptionErrMsg === 'invalid'" class="text-xs text-jobRed ml-64"
                      >{{$t('eanj-para8')}}</span
                    >
            </div>
            

            <!--============= Strength ============  -->
            <div class="pl-20 flex">
              <label
                class="inline-block w-32 text-base text-gray2 font-normal mr-11 self-center"
                for="strength"
                >{{$t('eanj-txt4')}}</label
              >
           
              <VueMultiselect
                class="w-96 my-2.5"
                v-model="selectedStrength"
                tag-placeholder="Add this as new strength"
                :placeholder="$t('eanj-para12')"
                label="strength"
                track-by="id"
                :options="strengths"
                :multiple="true"
                :taggable="true"
                @tag="addStrength"
                openDirection="bottom"
                
              ></VueMultiselect>
            </div>

            <!--============= Key Skills ============  -->
            <div class="pl-20">
              <div class="flex">
                <label
                  class="inline-block w-32 text-base text-gray2 font-normal mr-11 self-center"
                  >{{$t('em-tbl1-col2-row3')}}</label
                >
                <VueMultiselect
                  class="w-96 my-2.5"
                  v-model="selectedKeySkill"
                  tag-placeholder="Add this as new keyskill"
                  :placeholder="$t('eanj-para13')"
                  label="skills"
                  track-by="id"
                  :options="keySkills"
                  :multiple="true"
                  :taggable="true"
                  @tag="addKeySkill"
                  openDirection="bottom"
                  :allowEmpty="false"
                ></VueMultiselect>
             
              </div>
            </div>

            <!--============= Function Area ============  -->
            <div class="pl-20">
              <div class="flex">
                <label
                  class="inline-block w-32 text-base text-gray2 font-normal mr-11 self-center"
                  >{{$t('em-tbl1-col3-row3')}}</label
                >
                <VueMultiselect
                  class="w-96 my-2.5"
                  v-model="selectedFunctionArea"
                  tag-placeholder="Add this as new function area"
                  :placeholder="$t('eanj-para14')"
                  label="function_area"
                  track-by="id"
                  :options="functionAreas"
                  :multiple="false"
                  :taggable="true"
                  @tag="addFunctionArea"
                  openDirection="bottom"
                  :allowEmpty="false"
                ></VueMultiselect>
                
              </div>
            </div>

            <!--============= Closing Date ============  -->
            <div class="flex flex-col">
            <div class="pl-20 flex">
              <div class="w-32 mr-11 self-center">
                  <label
                    class="inline-block  text-base text-gray2 font-normal"
                    for="closingDate"
                    >{{$t('ejl-col5-title')}}</label
                  >
                </div>

                  <input
                    type="date"
                    class="border border-textGray my-2.5 pl-5 w-96 h-9 rounded-md outline-none"
                    name="date"
                    id="closingDate"
                    v-model="selectedDate"
                    />
            </div>
            <span v-if="dateMsg === 'invalid'" class="text-xs text-jobRed ml-64"
                          >{{$t('eanj-para9')}}</span
                        >
            </div>
       
            

            <!--============= Department ============  -->
            <div class="pl-20">
              <div class="flex">
                <label
                  class="inline-block w-32 text-base text-gray2 font-normal mr-11 self-center"
                  >{{$t('er-per-row5')}}</label
                >
                <VueMultiselect
                  class="w-96 my-2.5"
                  v-model="selectedDepartment"
                  tag-placeholder="Add this as new department"
                  :placeholder="$t('eanj-para15')"
                  label="department"
                  track-by="id"
                  :options="departments"
                  :multiple="false"
                  :taggable="true"
                  @tag="addDepartment"
                  openDirection="bottom"
                  :allowEmpty="false"
                ></VueMultiselect>
                <!-- <pre class="language-json"><code>{{selectedDepartment}}</code></pre> -->
              </div>
            </div>

            <!--============= Location ============  -->
            <div class="pl-20 flex flex-col">
              <div class="flex">
                <div class="self-center">
                  <label
                    class="inline-block w-28 text-base text-gray2 font-normal mr-10"
                    for="jobLocation"
                    >{{$t('eanj-txt9')}}<span class="text-jobRed"
                                            >*</span
                                          ></label
                  >
                </div>

                <div>
                  <VueMultiselect
                    class="border-textGray w-44 mr-6 my-2.5 pl-5 rounded-md"
                    v-model="selectedCity"
                    tag-placeholder=""
                    placeholder="Cities"
                    label="city_name"
                    track-by="id"
                    :options="cities"
                    :multiple="false"
                    @select="getStates"
                    openDirection="bottom"
                    
                  ></VueMultiselect>
                </div>
                <input
                  v-model="selectedState"
                  type="text"
                  class=" border border-textGray w-44 mr-6 ml-2 my-2.5 pl-5 py-1 rounded-md"
                  name="state"
                  id="stateId"
                  placeholder="State"
                  required
                  disabled
                />
              </div>
              <span
                v-if="msgForCity === 'invalid'"
                class="text-xs ml-44  mt-1
                                      text-xs
                                       text-jobRed"
                >{{$t('eanj-para8')}}</span
              >
             
              <!-- div for country -->
              <div class="">
                <input
                  v-model="selectedCountry"
                  type="text"
                  class="border border-textGray my-2.5 ml-lg pl-5 w-96 py-2.5 rounded-md"
                  name="country"
                  id="countryId"
                  placeholder="Country"
                  required
                  disabled
                />
              </div>
            </div>

            <!--============= Visa Status ============  -->
        
              <div class="pl-20 flex w-full">
                <div class = "w-32 mr-11 self-center">
                <label
                  class="inline-block  text-base text-gray2 font-normal self-center"
                  >{{$t('p-tbl2-col3-row4')}}<span class="text-jobRed"
                                            >*</span
                                          ></label
                >
                </div>
                <div class = "flex flex-col w-96">
                  <div >
                    <VueMultiselect
                      class=" my-2.5"
                      v-model="selectedVisaStatus"
                      :options="visaStatuss"
                      :placeholder="$t('eanj-para21')"
                      track-by="id"
                      label="visa_status"
                      openDirection="bottom"
                      @select="isVisaStatusSelected"
                      @tag="addVisaStatus"
                      :taggable="true"
                    >
                    </VueMultiselect>
                    </div>
                 <span
                v-if="visaStatusErrMsg === 'invalid'"
                class="  text-xs text-jobRed ml-2"
                >{{$t('eanj-para8')}}</span
              >
                
              </div>
              </div>
            

            <!--============= Hiring Manager Name ============  -->
            <div class="pl-20 flex">
              <label
                class="inline-block w-32 text-base text-gray2 font-normal mr-11 self-center"
                for="hiringManagerName"
                >{{$t('eanj-txt10')}}<span class="text-jobRed"
                                            >*</span
                                          ></label
              >
              <input
                type="text"
                class="border border-textGray my-2.5 pl-5 w-96 h-9 rounded-md outline-none"
                id="hiringManagerName"
                placeholder="Hiring Manager Name"
                name="hiringManager"
                v-model="hiringManagerName"
              />
              
            </div>

            <!--============= Hiring Manager Email ID ============  -->
            <div class="pl-20 flex">
              <label
                class="inline-block w-32 text-base text-gray2 font-normal mr-11 self-center"
                for="hiringManangerEmailId"
                >{{$t('eanj-txt11')}}<span class="text-jobRed"
                                            >*</span
                                          ></label
              >
              <input
                type="email"
                class="border border-textGray my-2.5 pl-5 w-96 h-9 rounded-md outline-none"
                id="hiringManangerEmailId"
                :placeholder="$t('eanj-txt11')"
                name="hiringManagerEmail"
              />
            </div>

            <!--============= Hiring Manager Phone Number ============  -->
            <div class="pl-20 flex">
              <label
                class="inline-block w-32 text-base text-gray2 font-normal mr-11 self-center"
                for="hiringManagerPhoneNumber"
                >{{$t('evj-tbl3-txt4')}}</label
              >
              <input
                type="number"
                class="border border-textGray my-2.5 pl-5 w-96 h-9 rounded-md outline-none"
                id="hiringManagerPhoneNumber"
                :placeholder="$t('eanj-txt12')"
                name="hiringManagerPhone"
              />
            </div>

            <!--============= Language ============  -->
            <div class="pl-20">
              <div class="flex flex-col">
                  <div class="flex">
                    <label
                      class="inline-block w-32 text-base text-gray2 font-normal mr-11 self-center"
                      for="language"
                      >{{$t('head-l')}}<span class="text-jobRed"
                                                >*</span
                                              ></label
                    >
                    <VueMultiselect
                      class="w-44 mr-7 my-2.5"
                      v-model="selectedLanguage"
                      tag-placeholder="Add this as new language"
                      :placeholder="$t('head-l')"
                      label="language"
                      track-by="id"
                      :options="languages"
                      :taggable="true"
                      selectLabel="select"
                      deselectLabel="remove"
                      @tag="addLanguage"
                      openDirection="bottom"
                      @select ="isLangSelected"
                    >
                    </VueMultiselect>
                    
                    <VueMultiselect
                      class="w-44 my-2.5"
                      v-model="selectedLanguageLevel"
                      :options="languageLevels"
                      :placeholder="$t('eanj-para22')"
                      track-by="id"
                      label="language_level"
                      selectLabel="select"
                      deselectLabel="remove"
                      openDirection="bottom"
                    >
                    </VueMultiselect>
                  </div>
               <span
                v-if="languageErrMsg === 'invalid'"
                class="  text-xs text-jobRed ml-44 mb-2"
                >{{$t('eanj-para8')}}</span
              >
               
              </div>
              <div class="ml-48 flex">
                <span class="text-sm">{{$t('eanj-txt13')}}</span>

                <div class="flex justify-center">
                  <input
                    class="radioLang invisible"
                    v-model="selectedLanguageAboveBelow"
                    type="radio"
                    id="LanguageBelow"
                    name="aboveBelow"
                    value="Below"
                  />

                  <label
                    class="
                        cursor-pointer
                        text-xs
                        px-2
                        py-
                        bg-btnBgGray
                        hover:bg-langGray
                        focus:bg-langGray
                        rounded-md
                      "
                    for="LanguageBelow"
                    >{{$t('mjc-tbl3-col2-row1-opt1')}}</label
                  >
                  <input
                    class="radioLang invisible"
                    v-model="selectedLanguageAboveBelow"
                    type="radio"
                    id="languageAbove"
                    name="aboveBelow"
                    value="Above"
                  />
                  <label
                    class="
                        cursor-pointer
                        text-xs
                        px-2
                        py-
                        bg-btnBgGray
                        hover:bg-langGray
                        focus:bg-langGray
                        rounded-md
                      "
                    for="languageAbove"
                    >{{$t('mjc-tbl3-col3-row1-opt2')}}</label
                  >
                </div>
              </div>
              <div class="ml-44 mt-2">
                <button
                  type="button"
                  class="bg-gray3 text-white px-7 py-1 rounded-3xl my-2.5"
                  @click="getLang()"
                >
                  {{$t('em-tbl1-col1-row3-button')}}
                </button>
              </div>

              <div
                class="border border-textGray rounded-md ml-lg my-2.5 h-24 overflow-auto"
              >
                <div
                  v-for="(tag, i) in tags"
                  :key="i"
                  class="inline-block border rounded-xl bg-gray-100 py-1 px-3 mx-3 my-1 text-center relative"
                >
                  {{ tag.lang.language }} - {{ tag.langAB }} -
                  {{ tag.lvl.language_level }}
                  <span
                    @click="removeTag(i)"
                    class="text-red-500 absolute right-0 -top-1 cursor-pointer"
                    ><svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.2447 1.75536C7.90431 -0.585003 4.09601 -0.585234 1.75538 1.75536C-0.585243 4.09595 -0.585012 7.90397 1.75538 10.2446C4.09578 12.5851 7.90408 12.5851 10.2447 10.2446C12.5851 7.90397 12.5851 4.09572 10.2447 1.75536ZM8.36722 8.3671C8.1869 8.54741 7.89461 8.54741 7.7143 8.3671L6.00004 6.65286L4.20429 8.4486C4.02397 8.62891 3.73168 8.62891 3.55137 8.4486C3.37105 8.26828 3.37105 7.976 3.55137 7.79569L5.34712 5.99996L3.63287 4.28572C3.45255 4.10541 3.45255 3.8129 3.63287 3.63282C3.81318 3.4525 4.10547 3.4525 4.28579 3.63282L6.00004 5.34705L7.63257 3.71454C7.81288 3.53423 8.10517 3.53423 8.28549 3.71454C8.4658 3.89486 8.4658 4.18714 8.28549 4.36745L6.65296 5.99996L8.36722 7.71419C8.54753 7.8945 8.54753 8.18679 8.36722 8.3671Z"
                        fill="#F9444B"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>

            <!--============= Industry Type ============  -->
            <div class="pl-20">
              <div class="flex">
                <label
                  class="inline-block w-32 text-base text-gray2 font-normal mr-11 self-center"
                  >{{$t('mjc-tbl1-col1-row5')}}</label
                >
                <VueMultiselect
                  class="w-96 my-2.5"
                  v-model="selectedIndustry"
                  tag-placeholder="Add this as new industry type"
                  :placeholder="$t('eanj-para16')"
                  label="industry"
                  track-by="id"
                  :options="industries"
                  @select="getIndustrySubCategory"
                  :taggable="true"
                  @tag="addIndustry"
                  openDirection="bottom"
                ></VueMultiselect>
              </div>
            </div>

            <!--============= Industry Sub-Category ============  -->
            <div class="pl-20">
              <div class="flex">
                <label
                  class="inline-block w-32 text-base text-gray2 font-normal mr-11 self-center"
                  >{{$t('mjc-tbl1-col2-row5')}}</label
                >
                <VueMultiselect
                  class="w-96 my-2.5"
                  v-model="selectedIndustrySubCategory"
                  tag-placeholder="Add this as new industry sub category"
                  :placeholder="$t('eanj-para17')"
                  label="industry_sub_category"
                  track-by="id"
                  :options="industrySubCategories"
                  :taggable="true"
                  @tag="addIndustrySubCategory"
                  openDirection="bottom"
                ></VueMultiselect>
                <!-- <pre class="language-json"><code>{{selectedIndustrySubCategory}}</code></pre> -->
              </div>
            </div>

            <!--============= Experience Level ============  -->
            <div class="pl-20 my-2.5 flex">
              <div>
                <label
                  class="inline-block w-32 text-base text-gray2 items-start font-normal mr-11 pt-2"
                  for="experienceLevel"
                  >{{$t('eanj-txt14')}}<span class="text-jobRed"
                                            >*</span
                                          ></label
                >
              </div>
              <div class="grid gap-x-10 grid-cols-3">
                <li
                  v-for="experienceLevel in experienceLevels"
                  v-bind:key="experienceLevel.id"
                  class="list-none w-40 leading-8"
                >
                  <label>
                    <input
                      type="radio"
                      class="mr-1 border border-textGray rounded-md"
                      v-model="selectedExperienceLevel"
                      :value="experienceLevel.id"
                    />{{ experienceLevel.experience_level }}
                  </label>
                </li>
              </div>
            </div>

            <!--============= Job Type ============  -->
            <div class="pl-20 my-2.5 flex">
              <div>
                <label
                  class="inline-block w-32 text-base text-gray2 items-start font-normal mr-11 pt-2"
                  for="jobType"
                  >{{$t('em-tbl1-col1-row5')}}<span class="text-jobRed"
                                            >*</span
                                          ></label
                >
              </div>
              <div class="grid gap-x-10 grid-cols-3">
                <li
                  v-for="jobType in jobTypes"
                  v-bind:key="jobType.id"
                  class="list-none w-40 leading-8"
                >
                  <label>
                    <input
                      type="checkbox"
                      class="mr-1"
                      v-model="selectedJobType"
                      :value="jobType.id"
                    />{{ jobType.job_type }}
                  </label>
                </li>
              </div>
            </div>

            <!--============= Work Style ============  -->
              <div class= "flex flex-col">
              <div class="flex pl-20">
                <label
                  class="inline-block w-32 text-base text-gray2 font-normal mr-11 self-center"
                  >{{$t('mjc-tbl1-col3-row1')}}<span class="text-jobRed"
                                            >*</span
                                          ></label
                >
                <VueMultiselect
                  class="w-96 my-2.5"
                  v-model="selectedWorkStyle"
                  :options="workStyles"
                  :placeholder="$t('eanj-para23')"
                  track-by="id"
                  label="work_style"
                  openDirection="bottom"
                  @select="isWorkStyleSelected"
                >
                </VueMultiselect>
              </div>
              <span
                v-if="workStyleErrMsg === 'invalid'"
                class="  text-xs text-jobRed ml-64 mb-2"
                >{{$t('eanj-para8')}}</span
              >
              </div>
            

            <!--============= Education ============  -->
            <div class = "flex flex-col">
              <div class="flex pl-20">
                <label
                  class="inline-block w-32 text-base text-gray2 font-normal mr-11 self-center"
                  >{{$t('p-tab2-title')}}<span class="text-jobRed"
                                            >*</span
                                          ></label
                >
                <VueMultiselect
                  class="w-96 my-2.5"
                  v-model="selectedDegree"
                  tag-placeholder="Add this as new degree"
                  :placeholder="$t('eanj-para18')"
                  label="course_name"
                  track-by="id"
                  :options="course"
                  :multiple="false"
                  :taggable="true"
                  @tag="addDegree"
                  openDirection="bottom"
                  @select="isEducationSelected"
                ></VueMultiselect>
                
              </div>
              <span
                v-if="educationErrMsg === 'invalid'"
                class="  text-xs text-jobRed ml-64 mb-2"
                >{{$t('eanj-para8')}}</span
              >
              </div>
          

            <!--============= Experience ============  -->
            <div class="pl-20 flex">
              <label
                class="inline-block w-32 text-base text-gray2 font-normal mr-11 self-center"
                for="experience"
                >{{$t('sds-menu6')}}<span class="text-jobRed"
                                            >*</span
                                          ></label
              >
              <input
                type="number"
                class="border border-textGray my-2.5 pl-5 w-96 h-9 rounded-md outline-none"
                name=""
                id="experience"
                placeholder="2 Year"
              />
            </div>

            <!--============= Salary ============  -->
            
              <div class="flex pl-20">
                <div class="self-center w-32 mr-11">
                  <label
                    class="inline-block text-base text-gray2 font-normal  "
                    >{{$t('mjc-tbl2-col5-row1')}}</label
                  >
                </div>

                <div class="flex ">
                  <div>
                      <input
                      type="number"
                      class="border border-textGray pl-2.5 w-44 h-9 mr-7 my-2.5 rounded-md outline-none"
                      name="salaryFrom"
                      id="salary1"
                      placeholder="From"
                      v-model="selectedSalaryFrom"
                    />
                  </div>

                  <div>
                    <input
                      type="number"
                      class="border border-textGray pl-2.5 h-9 w-44 my-2.5 rounded-md outline-none"
                      name="salaryTo"
                      id="salary2"
                      placeholder="To"
                      v-model="selectedSalaryTo"
                    />
                  </div>
              </div>

              </div>
            

            <!--============= Currency ============  -->
            <div class="pl-20 flex">
              <label
                class="inline-block w-32 text-base text-gray2 font-normal mr-11 self-center"
                for="status"
                >{{$t('sds-menu3-num2')}}</label
              >
              <VueMultiselect
                class="w-96 my-2.5"
                v-model="selectedCurrency"
                :options="currencies"
                placeholder="Currency"
                track-by="id"
                label="currency"
                openDirection="bottom"
              >
              </VueMultiselect>
            </div>
            <!--============= Status ============  -->
            <div class="pl-20 flex">
              <label
                class="inline-block w-32 text-base text-gray2 font-normal mr-11 self-center"
                for="status"
                >{{$t('evja-tbl7-txt10')}}</label
              >
              <VueMultiselect
                class="w-96 my-2.5"
                v-model="selectedStatus"
                :options="statuss"
                placeholder="Select Status"
                track-by="id"
                label="status"
                openDirection="bottom"
              >
              </VueMultiselect>
            </div>

            <!--============= WorkFlow ============  -->
            <div class="flex flex-col">
            <div class="pl-20 flex">
                <div class="self-center">
                    <label
                      class="inline-block w-32 text-base text-gray2 font-normal mr-11"
                      for="workflow"
                      >{{$t('anr-col1-txt1')}}<span class="text-jobRed"
                                            >*</span
                                          ></label
                    >
                </div>
              <div class="flex w-96 justify-between">
                  <button
                    class="mt-2 bg-gray3 text-white px-7 py-1 mr-7 rounded-3xl"
                    type="button"
                  >
                    {{$t('eanj-op1')}}
                  </button>
                  <div class="">
                      <VueMultiselect
                        class="w-44 mt-2.5"
                        v-model="selectedWorkflow"
                        :options="workflows"
                        :placeholder="$t('anr-col1-txt1')"

                        track-by="id"
                        label="status"
                        openDirection="bottom"
                        selectLabel=""
                        deselectLabel=""
                        @select="isWorkFlowSelected"
                      >
                      </VueMultiselect>
                  </div>
              </div>
            </div>
            <span
                v-if="workFlowErrMsg === 'invalid'"
                class="  text-xs text-jobRed inline-flex justify-end mb-2"
                >{{$t('eanj-para8')}}</span
              >
          </div>
        </div>
        </div>

        <!--============= Submit ============  -->
        <div class="flex pl-64 mt-7 pb-3 w-96 justify-between">
         
              <button type="submit" class="border px-9 py-1 mr-3 bg-btnBgGray text-gray-800 rounded-3xl">
                {{$t('p-tbl4-button1')}}
              </button>
            
            
              <button type="cancel" class="border px-9 py-1 mr-3 bg-btnBgGray text-gray-800 rounded-3xl">{{$t('sds-menu2-db-btn2')}}</button>
           
          
              <button type="button" class="border px-9 py-1 mr-3 bg-jobRed text-white rounded-3xl">{{$t('ewf-btn5')}}</button>
              
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import ProfileJobSidebar from "../components/ProfileJobSidebar.vue";
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
export default {
  name: "AddNewJobContainer",
  el: "#showModal",

  data: function() {
    const schema = yup.object({
      hiringManager: yup.string().required().min(3),
    });
    return {
      schema,
      hiringManagerName: "",
      summaryAdded: "",
      jobDescriptionAdded: "",
      selectedTITP: 0,
      positions: [],
      selectedPosition: [],
      jobTitles: [],
      selectedJobTitle: [],
      keySkills: [],
      selectedKeySkill: [],
      functionAreas: [],
      selectedFunctionArea: [],
      departments: [],
      selectedDepartment: [],
      selectedLanguage: "",
      languages: [],
      selectedLanguageLevel: "",
      languageLevels: [],
      selectedLanguageAboveBelow: "",
      showLang: false,
      tags: [],
      industries: [],
      selectedIndustry: [],
      industrySubCategories: [],
      selectedIndustrySubCategory: [],
      industryId: "",
      experienceLevels: [],
      selectedExperienceLevel: "",
      jobTypes: [],
      selectedJobType: [],
      workStyles: [],
      selectedWorkStyle: [],
      visaStatuss: [],
      selectedVisaStatus: [],
      degrees: [],
      selectedDegree: [],
      selectedStatus: { id: "0", status: "Publish" },
      statuss: [
        { id: "1", status: "Publish" },
        { id: "2", status: "Unpublish" },
        { id: "3", status: "Draft" },
      ],
      selectedWorkflow: [],
      workflows: [
        { id: "1", status: "WorkFlow1" },
        { id: "2", status: "Workflow2" },
        { id: "3", status: "Workflow3" },
      ],
      salaries: [],
      selectedSalaryFrom: [],
      selectedSalaryTo: [],
      currencies: [],
      selectedCurrency: [],

      cities: [],
      cityId: [],
      selectedCity: "",
      option: [],
      selectedState: "",
      selectedCountry: "",
      course: [],

      selectedTitpJobTypes: [],
      TitpjobTypes: [],

      selectedJobSubTypes: "",
      jobSubTypes: [],

      selectedJobSubTypeOperations: "",
      jobSubTypeOperations: [],

      jobTypeId: [],
      JobSubTypeId: [],
      strengths: [],
      selectedStrength: "",

      // validation fields messages variables
      positionErrMsg: "",
      jobTitleErrMsg: "",
      summaryErrMsg:"",
      jobDescriptionErrMsg:"",
      dateMsg:'',
      visaStatusErrMsg:'',
      languageErrMsg:'',
      msgForCity: "",
      workStyleErrMsg: "",
      educationErrMsg: "",
      workFlowErrMsg: "",

      selectedDate:null,
    };
  },

  components: {
    Form,
    Field,
    ErrorMessage,
  },

  methods: {
    
    positionSelected($event){
      if($event.id){
        this.positionErrMsg = ""
      }

    },
    jobTitleSelected($event){
      if($event.id){
          this.jobTitleErrMsg = ""
        }
    },

    isVisaStatusSelected($event){
      if($event.id){
          this.visaStatusErrMsg = ""
        }
    },
    isLangSelected($event){
      if($event.id){
          this.languageErrMsg = ""
        }
    },
    isWorkStyleSelected($event){
      if($event.id){
          this.workStyleErrMsg = ""
        }
    },
    isEducationSelected($event){
      if($event.id){
          this.educationErrMsg = ""
        }
    },
    isWorkFlowSelected($event){
      if($event.id){
          this.workFlowErrMsg = ""
        }
    },
  
    checkForm(e) {
      // Position validation
      if (!(this.selectedPosition && this.selectedPosition.length !== 0)) {
        this.positionErrMsg = "invalid"; 
      }

    //  Job_Title validation
      if(!(this.selectedJobTitle && this.selectedJobTitle.length !== 0)){
        this.jobTitleErrMsg = "invalid";
      }
      // summary validation
      if(!(this.summaryAdded)){
        this.summaryErrMsg = "invalid";
      }else{
        this.summaryErrMsg = "";
      }

      // job description validation
      if(!(this.jobDescriptionAdded && this.jobDescriptionAdded.length !== 0)){
        this.jobDescriptionErrMsg = "invalid";
      }else{
        this.jobDescriptionErrMsg = "";
      }

      // job Location validation
      if(!(this.selectedCity && this.selectedCity.length !== 0)) {
        this.msgForCity = "invalid";
      }else{
        
      }
      // Closing date validation
      let currentDate = new Date();
      let selectedDateFormate = new Date(this.selectedDate);

      if(!(selectedDateFormate > currentDate)){
        this.dateMsg = "invalid";
      }else{
        this.dateMsg = "";
      }

      // visa status validation
      if(!(this.selectedVisaStatus && this.selectedVisaStatus.length !== 0)){
        this.visaStatusErrMsg = "invalid";
      }else{
        
      }
      // language validation
      if(!(this.selectedLanguage && this.selectedLanguage.length !== 0)){
        this.languageErrMsg = "invalid";
      }else{
        
      }
      // work style validation
      if(!(this.selectedWorkStyle && this.selectedWorkStyle.length !== 0)){
        this.workStyleErrMsg = "invalid";
      }
      
      // education validation
      if(!(this.selectedDegree && this.selectedDegree.length !== 0)){
        this.educationErrMsg = "invalid";
      }
      // work flow validation
      if(!(this.selectedWorkflow && this.selectedWorkflow.length !== 0)){
        this.workFlowErrMsg = "invalid";
      }

      e.preventDefault();
    },
    
    getCity() {
      this.axios
        .get(this.baseURL + "cities", {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.cities = response.data.data;
          console.warn(response.data.data);
        })
        .catch((err) => {});
      this.apiLoaded = true;
    },
    getStates($event) {
      this.cityId = $event.id;
      this.axios
        .get(this.baseURL + "states", {
          params: {
            id: this.cityId,
          },
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.option = response.data.data.option;

          this.selectedState = this.option.state_name;
          this.selectedCountry = this.option.country_name;

          console.warn(response.data.data.option);
        })
        .catch((err) => {
          console.log(err);
        });
      this.apiLoaded = true;

      // checking city selected in dropdown or not
       if($event.id){
         this.msgForCity = "";
      }
    },

    openSidebar() {
      this.sidebarview = true;
    },
    closeSidebar() {
      this.sidebarview = false;
    },

    getLang() {
      if (
        this.selectedLanguage === "" ||
        this.selectedLanguageLevel === "" ||
        this.selectedLanguageAboveBelow === ""
      )
        return;
      let obj = {
        lang: this.selectedLanguage,
        lvl: this.selectedLanguageLevel,
        langAB: this.selectedLanguageAboveBelow,
      };
      if (!this.containsObject(obj, this.tags)) this.tags.push(obj);
      this.showLang = true;

      this.selectedLanguage = "";
      this.selectedLanguageLevel = "";
      this.selectedLanguageAboveBelow = "";
    },
    removeTag(i) {
      this.tags.splice(i, 1);
    },
    containsObject(obj, list) {
      for (let i = 0; i < list.length; i++) {
        if (
          list[i].lang === obj.lang &&
          list[i].lvl === obj.lvl &&
          list[i].langAB === obj.langAB
        ) {
          return true;
        }
      }
      return false;
    },
    addPosition(newPosition) {
      const tagPosition = {
        position: newPosition,
        // code: newPosition.substring(0, 2) + Math.floor((Math.random() * 10000000))
      };
      this.positions.push(tagPosition);
      this.selectedPosition.push(tagPosition);
    },

    addVisaStatus(newStatus){
      const tagStatus = {
        visa_status:newStatus,
         // code: newStatus.substring(0, 2) + Math.floor((Math.random() * 10000000))
      };
      this.visaStatuss.push(tagStatus);
      this.selectedVisaStatus.push(tagStatus);
    },
    addJobTitle(newJobTitle) {
      const tagJobTitle = {
        job_title: newJobTitle,
        // code: newJobTitle.substring(0, 2) + Math.floor((Math.random() * 10000000))
      };
      this.jobTitles.push(tagJobTitle);
      this.selectedJobTitle.push(tagJobTitle);
    },
    addKeySkill(newKeySkill) {
      const tagKeySkill = {
        skills: newKeySkill,
        // code: newKeySkill.substring(0, 2) + Math.floor((Math.random() * 10000000))
      };
      this.keySkills.push(tagKeySkill);
      this.selectedKeySkill.push(tagKeySkill);
    },
    addFunctionArea(newFunctionArea) {
      const tagFunctionArea = {
        function_area: newFunctionArea,
        // code: newFunctionArea.substring(0, 2) + Math.floor((Math.random() * 10000000))
      };
      this.functionAreas.push(tagFunctionArea);
      this.selectedFunctionArea.push(tagFunctionArea);
    },
    addDepartment(newDepartment) {
      const tagDepartment = {
        department: newDepartment,
        // code: newDepartment.substring(0, 2) + Math.floor((Math.random() * 10000000))
      };
      this.departments.push(tagDepartment);
      this.selectedDepartment.push(tagDepartment);
    },
    addLanguage(newLanguage) {
      const tagLanguage = {
        language: newLanguage,
        // code: newLanguage.substring(0, 2) + Math.floor((Math.random() * 10000000))
      };
      this.languages.push(tagLanguage);
      this.selectedLanguage.push(tagLanguage);
    },
    addIndustry(newIndustry) {
      const tagIndustry = {
        industry: newIndustry,
        // code: newIndustry.substring(0, 2) + Math.floor((Math.random() * 10000000))
      };
      this.industries.push(tagIndustry);
      this.selectedIndustry.push(tagIndustry);
    },
    addIndustrySubCategory(newIndustrySubCategory) {
      const tagIndustrySubCategory = {
        industry_sub_category: newIndustrySubCategory,
        // code: newIndustrySubCategory.substring(0, 2) + Math.floor((Math.random() * 10000000))
      };
      this.industrySubCategories.push(tagIndustrySubCategory);
      this.selectedIndustrySubCategory.push(tagIndustrySubCategory);
    },
    addDegree(newDegree) {
      const tagDegree = {
        degree: newDegree,
        // code: newDegree.substring(0, 2) + Math.floor((Math.random() * 10000000))
      };
      this.degrees.push(tagDegree);
      this.selectedDegree.push(tagDegree);
    },

    getPosition() {
      this.axios
        .get(this.baseURL + "position", {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.positions = response.data.data;
          console.warn(response.data.data);
        })
        .catch((err) => {});
      this.apiLoaded = true;
    },
    getjobTitle() {
      this.axios
        .get(this.baseURL + "job-title", {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.jobTitles = response.data.data;
          console.warn(response.data.data);
        })
        .catch((err) => {});
      this.apiLoaded = true;
    },
    getKeySkills() {
      this.axios
        .get(this.baseURL + "key-skills", {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.keySkills = response.data.data;
          console.warn(response.data.data);
        })
        .catch((err) => {});
      this.apiLoaded = true;
    },
    getFunctionArea() {
      this.axios
        .get(this.baseURL + "function-area", {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.functionAreas = response.data.data;
          console.warn(response.data.data);
        })
        .catch((err) => {});
      this.apiLoaded = true;
    },
    getDepartment() {
      this.axios
        .get(this.baseURL + "department", {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.departments = response.data.data;
          console.warn(response.data.data);
        })
        .catch((err) => {});
      this.apiLoaded = true;
    },
    getLanguage: function() {
      this.axios
        .get(this.baseURL + "language", {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.languages = response.data.data;
          console.warn(response.data.data);
        })
        .catch((err) => {});
    },
    getLanguageLevel: function() {
      this.axios
        .get(this.baseURL + "language-level", {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.languageLevels = response.data.data;
          console.warn(response.data.data);
        })
        .catch((err) => {});
    },
    getIndustry() {
      this.axios
        .get(this.baseURL + "industry", {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.industries = response.data.data;
          console.warn(response.data.data);
        })
        .catch((err) => {});
    },

    getIndustrySubCategory($event) {
      this.industryId = $event.id;
      this.axios
        .get(this.baseURL + "industry-sub-category", {
          params: {
            id: this.industryId,
          },

          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.industrySubCategories = response.data.data;
          console.warn(response.data.data);
        })
        .catch((err) => {});
    },

    getExperienceLevel() {
      this.axios
        .get(this.baseURL + "experience-level", {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.experienceLevels = response.data.data;
          console.warn(response.data.data);
        })
        .catch((err) => {});
      this.apiLoaded = true;
    },
    getJobType() {
      this.axios
        .get(this.baseURL + "job-type", {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.jobTypes = response.data.data;
          console.warn(response.data.data);
        })
        .catch((err) => {});
      this.apiLoaded = true;
    },
    getWorkStyle() {
      this.axios
        .get(this.baseURL + "work-style", {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.workStyles = response.data.data;
          console.warn(response.data.data);
        })
        .catch((err) => {});
      this.apiLoaded = true;
    },
    getVisaStatus() {
      this.axios
        .get(this.baseURL + "visa-status", {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.visaStatuss = response.data.data;
          console.warn(response.data.data);
        })
        .catch((err) => {});
      this.apiLoaded = true;
    },
    getDegree() {
      this.axios
        .get(this.baseURL + "educational-courses", {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.course = response.data.data;
          console.warn(response.data.data);
        })
        .catch((err) => {});
      this.apiLoaded = true;
    },
    getSalary() {
      this.axios
        .get(this.baseURL + "salary", {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.salaries = response.data.data;
          console.warn(response.data.data);
        })
        .catch((err) => {});
      this.apiLoaded = true;
    },
    getCurrency() {
      this.axios
        .get(this.baseURL + "currency", {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.currencies = response.data.data;
          console.warn(response.data.data);
        })
        .catch((err) => {});
      this.apiLoaded = true;
    },
    getTitpJobType() {
      this.axios
        .get(this.baseURL + "get-titp-job-type", {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.TitpjobTypes = response.data.data;
        })
        .catch((err) => {});
      this.apiLoaded = true;
    },

    getJobSubTypes($event) {

      this.jobTypeId = $event.id;
      this.axios
        .get(this.baseURL + "get-titp-job-subtype", {
          params: {
            id: this.jobTypeId,
          },
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.jobSubTypes = response.data.data;
        })
        .catch((err) => {});
    },
    getJobSubTypeOperations($event) {
      this.JobSubTypeId = $event.id;

      this.axios
        .get(this.baseURL + "get-titp-job-operations", {
          params: {
            id: this.JobSubTypeId,
          },
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.jobSubTypeOperations = response.data.data;
        })
        .catch((err) => {});
    },
    addTitpJob(newJob) {
      const tagJobs = {
        job_type: newJob,
      };
      this.TitpjobTypes.push(tagJobs);
      this.selectedTitpJobTypes.push(tagJobs);
    },
    addTitpJobSubType(newSubType) {
      const tagJobs = {
        job_sub_type: newSubType,
      };
      this.jobSubTypes.push(tagJobs);
      this.selectedJobSubTypes.push(tagJobs);
    },
    addTitpJobSubTypeOperations(newOperation) {
      const tagOperations = {
        operations: newOperation,
      };
      this.jobSubTypeOperations.push(tagOperations);
      this.selectedJobSubTypeOperations.push(tagOperations);
    },

    getStrength() {
      this.axios
        .get(this.baseURL + "strength", {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.strengths = response.data.data;
          console.warn(response.data.data);
        })
        .catch((err) => {});
      this.apiLoaded = true;
    },
    addStrength(newStrength) {
      const tagStrength = {
        strength: newStrength,
        // code: newKeySkill.substring(0, 2) + Math.floor((Math.random() * 10000000))
      };
      this.strengths.push(tagStrength);
      this.selectedStrength.push(tagStrength);
    },
  },
  components: {
    VueMultiselect,
    ProfileJobSidebar,
  },

  props: {},

  beforeMount() {
    this.getStrength();
    this.getPosition();
    this.getjobTitle();
    this.getKeySkills();
    this.getFunctionArea();
    this.getDepartment();
    this.getLanguage();
    this.getLanguageLevel();
    this.getIndustry();
    this.getExperienceLevel();
    this.getJobType();
    this.getWorkStyle();
    this.getVisaStatus();
    this.getDegree();
    this.getSalary();
    this.getCurrency();
    this.getCity();
    this.getTitpJobType();
    this.token = "token updated";
    console.warn(this.token);
  },
};
</script>

<style>
.multiselect__tags {
  min-height: 36px !important;
  display: block;
  padding: 0.5rem 2.5rem 0.5rem 1.5rem !important;
  border-radius: 5px;
  border: 1px solid #444546 !important;
  background: #fff;
  font-size: 16px !important;
}
.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 0rem !important;
  padding-top: 2px;
}
.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px !important;
  border-radius: 15px !important;
  margin-right: 10px;
  color: #000000 !important;
  line-height: 1;
  background: #e5e5e5 !important;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
.multiselect__tag-icon:after {
  content: "×";
  color: #ed1b23 !important;
  font-size: 14px;
}
.radioGender:checked:after {
  width: 12px;
  height: 12px;
  border-radius: 10px;
  top: -7px;
  left: 0px;
  position: relative;
  content: "";
  display: inline-block;
  visibility: visible;
  background-color: #f80a11;
}
.multiselect__single {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
.radioLang:checked + label {
  /* background-color: #e5e5e5; */
  background-color: #afafaf;
  /* color: black; */
  color: rgb(0, 0, 0);
  /* box-shadow: 0.5px 0.5px 0.5px 1px #0000004d; */
  box-shadow: 0.5px 0.5px 0.5px 1px rgb(148, 147, 147);
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
