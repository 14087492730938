<template>
  <section class="relative flex justify-center bg-cover bg-center bg-no-repeat" :style="{backgroundImage: `url(${coverImage})`}">
    <div>
      <h1 class="mt-44 mb-5 text-5xl uppercase text-white">{{title}}</h1>
      <div class='flex justify-center'>
        <hr class="w-28 mb-2 border border-t-2 border-b rounded-full">
      </div>
      <div class='flex justify-center'>
        <hr class="w-16 mb-64 border border-t-2 border-b rounded-full">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name:'HeroSection',
  props: {
  title: {
    type: String,
  },
  coverImage:{
    type:String
  },
}
}
</script>