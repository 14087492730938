export const showViewPaymentModel = (state, {flag, value}) => {
	state.showViewPaymentModel = flag
	state.paymentHistoryId = value	
	
}

export const setPayementHistory = (state, data) => {
    state.paymentHistory.data = data.data.payment_history.data
    state.paymentHistory.links = data.data.payment_history
}
