const getMainHeaderLinks = (state) => {
	return state.headers.mainHeaderLinks
}

const getSidebarLinks = (state) => {
  return state.sidebar
}

const getShowSidebar = (state) => {
  return state.showSidebar
}

const getWebLang = (state) => {
	return state.locale;
}

const getCityData = (state) => {
	return state.cities
}
const getCountryData = (state) => {
	return state.countries
}
const getUserBrief = (state) => {
  return state.userBrief
}
export const getLanguageList = (state) => {
  return state.languageList.data
}
const getRESTApiURL = (state) => {
  return process.env.VUE_APP_LOCAL_API
}
const getMemberShipPlan = (state) => {
  return state.membershipPlan;
}
const getMemberShipPlanDate = (state) => {
  return state.membershipPlanData;
}
const getRESTApiURL_backend = (state) => {
	return process.env.VUE_APP_SMART_RECRUIT_API_URL;
}

const getfeedbackCategory = (state) => {
  return state.feedback.feedbackCategory
}
export default {
  getMainHeaderLinks,
  getWebLang,
  getCityData,
  getCountryData,
  getSidebarLinks,
  getShowSidebar,
  getLanguageList,
  getRESTApiURL,
  getIsAuth() {
    return state.isAuth;
  },
  getUserBrief,
  getMemberShipPlan,
  getMemberShipPlanDate,
  getRESTApiURL_backend,
  getfeedbackCategory,
};

