export const showRejectCompanyApplication = (state, flag) => {
	state.showRejectCompanyApplication = flag
}
export const showSendScoutMailModel = (state, flag) => {
	state.showSendScoutMailModel = flag
}
export const setProjectList = (state, data) => {
	state.projectList.data = data.data
    state.projectList.links = data.links
    state.projectList.meta = data.meta
    // console.log("jkhgjgkj")
}

export const setProjectId = (state, id) =>{
    state.projectId = id;
}


