import { isEmpty } from 'lodash'

export const showInclusion = (state, flag) =>{
  state.inclusions.showInclusion = flag;
}
export const hideInclusion = (state) =>{
  state.inclusions.showInclusion = false;
}
export const showWithPlanInclusion = (state, flag) => {
  state.inclusions.showWithPlanInclusion = flag;
}

export const hideWithPlanInclusion = (state) =>{
  state.inclusions.showWithPlanInclusion = false;
}

export const showTitpInclusion = (state, flag) =>{
  state.inclusions.showTitpInclusion = flag;
}
export const hideTitpInclusion = (state) =>{
  state.inclusions.showTitpInclusion = false;
}

export const showSmartPlannerInclusion = (state, flag) =>{
  state.inclusions.showSmartPlannerInclusion = flag;
}
export const hideSmartPlannerInclusion = (state) =>{
  state.inclusions.showSmartPlannerInclusion = false;
}

export const employerData = (state, data) => {
  state.employerdata = data;
};

export const paymentData = (state, data) => {
  state.paymentData = data;
};

