<template>
<div>
  <div>
    <HeroSection style="background-image:linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(images/contactUs.webp)" :title="($t('ed-menu-txt3'))"/>
  </div>

  <div>
    <div class="container mx-6 mt-14">
      <div class="mr-12 flex justify-center lg:flex ">
        <div class="-mt-20" >
          <SideBar/>
        </div>

        <div class="w-full">
            <ManageJobContainer/>
        </div>
      </div>
    </div> 
    </div> 
</div>   
</template>

<script>
// @ is an alias to /src
import SideBar from '../components/SideBar.vue'
import ManageJobContainer from '../components/ManageJobContainer.vue'
import HeroSection from '../components/HeroSection.vue'



export default {
  name:'ManageJobs',
   data: function() {
      return {
        
      
    }
    
  },

  name: 'ManageJobs',
  components: {
        SideBar,
        ManageJobContainer,
        HeroSection,
       
  },
  methods: {
      
    }, 
};
</script>

<style>

html {
  scroll-behavior: smooth;
}

</style>
