export const SET_JOB_LIST = (state, data) => {
    state.jobList.data = data.data.data ? data.data.data : ''
    state.jobList.links = data.data.meta ? data.data.meta.links : ''
    state.jobList.meta = data.data.meta ? data.data.meta : ''
}

export const SET_APPLIED_JOBS = (state, data) => {
    state.appliedJobs.data = data.data.data.data
    state.appliedJobs.links = data.data.data.links
    state.appliedJobs.meta = {
        current_page: data.data.data.current_page,
        from: data.data.data.from,
        to:  data.data.data.to,
        total: data.data.data.total,
        perPage: data.data.data.per_page
    }
}

export const SET_JOB_DETAILS = (state, data) => {
    state.jobDetails = data.data.data
}

export const SET_JOB_APPLICATION = (state, data) => {
    state.jobApplication = data.data
}

export const SET_HAS_APPLIED = (state, data) => {
    state.has_applied = data
}

export const SET_MY_RESUME = (state, data) => {
    state.myResume = data.data
}
export const SET_APPLICANTS = (state, data) => {
    state.applicants = data.data
}
export const SET_MATCHING_APPLICANTS = (state, data) => {
    state.matching_applicants = data.data
}
export const setJobId = (state, data) => {
    state.jobs.jobId = data;
} 
export const setShowApplyBox = (state, data) => {
    state.showApplyBox = data
}
export const setForEmployer = (state, data) => {
    state.forEmployer = data
}
export const setJobSeekerId = (state, data) => {
    state.jobSeekerId = data
}
export const setInterviewMessage = (state, data) => {
    state.interviewMessage = data
}
export const setVisaInterviewMessage = (state, data) => {
    state.visaInterviewMessage = data
}
export const SET_HIRING_SOURCE = (state, data) => {
    state.hiring_source_titp = data.HiriAuthTitp
    state.hiring_source_nontitp = data.HiriAuthNonTitp
}

export const setShowRejectModel = (state, data) => {
    state.showRejectModel = data
}

export const SET_EMPLOYER_DATA = (state, data) => {
    state.employers = data.data
}

export const RESET_EMPLOYER_DATA = (state, data) => {
    state.employers = []
}

export const SET_JOBSEEKER_RESUME = (state, data) => {
    state.jobseekerResume = data.data
}


export const setProfileList = (state, data) => {
    state.ProfileList.data = data.profileCount
}

export const setResumeLanguage = (state, data) => {
    state.languageList.data = data.data
}

// delete job seeker profilw model 11-08-022
export const showDeleteJobseekerProfile = (state, {flag, profileId, profileLanguage}) => {
    state.showDeleteJobseekerProfile = flag
    state.profileIdDeleted = profileId
    state.profileIdLanguage = profileLanguage
}
export const hideDeleteJobseekerProfile = (state) => {
    state.showDeleteJobseekerProfile = false
}
/////

// make maseter profile model 12-08-022
export const showMakeMasterProfile = (state, {flag, value}) => {
    state.showMakeMasterProfile = flag
    state.profileIdMaster = value
}
export const hideMakeMasterProfile = (state) => {
    state.showMakeMasterProfile = false
}
///

/// make maseter profile model 12-08-022
export const showDeactiveProfile = (state, {flag, value}) => {
    state.showDeactiveProfile = flag
    state.activeDeactive = value

}
export const hideDeactiveProfile = (state) => {
    state.showDeactiveProfile = false
}
////

///// activate warning model 16-08-022
export const showActivateWarning = (state, flag) => {
    state.showActivateWarning = flag
}
export const hideActivateWarning = (state) => {
    state.showActivateWarning = false
}
///// 
// export const showAddNewLanguageOther = (state, flag) => {
//     state.showAddNewLanguageOther = flag
// }
export const setResumeDetails = (state, {profile_type, resume_name}) => {
    state.resumeDetails.profile_type = profile_type;
    state.resumeDetails.resume_name = resume_name;
}
export const setMasterLanguage = (state, data) => {
    state.resumeDetails.resume_language = data;
}

export const SET_JOB_DROPDOWN_LIST = (state, data) => {
    state.setJobDropdownList = data
}

export const SET_CHATBOT_DATA = (state, data) => {
    state.setChatbotData = data
}