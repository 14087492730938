<template>
  <div>  
    <div class="my-16" id="contactUs"> 
      <div class="w-full bg-cover bg-gray-900" >
        <div class="h-full">
          <div class="container mx-auto">
            <div class="md:flex xl:flex p-3 lg:p-8 gap-2">
              <div class="w-full lg:w-6/12 text-white space-y-10">
                <div class="lg:mx-20">                  
                  <div class="space-y-2">
                    <div class="flex items-center font-bold uppercase text-2xl lg:text-3xl border-b-2 border-red-500 w-52">
                      <svg class="fill-current w-7 h-6 mr-1" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 4c1.93 0 5 1.4 5 5.15 0 2.16-1.72 4.67-5 7.32-3.28-2.65-5-5.17-5-7.32C7 5.4 10.07 4 12 4m0-2C8.73 2 5 4.46 5 9.15c0 3.12 2.33 6.41 7 9.85 4.67-3.44 7-6.73 7-9.85C19 4.46 15.27 2 12 2z"/><path d="M12 7c-1.1 0-2 .9-2 2s.9 2 2 2a2 2 0 100-4zM5 20h14v2H5v-2z"/></svg>
                      <label class="py-1"><!-- Address -->{{$t('sec12-title')}}</label>
                    </div>
                    <div class="w-full text-sm md:text-lg">
                      <p>
                        <!-- Japan India Industrial Promotion Organization, Shinbashi Daiichi Honkan Bldg 2FD, 6-9-2 Sinbashi, Minato-ku, Tokyo, 105-0004, Japan -->{{$t('sec12-col1-text1')}}
                      </p>
                    </div>
                    <div class="w-full pt-6">
                        <iframe class="rounded-md w-full" width="full" height="200" src="https://www.google.com/maps/embed?pb=!1m12!1m8!1m3!1d3241.62248898821!2d139.7537543!3d35.6616716!3m2!1i1024!2i768!4f13.1!2m1!1s6-ch%C5%8Dme-9-2%20Shinbashi%2C%20Minato%20City%2C%20Tokyo%2010%5B%E2%80%A6%5D8bc1a48cbec5%3A0xa03fb83c88d333b*218m2*213d35.6616673*214d139.755943!5e0!3m2!1sen!2snl!4v1651454001417!5m2!1sen!2snl" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                 </div>
                </div>
             </div>
             <div class="w-full lg:w-6/12 my-10 lg:my-0">
              <form  @submit.prevent="submitContact">
                <div class="border-white border rounded-xl p-3 mx-auto">
                  <div class="flex justify-center pb-3">
                    <div><label class="text-white font-bold uppercase text-2xl lg:text-3xl border-b-2 border-red-500 lg:px-3"><!-- Contact Us -->{{$t('sec12-col2-cu')}}</label></div>
                  </div>
                  <div class="space-y-4 my-3">
                    <div class="flex md:px-1 xl:px-4 space-x-4">
                      <div class="w-full">
                        <input v-model="contact.firstname" name="firstname" ref="firstname" class="placeholder-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 px-4 py-1.5 w-full shadow placeholder-black" type="text" :placeholder="$t('sec12-col2-text1')" @blur="clearValidation('firstname')" />
                        <!-- <span v-if="errors.firstname" class="pt-2 text-xs text-red-400">{{$t('msrs-para2')}}</span> -->
                        <span v-if="!errors.firstname" class="pt-2 text-xs text-red-400">{{$t('msrs-para2')}}</span>
                      </div>
                      <div class="w-full">
                        <input v-model="contact.lastname" class="placeholder-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 px-4 py-1.5 w-full shadow placeholder-black" type="text" :placeholder="$t('sec12-col2-text2')" @blur="clearValidation('lastname')" />
                        <!-- <span v-if="errors.lastname" class="pt-2 text-xs text-red-400">{{$t('msrs-para3')}}</span> -->
                        <span v-if="!errors.lastname" class="pt-2 text-xs text-red-400">{{$t('msrs-para3')}}</span>
                      </div>
                    </div>
                    
                    <div class="flex md:px-1 xl:px-4">
                      <div class="w-full">
                        <input v-model="contact.email" class="placeholder-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 px-4 py-1.5 w-full shadow placeholder-black" type="text" :placeholder="$t('sec12-col2-text3')" @blur="clearValidation('email')" />
                        <!-- <span v-if="errors.email" class="pt-2 text-xs text-red-400">{{$t('eau-para6')}}</span> -->
                        <span v-if="!errors.email" class="pt-2 text-xs text-red-400">{{$t('eau-para6')}}</span>
                      </div>
                    </div>
                    <div class="flex md:px-1 xl:px-4">
                      <div class="w-full flex gap-2">
                        <div class="relative inline-flex w-5/12">
                            <svg class="w-1.5 absolute top-0 right-0 m-3 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#1c80cf" fill-rule="nonzero"/></svg>
                            <select v-model="contact.phone_code" class="border border-gray-300 rounded-md pl-4 pr-8 py-1.5 font-semibold text-xs hover:border-indigo-500 focus:outline-none appearance-none bg-white w-full">
                                <option value="" hidden><!-- Select Code -->{{$t('select-code')}}</option>
                                <option v-for="item in getPhoneCode" :value="item.phonecode">
                                  {{ item.name }} ({{item.phonecode }})
                                </option>
                            </select>
                        </div>
                        <input v-model="contact.phone_number" class="placeholder-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 px-4 py-1.5 w-7/12 shadow placeholder-black" type="number" :placeholder="$t('sec12-col2-text4')" />
                      </div>
                    </div>
                    <div class="flex md:px-1 xl:px-4">
                      <div class="w-full">
                        <input v-model="contact.company_name" class="placeholder-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 px-4 py-1.5 w-full shadow placeholder-black" type="text" :placeholder="$t('em-tbl1-col1-row1')" />
                      </div>
                    </div>
                    <div class="flex md:px-1 xl:px-4">                     
                      <div class="w-full">
                        <input v-model="contact.comment" class="placeholder-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 px-4 py-1.5 w-full shadow placeholder-black" type="text" :placeholder="$t('sec12-col2-text6')" @blur="clearValidation('comment')" />
                        <!-- <span v-if="errors.comment" class="pt-2 text-xs text-red-400">{{$t('comment-field-required')}}</span> -->
                        <span v-if="!errors.comment" class="pt-2 text-xs text-red-400">{{$t('comment-field-required')}}</span>
                      </div>
                    </div>

                    <div class="flex justify-center pt-4">
                      <button type="submit" :disabled="isbeingCreating" :class="[isbeingCreating ? 'w-36 ursor-not-allowed border border-gray-300 bg-gray-200 p-2 flex justify-center item-center text-base rounded-lg font-semibold text-white mx-4' : 'w-36 bg-red-600 border border-red-700 py-2 p-2 flex justify-center item-center text-base rounded-lg font-semibold text-white mx-4']">
                        <svg v-if="isbeingCreating" class="animate-spin h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
                        <span v-show="!isbeingCreating"><!-- Submit -->{{$t('sec12-button')}}</span>                        

                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
export default {
    data: function() {
        return {
            isbeingCreating: false,            
            contact: {
                firstname: '',
                lastname: '',
                email: '',
                phone_code: '',
                phone_number: '',
                company_name: '',
                comment: '',
            },
            errors:{
				firstname: true,
				lastname : true,
				email : true,
				comment : true,
			},
        }
    },

    components: {
    },

    computed: {      
        ...mapGetters({           
            getPhoneCode:'contactUs/getPhoneCode',
        })
    },

    methods: { 
        ...mapActions({
            sendContactUs: 'contactUs/sendContactUs',       
             fetchPhoneCode:'contactUs/fetchPhoneCode',         
        }),

        fetchPhoneCodeData() {
            this.fetchPhoneCode()
        },

        submitContact(){
            try{
                if(this.validation()){
                    this.isbeingCreating = true;

                    this.sendContactUs({
                        payload: this.contact,
                        context: this,
                    }).then((success)=> {
                        if(success){
                            this.$store.commit('SET_ALERT', 'success',{ root: true });
                            this.$store.commit('SET_ALERT_MESSAGE', this.$t('record-added-successfully'),{ root: true });
                            this.isbeingCreating = false;
                            this.contact.firstname = '';
                            this.contact.lastname = '';
                            this.contact.email = '';
                            this.contact.phone_code = '';
                            this.contact.phone_number = '';
                            this.contact.company_name = '';
                            this.contact.comment = '';
                        }else{
                            this.isbeingCreating = false;
                        }
                    })
                }
            }catch($e){
				this.isbeingCreating = false;
				console.log($e);
			}
        },

        validation(){
			let isValidForm = true;

			if(!this.contact.firstname){
				isValidForm = false;
				this.errors.firstname = false;
                this.$refs.firstname.focus();
			}
			if(!this.contact.lastname){
				isValidForm = false;
				this.errors.lastname = false;
			}
			if(!this.contact.email){
				isValidForm = false;
				this.errors.email = false;
			}
			if(!this.contact.comment){
				isValidForm = false;
				this.errors.comment = false;
			}
			return isValidForm;
		},

        clearValidation(input){
			if(this.contact[input] !== ''){
				this.errors[input] = true;
			}
		},
    },

    mounted() {
        this.fetchPhoneCodeData();
    }
};
</script>

<style>
    .imageBox {
        position: relative;
        width: 50%;
    }

    .slidImage {
        opacity: 1;
        display: block;
        width: 100%;
        height: auto;
        transition: .5s ease;
        backface-visibility: hidden;
    }

    .middle {
        transition: .5s ease;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
    }

    .imageBox:hover .slidImage {
        opacity: 0.5;
    }

    .imageBox:hover .middle {
        opacity: 1;
    }
</style>
