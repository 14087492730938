export default {
    state:{
        showScoutMailModal:false,
        hideScoutMailModal:false,
        showDeleteScoutModal:false,
        hideDeleteScoutModal:false,
    },
    scout_inbox:{
        data:null,
        links:null,
        meta:null,  
    },
    scout_sendbox:{
        data:null,
        links:null,
        meta:null,
    },
    scout_mail:{
        detail:[],
        jobId:'',
        id:'',
        type:'',
        data: []
    }
}