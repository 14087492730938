export default [
    {
        path: "/scout-mail",
        name: "scout-mail",
        component: () => import('../components/ScoutMail.vue'),
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },  
    {
        path: '/view-scout-mail',
        component: () => import('../components/partials/ViewScoutMail.vue'),
        name: 'view-scout-mail',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
    {
        path: '/edit-scout-mail',
        component: () => import('../components/partials/EditScoutMail.vue'),
        name: 'edit-scout-mail',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
]