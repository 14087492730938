export const setshowJobsCategory = (state, data) => {
	return state.showJobsCategory  = data
}

export const setshowRefferalCategory = (state, data) => {
	return state.showRefferalCategory  = data
}

export const setshowJobFairCategory = (state, data) => {
	return state.showJobFairCategory  = data
}

export const setshowAdvertisementCategory = (state, data) => {
	return state.showAdvertisementCategory  = data
}

export const setshowAppliedJobs  = (state, data) => {
	return state.showAppliedJobs  = data
}

export const setshowMatchingJobs  = (state, data) => {
	return state.showMatchingJobs  = data
}

export const setshowSubscribeJobFairCategory  = (state, data) => {
	return state.showSubscribeJobFairCategory  = data
}

export const setDashboardAnalytics  = (state, data) => {
	return state.dashboardAnalytics  = data
}