<template>
  <div class="container mx-auto my-4 lg:my-20 bg-white w-screen lg:w-auto"> 
    <!-- <div class="bg-gray-700 pb-10 mr-5 h-auto"> -->
      <!-- <div class="">
        <ProfileJobCard/>
      </div> -->

      <div>
        <ProfileJobSidebar/>
      </div>
    <!-- </div> -->
  </div>
</template> 

<script>
// @ is an alias to /src
import ProfileJobCard from '../components/ProfileJobCard.vue'
import ProfileJobSidebar from '../components/ProfileJobSidebar.vue'


export default {
  name: "SideBar",
  components: {
    ProfileJobCard,
    ProfileJobSidebar
  },
  data() {
    return {
      openTab: 1
    }
  },

  methods: {
    toggleTabs: function(tabNumber){
      this.openTab = tabNumber
    }
  }
}

</script>