import { createApp } from 'vue'
import mitt from 'mitt';
const emitter = mitt();
import axios from 'axios'
import VueAxios from 'vue-axios'
import localforage from 'localforage'
import CKEditor from '@ckeditor/ckeditor5-vue';
import globalMixin from './globalMixin'
import PrimeVue from 'primevue/config'
// import Calendar from 'primevue/calendar'

import 'primevue/resources/themes/saga-blue/theme.css' //theme
import 'primevue/resources/primevue.min.css' //core css
import 'primeicons/primeicons.css' //icons

localforage.config({
    driver: localforage.LOCALSTORAGE,
    storeName: 'smartrecruit'
})

import gliderHelper from './plugins/js/glider-helper.js'
window.glider = gliderHelper

import App from './App.vue'
import router from './router'
import permissionDirective from './directives/permission-directive.js'
import store from './store/store.js'
import './index.css'
import './plugins/js/glider.min.js'
import './plugins/css/glider.min.css'
import i18n from './i18n'

import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';

// import 'alga-css/dist/alga.min.css'


window.axios = axios.create({
    baseURL: process.env.VUE_APP_SSO_API_URL || '',

   withCredentials: false,
    headers: {
        Accept: 'application/json'
    }
})

window.alertDuration = process.env.VUE_APP_ALERT_DURATION || 5000
// Feth CSRF token 
// store.dispatch('auth/getCsrfToken')
// Set Auth token
store.dispatch('auth/setToken').then(() => {
    store.dispatch('auth/fetchUser').then((response) => {
        //store.dispatch('auth/fetchUserMembershipPermission')
    }).catch(() => {
        store.dispatch('auth/clearAuth')
        router.replace({ name: 'sign-in'})
    })
}).catch(() => {
    store.dispatch('auth/clearAuth')
})

const app = createApp(App).use(PrimeVue).use(i18n).use(store).use(router).use(CKEditor).use(PrimeVue).use(Donut);
app.config.globalProperties.emitter = emitter;

app.mixin(globalMixin)

app.directive('has-permission', permissionDirective)

// const app = createApp(App);
//app.use(Vuex)
app.use(VueAxios, axios);
// app.component('multiselect', Multiselect)
//const instance = axios.create({
// baseURL: 'http://dev.smartrecruit.com/api/',
// });

//app.config.globalProperties.axios=instance;

app.config.globalProperties.productionTip = false;
app.config.globalProperties.token = "0Y3ieT8FPw8ppLgW3feuhMdUGfNkQg2yMJev6VuQ2F9y0SKQ5RGPAPxH3mpe";
app.config.globalProperties.baseURL = (app.config.productionTip) ? 'http://master.smartrecruit.com/api/' : 'http://dev.sm_recruit.com:8080/api/';

app.mount('#app');