<template>
<br><br><br><br><br> <h1> cities </h1> 
<div class="block">
  <span class="text-gray-700"> Add Column</span>
  <div class="mt-2">
    <div>
      <label class="inline-flex items-center">
        <input type="checkbox" id="status" name="status" value="status" v-model="checkedColumn">
        <span class="ml-2">Status</span>
      </label>
    </div>
    <div>
      <label class="inline-flex items-center">
        <input type="checkbox" id="city_short_name" name="city_short_name" value="city_short_name" v-model="checkedColumn">
        <span class="ml-2"> city short name </span>
      </label>
    </div>
    <div>
      <label class="inline-flex items-center">
        <input type="checkbox" id="created_on" name="created_on" value="created_on" v-model="checkedColumn">
        <span class="ml-2">created on</span>
      </label>
    </div>
  </div>
  <input type="button" @click='dynamicColumn' name="submit" value="submit">
</div>
  <div class="data-table">
    <div class="main-table">
      <table class="table-auto">
        <thead>
        <tr>
          <th v-for="(column,index) in columns"  @click.self="sortByColumn(fieldsName[index])" class="w-2/12 text-left text-sm relative border border-gray-300 text-center"
            >
            {{ column  }} <div class="w-full h-4 px-1 mt-1 mb-2">
              <input type="text" v-bind:id="fieldsName[index]" class=" w-full h-6 px-1 mt-1 mb-2 border-solid border-4 border-light-blue-500" v-on:keyup.enter="onEnter(index,$event)"/></div>
            </th>

            <!--<span v-if="column === sortedColumn">
                            <i v-if="order === 'asc' " class="fas fa-arrow-up"></i>
                            <i v-else class="fas fa-arrow-down"></i>
            </span>
          </th>-->
        </tr>
        </thead>
        <tbody>
        <tr class="" v-if="tableData.length === 0">
          <td class="lead text-center" :colspan="columns.length + 1">No data found.</td>
        </tr>
        <tr v-for="data in tableData">
           <td v-for="key in fieldsName">  {{data[key]}} </td>
        </tr>
        </tbody>
      </table> 
    </div>
    <nav v-if="tableData.length > 0">
      <ul class="pagination">
        <li class="page-item" :class="{'disabled' : currentPage === 1}">
          <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">Previous</a>
        </li>
        <li v-for="page in pagesNumber" class="page-item"
            :class="{'active': page == currentPage}">
          <a href="javascript:void(0)" @click.prevent="changePage(page)" class="page-link">{{ page }}</a>
        </li>
        <li class="page-item" :class="{'disabled': currentPage === lastPage }">
          <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">Next</a>
        </li>
        <span style="margin-top: 8px;"> &nbsp; <i> Showing {{ from }} to {{ to }} of {{ total }} entries.</i></span>
      </ul>
    </nav> 
    checkedColumn {{ checkedColumn }} 
    fields name {{fieldsName[0]}}
  </div>
</template>

<script>
export default {
  name: 'DataTable',

  data() {
    return {
      tableData: [],
      checkedColumn:[],
      columns:['Sr. No.','City Name', 'State id'], 
      offset: '2',
      currentPage: '1',
      perPage: '10',
      sortedColumn: "city_name",
      order: 'asc',
      to:'',
      from:'',
      total:'',
      lastPage:'',
      fieldsName:[],
      searchField:{},
      searchValue:'',
      field:'',
    }
  },
  computed: {
    /**
     * Get the pages number array for displaying in the pagination.
     * */
    pagesNumber() {
      if (!this.to) {
        return []
      }
      let from = this.currentPage - this.offset
      if (from < 1) {
        from = 1
      }
      let to = from + (this.offset * 2)
      if (to >= this.lastPage) {
        to = this.lastPage
      }
      let pagesArray = []
      for (let page = from; page <= to; page++) {
        pagesArray.push(page)
      }
      return pagesArray
    },
  },
    beforeMount(){
        this.fetchData();
        console.warn('mounted');
    },
  methods: {
    fetchData() {
        this.axios
            .get(this.baseURL + "cities", {
                headers: {
                    Authorization: "Bearer " + this.token,
                },params: {
                        sort_field: this.sortedColumn,
                        sort_order: this.order,
                        search_value: this.searchValue,
                        search_field: this.searchField,
                        per_page: this.perPage,
                        page: this.currentPage,
                        checked_column: this.checkedColumn,
                    },
                })
                .then((response) => {
                    this.tableData = response.data.data.data; 
                    this.to = response.data.data.to;   
                    this.from = response.data.data.from;   
                    this.total = response.data.data.total;  
                    this.currentPage = response.data.data.current_page;  
                    this.lastPage = response.data.data.last_page;
                    this.columns = response.data.header;
                    this.fieldsName = response.data.fieldsName;
                })
                .catch((err) => {});
                    console.warn('error');
                    //this.apiLoaded = true;
        
    },
    
    /**
     * Change the page.
     * @param pageNumber
     */
    changePage(pageNumber) {
      this.currentPage = pageNumber
      this.fetchData()
    },
    /**
     * Sort the data by column.
     * */
    sortByColumn(column) {
      if (column === this.sortedColumn) {
        this.order = (this.order === 'asc') ? 'desc' : 'asc'
      } else {
        this.sortedColumn = column
        this.order = 'asc'
      }
      this.fetchData()
    },
    onEnter(index,e){
      this.field = this.fieldsName[index];
      this.searchField[this.field] = e.target.value;
      this.fetchData()
    },
    dynamicColumn() {
       this.fetchData()
    },
  },
  filters: {
    columnHead(value) {
      return value.split('_').join(' ').toUpperCase()
    }
  },
  
}
</script>
