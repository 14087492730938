export const addProject = ({ commit }, { payload, context }) => {
  return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/project-details', payload, {
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
    .then((response) => {
      commit("SET_ALERT", "success", { root: true });
      commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      return response.data.success;
    }).catch((error) => {
        // commit('SET_ALERT', 'warning', { root: true })
        // commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}

export const strengthData = ({ commit }) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/strength").then((response) => {
        return response.data.data;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return false;
    });
};

export const keySkillsData = ({ commit}) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/key-skills").then((response) => {
        return response.data.data;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return false;
    });
};

export const functionAreaData = ({ commit }) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/function-area").then((response) => {
        return response.data.data;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return false;
    });
};

export const postStrength = ({ commit, dispatch }, { payload, context }) => {
  return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/post-strength", payload).then((response) => {
      // commit('SET_ALERT', 'success', { root: true })
      // commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
      return response.data.success
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
      context.errors = error.response.data
      return false
  })
}

export const postKeySkills = ({ commit, dispatch }, { payload, context }) => {
  return window.axios.post (process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/post-key-skills", payload).then((response) => {
      // commit('SET_ALERT', 'success', { root: true })
      // commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
      return response.data.success
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
      context.errors = error.response.data
      return false
  })
}

export const postFunctionArea = ({ commit, dispatch }, { payload, context }) => {
  return window.axios.post (process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/post-function-area", payload).then((response) => {
      // commit('SET_ALERT', 'success', { root: true })
      // commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
      return response.data.success
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
      context.errors = error.response.data
      return false
  })
}

export const languageData = ({ commit, context }) => {
  return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/managejob/language").then((response) => {
      commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      return response.data.data;
  }).catch((error) => {
      context.errors = error.response.data.errors;
      return false;
  });
};

export const currencyData = ({ commit, context }) => {
  return window.axios.get( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/managejob/currency").then((response) => {
  commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
  return response.data.data;
  }).catch((error) => {
    context.errors = error.response.data.errors;
    return false;
  });
};

export const fetchProjectList = ({ commit, dispatch }, query = '') => {
    // return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/project-list' + query).then((response) => {  
    //     // commit('setAuthenticated', true);
    //     commit('setProjectList', response.data);   
    //     return response

    // })
}

export const getProjectById = ({commit}, id) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + `api/projectbyid/${id}`).then((response) => { 
    commit('setProjectId', response.data.data);           
        return response.data.data
    })
}

