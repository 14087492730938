export const set_scout_inbox_data = (state, data) => {
    state.scout_inbox.data =  data.data.data.data
    state.scout_inbox.links = data.data.data.links
    state.scout_inbox.meta = {
        current_page: data.data.data.current_page,
        from: data.data.data.from,
        to:  data.data.data.to,
        total: data.data.data.total,
        perPage: data.data.data.per_page
    }
}
export const set_scout_sendbox_data = (state, data) => {
    state.scout_sendbox.data =  data.data.data.data
    state.scout_sendbox.links = data.data.data.links
    state.scout_sendbox.meta = {
        current_page: data.data.data.current_page,
        from: data.data.data.from,
        to:  data.data.data.to,
        total: data.data.data.total,
        perPage: data.data.data.per_page
    }
}

export const showScoutMailModal = (state, flag) => {
    state.showScoutMailModal = flag
}

export const hideScoutMailModal = (state) => {
    state.showScoutMailModal = false
}

export const setScoutJobDetail = (state, data) => {
    state.scout_mail.jobId =  data;
}

export const setScoutUserDetail = (state, data) => {
    state.scout_mail.detail =  data;
}

export const hideDeleteScoutModal = (state) => {
    state.showDeleteScoutModal = false
}

export const showDeleteScoutModal = (state, flag) => {
    state.showDeleteScoutModal = flag
}

export const setId = (state, {id, type}) => {
    state.scout_mail.id =  id;
    state.scout_mail.type =  type;
}


export const setScoutDataById = (state, data) => {
    state.scout_mail.data = data.data.scoutInfo;
};