<template>
    <div>
        <div class=" flex justify-center items-center outline-none focus:outline-none py-28">
            <div class="w-full  max-w-lg py-4  mx-auto my-auto rounded-xl shadow-lg  bg-white ">
                     <!--content-->
                <div class="">
                    <div class="text-center pb-5 flex-auto justify-center">
                        <h2 class="text-2xl font-bold text-red-500 py-4 ">{{$t('cp-col2-title')}}<!-- Change Password --></h2>
                        <p class="text-sm text-gray-700 px-8 font-semibold mx-9 ">{{$t('pcs-title')}}<!-- Password change successfully --></p>
                    </div>
               
                    <div class=" flex flex-col justify-center items-center   text-center space-y-6 pb-5 mx-10">
                                <!--==== Current Password ====-->
                        <input type="text" name="" :placeholder="$t('cp-col2-text1')" class="mb-2 md:mb-0 bg-white px-6 py-2 text-sm md:text-base filter drop-shadow-md tracking-tight border text-gray-600 rounded  hover:bg-gray-50 w-full  block placeholder-gray-600 outline-none">
                                <!--===== New Password =======-->
                        <input type="text" name="" :placeholder="$t('ccp-col2-text2')" class="mb-2 md:mb-0 bg-white px-6 py-2 text-sm md:text-base filter drop-shadow-md tracking-tight border text-gray-600 rounded  hover:bg-gray-50 w-full  block placeholder-gray-600 outline-none">
                                 <!--==== Coinfirm New Password ====-->
                        <input type="text" name="" :placeholder="$t('cp-col2-text3')" class="mb-2 md:mb-0 bg-white px-6 py-2 text-sm md:text-base filter drop-shadow-md tracking-tight border text-gray-600 rounded  hover:bg-gray-50 w-full  block placeholder-gray-600 outline-none ">         
                                    <!--=== Submit ===-->
                        <button class="mb-2 md:mb-0 bg-red-500 border border-red-500 px-1 py-2 text-base filter 2xl:drop-shadow-lg tracking-wider text-white rounded-lg shadow-lg hover:bg-red-600 w-32 block ">{{$t('sec12-button')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
 
  data: function() {
    return {
     
    }
  },

  components: {

  }
};
</script>


