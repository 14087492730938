<template>
  <div>
    <div class="container mx-auto mx-6">
      <div class="flex justify-center my-6">
        <label class="text-4xl font-bold box-border border-b-2 border-red-500 w-52 py-2 ">MY PROFILE</label>
      </div>
      <div class="mx-6 flex justify-center items-center lg:flex ">
        <div class="hidden lg:block w-9/12 lg:w-4/12">
          <ProfileSidebar/> 
        </div>
        <div class=" lg:w-8/12">
          <ProfileCointainer/> 
        </div>
      </div>
    </div>   
  </div>
</template>

<script>
// @ is an alias to /src
import ProfileSidebar from '../components/ProfileSidebar.vue'
import ProfileCointainer from '../components/ProfileCointainer.vue'

export default {
   data: function() {
      return {
      
    }
  },

  name: 'Profile',
  components: {
        ProfileSidebar,
        ProfileCointainer,
  },
  methods: {
      
    }, 
};
</script>

<style>

html {
  scroll-behavior: smooth;
}

</style>
