export default [    
    {
        path: '/add-new-language',
        name: 'AddNewLanguage',
        component: () => import ('../components/AddNewLanguage.vue'),
        meta: {
            guest: false,
            needsAuth: true
        }
    },
]