export const SET_AGREEMENTS_DATA = (state, data) => {
    state.agreemntList.data = data.data.data;
    state.agreemntList.links = data.data.links;
    state.agreemntList.meta = {
		current_page: data.data.current_page,
		from: data.data.from,
		to:  data.data.to,
		total: data.data.total,
		perPage: data.data.per_page
	}
}
export const setPaymentStatus = (state, data) => {
    state.paymentStatus = data;
}
export const setSettingsInclusion =(state, data) =>{
	state.inclusionsSettings = data;
}
export const setPlannerData =(state, data) =>{
	state.smartPlannerData = data;
}
export const setJiipaData =(state, data) =>{
	state.jiipaData = data;
}
export const setEmployerData =(state, data) =>{
	state.employerData = data;
}