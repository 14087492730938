
import { Login, Register, UserTypeSylector, ForgotPassword, ResetPassword, ChangePassword} from '../components'


export default [
	{
		path: '/sign-in',
		component: () => Login,
		name: 'sign-in',
		meta: {
			guest: true,
			needsAuth: false
		}
	},
	{
		path: '/sign-up',
		component: () => Register, 
		name: 'register',
		meta: {
			guest: true,
			needsAuth: false
		}
	},
	{
		path: '/select-role',
		component: () => UserTypeSylector, 
		name: 'select-role',
		meta: {
			guest: true,
			needsAuth: false
		}
	},
	{
		path: '/forgot-password',
		component: () => ForgotPassword, 
		name: 'forgot-password',
		meta: {
			guest: true,
			needsAuth: false
		}
	},
	{
		path: '/reset-password',
		component: () => ResetPassword, 
		name: 'reset-password',
		meta: {
			guest: true,
			needsAuth: false
		}
	},

	{
		path: '/change-password',
		component: () => ChangePassword, 
		name: 'change-password',
		meta: {
			guest: false,
			needsAuth: true
		}
	},

]