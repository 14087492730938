export default {
   	showViewPaymentModel: false,

   	paymentHistory: {
   		data: [],
   		links: [],
   		paymentHistoryId: null, 
   	},
}

