<template>
  <div class=" flex justify-between lg:flex p-10 lg:p-1 mb-5">  
    <div class="lg:block lg:w-4/12 mt-5   mr-5 text-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="border rounded-full h-28 p-2 mb-2" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
        </svg>
        <!-- <span> Add your photo</span> -->
    </div>
    <div class="w-7/12">
      <div class="lg:flex">
        <span class="font-bold text-sm">Lorem Ipsum </span>
      </div>
      <div class="text-base lg:text-sm">
        <span class="font-semibold ">First name: </span>Lourm Ipsum
      </div>
      <div class="text-base lg:text-sm">
        <span class="font-semibold">Last name: </span>Lorum Ipsum
      </div>
      <div class="text-base lg:text-sm">
        <span class="font-semibold">Email Id: </span>abc@gmail.com
      </div>
      <div class="text-base lg:text-sm">
        <span class="font-semibold">Phone No:</span>123456789
      </div>
      <div class="text-base lg:text-sm">
        <span class="font-semibold">Indusrty Type:</span>Lorum Ipsum
      </div>
      <div class="text-base lg:text-sm">
        <span class="font-semibold">NDA Doc File </span>
      </div>
    </div>

    <div class="flex-none Lg:flex lg:w-1/12">
      <span class="text-right">
        <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="432.544px" height="432.544px" viewBox="0 0 432.544 432.544" style="enable-background:new 0 0 432.544 432.544;" xml:space="preserve">
          <g>
            <g>
              <path d="M0,313.775v118.77h118.771l237.541-237.541L237.539,76.232L0,313.775z M103.638,395.999L103.638,395.999l-30.55,0.004    v-36.546H36.545v-30.553l25.981-25.981l67.093,67.092L103.638,395.999z M246.683,124.77c4.182,0,6.276,2.095,6.276,6.28    c0,1.906-0.664,3.521-1.999,4.856L96.214,290.651c-1.333,1.328-2.952,1.995-4.854,1.995c-4.184,0-6.279-2.098-6.279-6.279    c0-1.906,0.666-3.521,1.997-4.856l154.747-154.743C243.154,125.436,244.773,124.77,246.683,124.77z"/>
              <path d="M421.976,77.654l-67.091-66.806C347.653,3.619,338.992,0,328.903,0c-10.283,0-18.842,3.619-25.693,10.848l-47.394,47.109    l118.773,118.77l47.394-47.392c7.042-7.043,10.561-15.608,10.561-25.697C432.54,93.743,429.022,85.08,421.976,77.654z"/>
            </g>
          </g>
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
   name: "ToggleDiv",
   el:'#showModal',
  data: function() {
    return {
      roleModelBox : false,
      isShow: false,
      show: false,
    }
  },
  components: {

  }
};
</script>


