export const fetchPersonal = ({ commit }, { context, payload }) => {
  var profile_id = "";
  if (payload.profile) {
    profile_id = "?personal_profile_id=" + payload.profile + "&employerView=" + payload.employerView;
  } else if (localStorage.getItem("setPersonalProfileId")) {
    profile_id = "?personal_profile_id=" + localStorage.getItem("setPersonalProfileId");
  }

  var language = payload && payload.language ? "?language=" + payload.language : "";
  return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/get-js-profile-details" + profile_id + language)
    .then((response) => {
      commit("setJobseekerData", response.data);
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};

export const ssoUserDataPost = ({ commit }, { context, payload }) => {
  return window.axios.post(process.env.VUE_APP_SSO_API_URL + "api/sso-user/update-details/" + payload.email,payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      context.isbeingSaved = false;
      context.isbeingNext = false;
      context.isbeingSubmit = false;
      return false;
    });
};
export const restJobseekerPersonalData = ({ commit }, { payload, context }) => {
  return window.axios
    .post(
      process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/js-profile-personal",
      payload
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      context.isbeingSaved = false;
      context.isbeingNext = false;
      context.isbeingSubmit = false;
      return false;
    });
};
export const uploadProfile = ({ commit }, { payload, context }) => {
  return window.axios
    .post(
      process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/js-profile-image-upload",
      payload
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", error.response.data.message, { root: true });
      context.isbeingSaved = false;
      context.isbeingNext = false;
      context.isbeingSubmit = false;
      return false;
    });
};
export const userRemoveProfile = ({ commit }, payload) => {
  return window.axios
    .post(
      process.env.VUE_APP_SMART_RECRUIT_API_URL +
        "api/js-profile-image-remove?profile_id=" +
        payload
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};
export const fetchLanguageList = ({ commit }) => {
  return window.axios
    .get(
      process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/languages-list-profile"
    )
    .then((response) => {
      return response.data.data;
    });
};
export const fetchExamDegreeList = ({ commit }, payload) => {
  return window.axios
    .get(
      process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/master-degree-exam-list"
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};
export const fetchCourseMinorList = ({ commit }, payload) => {
  return window.axios
    .get(
      process.env.VUE_APP_SMART_RECRUIT_API_URL +
        "api/master-course-minor-list/" +
        payload
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};
export const fetchUniversityCollegeList = ({ commit }, payload) => {
  return window.axios
    .get(
      process.env.VUE_APP_SMART_RECRUIT_API_URL +
        "api/master-university-college-list/" +
        payload
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};
export const saveEductionData = ({ commit }, { payload, context }) => {
  return window.axios
    .post(
      process.env.VUE_APP_SMART_RECRUIT_API_URL +
        "api/js-profile-education-college-journal-publication",
      payload
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", error.response.data.message, { root: true });
      context.isbeingSaved = false;
      context.isbeingNext = false;
      context.isbeingSubmit = false;
      return false;
    });
};
export const positionList = ({ commit }, payload) => {
  return window.axios
    .get(
      process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/master-positionname-list"
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};
export const strengthList = ({ commit }, payload) => {
  return window.axios
    .get(
      process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/master-strengths-list"
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};
export const keySkillList = ({ commit }, payload) => {
  return window.axios
    .get(
      process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/master-keyskills-list"
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};
export const functionAreaList = ({ commit }, payload) => {
  return window.axios
    .get(
      process.env.VUE_APP_SMART_RECRUIT_API_URL +
        "api/master-function-area-list"
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};
export const submitEmployment = ({ commit }, { payload, context }) => {
  return window.axios
    .post(
      process.env.VUE_APP_SMART_RECRUIT_API_URL +
        "api/js-profile-employment-employer-details",
      payload
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      context.isbeingSaved = false;
      context.isbeingNext = false;
      context.isbeingSubmit = false;
      return false;
    });
};
export const getJobTitle = ({ commit }, payload) => {
  return window.axios
    .get(process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/master-jobtitle-list")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};
export const getWorkStyle = ({ commit }, payload) => {
  return window.axios
    .get(
      process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/master-workstyle-list"
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};
export const employerTypeList = ({ commit }, payload) => {
  return window.axios
    .get(
      process.env.VUE_APP_SMART_RECRUIT_API_URL +
        "api/master-employer-type-list"
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};
export const industryList = ({ commit }, payload) => {
  return window.axios
    .get(process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/master-industry-list")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};
export const industrySubList = ({ commit }, payload) => {
  return window.axios
    .get(
      process.env.VUE_APP_SMART_RECRUIT_API_URL +
        "api/master-industry-sub-list/" +
        payload
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};
export const sendingOrganisationList = ({ commit, dispatch }) => {
  return window.axios
    .get(
      process.env.VUE_APP_SMART_RECRUIT_API_URL +
        "api/user-list?sso_user_role=titp-sending-org&is_disable=yes&perPage=500"
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return false;
    });
};
export const titpJobCategory = ({ commit }, payload) => {
  return window.axios
    .get(
      process.env.VUE_APP_SMART_RECRUIT_API_URL +
        "api/titp-document-parent-category-list"
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};
export const titpJobSubTypeData = ({ commit }, payload) => {
  return window.axios
    .get(
      process.env.VUE_APP_SMART_RECRUIT_API_URL +
        "api/titp-document-child-category-list/" +
        payload
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};
export const titpOperationsData = ({ commit }, payload) => {
  return window.axios
    .get(
      process.env.VUE_APP_SMART_RECRUIT_API_URL +
        "api/titp-document-operations-list/" +
        payload
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};
export const titpMandatoryDocuemntListByCategory = ({ commit }, payload) => {
  return window.axios
    .get(
      process.env.VUE_APP_SMART_RECRUIT_API_URL +
        "api/titp-mandatory-documents-by-category/" +
        payload
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};
export const languageLevelList = ({ commit }, payload) => {
  return window.axios
    .get(
      process.env.VUE_APP_SMART_RECRUIT_API_URL +
        "api/master-language-level-list"
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};
export const saveJobCriteria = ({ commit }, { payload, context }) => {
  return window.axios
    .post(
      process.env.VUE_APP_SMART_RECRUIT_API_URL +
        "api/js-profile-matching-job-criteria",
      payload
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      context.isbeingSaved = false;
      context.isbeingNext = false;
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};
export const deleteEductionData = ({ commit }, { payload, context }) => {
  return window.axios
    .delete(
      process.env.VUE_APP_SMART_RECRUIT_API_URL +
        "api/js-profile-education-college-remove/" +
        payload
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      context.isDisable = false;
      context.btnDelete = context.lable;
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });
};
