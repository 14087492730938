export const updateUserByAuth = ({ commit}, { payload, context}) => {
    return window.axios({
        method: 'POST',
        url: process.env.VUE_APP_SSO_API_URL + `api/sso-user/update-details/`+payload.email,
        data: payload,
        }).then((response) => {
        
        return response.data;
        }).catch((error) => {
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
            context.errors = error.response.data;
            context.isbeingUpdating = false;
            return false
        })
};
export const updateRestUserByAuth = ({ commit}, { payload, context}) => {
    return window.axios({
        method: 'POST',
        url: process.env.VUE_APP_SMART_RECRUIT_API_URL + `api/update-profile-details`,
        data: payload,
    }).then((response) => {
        // Showing  success message
        // commit('SET_ALERT', 'success', { root: true })
        // commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        
        return response.data.success
    }).catch((error) => {
        context.isbeingUpdating = false;
        context.errors = error.response.data
        return false
    })
};
export const removeProfileImage = ({commit}, {payload,context}) => {
    return window.axios({
        method: 'DELETE',
        url: process.env.VUE_APP_SMART_RECRUIT_API_URL + `api/remove-profile-pic`,
        data: payload,
    }).then((response) => {
        // Showing  success message
        // commit('SET_ALERT', 'success', { root: true })
        // commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.isbeingDeleted = false;
        return false
    })
};
export const updateAuthUserProfilePic = ({ commit}, { payload, context}) => {
    return window.axios({
            method: 'POST',
            url: process.env.VUE_APP_SMART_RECRUIT_API_URL + `api/update-profile-pic`,
            data: payload,
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
            context.errors = error.response.data
            return false
        })
};
export const changePassword = ({commit}, {payload,context}) => {
    return window.axios({
        method: 'POST',
        url: process.env.VUE_APP_SSO_API_URL + `api/change-password`,
        data: payload,
    }).then((response) => {
    // Showing  success message
        // commit('SET_ALERT', 'success', { root: true })
        // commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        
        return response.data;
    }).catch((error) => {
        if(error.response.status == 400){
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true })
            context.errors.isOldPasswordMatch = false;
        }
        return false;
    })
};
export const removeCompanyLogoImage = ({commit}, {payload,context}) => {
    return window.axios({
        method: 'DELETE',
        url: process.env.VUE_APP_SMART_RECRUIT_API_URL + `api/remove-company-logo`,
        data: payload,
    }).then((response) => {
    // Showing  success message
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.isbeingLogoDeleted = false;
        return false
    })
};
export const restChangePassword = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/must-change-password" , payload).then((response) => {
       // Showing  success message
       commit('SET_ALERT', 'success', { root: true })
       commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
       
       return response.data.success
   }).catch((error) => {
       commit('SET_ALERT', 'warning', { root: true })
       commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
       context.errors = error.response.data.errors
       return false
   })
}