
export const postRelocation = ({ commit }, {context, payload}) => {
  return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/relocation-service', payload).then((response) => {
        // commit('SET_ALERT', 'success', { root: true })
        // commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
        return response.data;
      }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        context.errors = error.response.data
        return false;
      })  
  };

  export const nationalityData = ({ commit, context }) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/nationality').then((response) => {
    // commit("setAuthenticated", true);
    commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
    return response.data.data;
    }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      context.errors = error.response.data.errors;
      return false;
    });
  };

  export const fetchRelocationServiceAnalytics = ({commit, dispatch}) => {
    let url = process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/relocation-service-analytics';
    return window.axios.get(url).then((response) => {
        commit('setRelocationServiceAnalytics', response.data);
        return true;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    })
};
export const verifyReCapcha = ({ commit }, {context, payload}) => {
  return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/recapcha-verification', payload).then((response) => {
      return response.data; 
    }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    })  
};