export default [
	{
        path: '/dashboard',
        component: () => import('../components/Dashboard.vue'),
        name: 'Dashboard',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },

    {
        path: '/dashboard-home',
        component: () => import('../components/DashboardHome.vue'),
        name: 'DashboardHome',
        meta: { 
            guest: true,
            needsAuth: false,        
        }
    },
    
]