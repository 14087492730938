export default [
	{
        path: '/candidates',
        component: () => import('../components/MyCandidates.vue'),
        name: 'candidates',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
    
]