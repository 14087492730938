export const set_blocklist_data = (state, data) => {
    state.blocklist.data =  data.data.data.data
    state.blocklist.links = data.data.data
}

export const showBlockModal = (state, flag) => {
    state.showBlockModal = flag
}

export const hideUnblockModal = (state) => {
    state.showUnblockModal = false
}

export const showUnblockModal = (state, flag) => {
    state.showUnblockModal = flag
}

export const setId = (state, {id, name}) => {
    state.blocklist.id =  id;
    state.blocklist.name =  name;
}

export const setBlockUnblock = (state, block) => {
    state.blocklist.block =  block;

}