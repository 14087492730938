export default [
	{
        path: '/projects',
        component: () => import('../components/ProjectList.vue'),
        name: 'projects',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
    {
        path: '/view-project/:id',
        component: () => import('../components/ViewProject.vue'),
        name: 'view-project',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
     {
        path: '/edit-project/:id',
        component: () => import('../components/EditProject.vue'),
        name: 'edit-project',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
    {
        path: '/view-project-application',
        component: () => import('../components/ViewProjectApplication.vue'),
        name: 'view-project-application',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
    {
        path: '/list-application-recived',
        component: () => import('../components/ListApplicationRecived.vue'),
        name: 'list-application-recived',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
    {
        path: '/list-matching-companies',
        component: () => import('../components/ListMatchingCompanies.vue'),
        name: 'list-matching-companies',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
    {
        path: '/add-new-project',
        component: () => import('../components/AddNewProject.vue'),
        name: 'add-new-project',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
    
     
   
]