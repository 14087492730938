export const getJobseekerData = (state) => {
    return state.jobseeker;
};

export const getCommonJobTypeData = (state) => {
    return state.commonJobTypes;
};

export const getCommonExperienceLevelData = (state) => {
    return state.commonExperienceLevel;
};

export const getCommonJobStatusData = (state) => {
    return state.commonJobStatus;
};