export default [
	{
        path: '/advertisements-list',
        component: () => import('../components/AdvertisementList.vue'),
        name: 'advertisements-list',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
    {
        path: '/add-advertisement',
        component: () => import('../components/partials/AddNewAdvertisement.vue'),
        name: 'add-advertisement',
        // meta: { 
        //     guest: false,
        //     needsAuth: true,        
        // }
    },
    //  {
    //     path: '/view-advertisement',
    //     component: () => import('../components/partials/ViewAdvertisement.vue'),
    //     name: 'view-advertisement',
    //     meta: { 
    //         guest: false,
    //         needsAuth: true,        
    //     }
    // },
    //  {
    //     path: '/edit-advertisement',
    //     component: () => import('../components/partials/EditAdvertisement.vue'),
    //     name: 'edit-advertisement',
    //     meta: { 
    //         guest: false,
    //         needsAuth: true,        
    //     }
    // },
   
]