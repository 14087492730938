export const fetchBlockList = ({ commit } , query = '') => {
     return window.axios
       .get(process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/block-lists" + query)
       .then((response) => {
         commit("set_blocklist_data", response)
         return response.data.data.data;
       })
       .catch((error) => {
         commit("SET_ALERT", "warning", { root: true });
         commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
         return false;
    });
};

export const block = ({ commit}, { payload }) => {
  return window.axios.post('/api/block-multiple/', payload).then((response) => {
    // commit('SET_ALERT', 'success', { root: true })
    // commit("SET_ALERT_MESSAGE", response.data.message, {root: true});
    return response.data;
  }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
  });   
};

export const unblock = ({ commit}, { payload }) => {
  return window.axios.post('/api/unblock/', payload).then((response) => {
    // commit('SET_ALERT', 'success', { root: true })
    // commit("SET_ALERT_MESSAGE", response.data.message, {root: true});
    return response.data;
  }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
  });   
};