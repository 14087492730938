export default {
	
	employers: [
		{
			company_name: null,
			id: null	
		}
	],
	hiring_source_titp: null,
	hiring_source_nontitp: null,
	has_applied: false,
	showApplyBox: false,
	forEmployer: false,
	jobSeekerId: null,
	interviewMessage: "",
	visaInterviewMessage: "",
	showRejectModel: false,
	jobList: {
		data: null,
		links: null,
		meta: null
	},
	appliedJobs: {
		data: null,
		links: null,
		meta: null
	},
	applicants: [],
	matching_applicants: [],
	user: {
		token: null,
		authenticated: false,
		data: [],
		roleName: null,
		roleSlug: null,
		roleId:null,
	},
	jobDetails: null,
	// jobDetails: {
	// 	hiring_source_logo: "",
	// 	hiring_source_name: "",
	// 	hiring_company_logo: "",
	// 	company_details: {},
	// 	creator_details: {},
	// 	job_details: {},
	// 	application_details: {
	// 		status: "",
	// 		documents: [],
	// 		statuses: [],
	// 		interviews: [],
	// 		job_offers: [],
	// 		visa_interviews: []
	// 	},
	// },
	jobs:{
		jobId:[],
		
	  },

	showTab: true,
	myResume: {
		addressDetails: {},
		criteriaDetails: [],
		details: {},
		documentDetails: [],
		educationDetails: [],
		employmentDetails: [],
		journalDetails: [],
	},
	jobseekerResume: {
		addressDetails: {},
		details: {},
		educationDetails: [],
		employmentDetails: [],
		journalDetails: [],
	},
	jobApplication: {
		company_details: {
			industry_details: [],
			sub_industry_details: [],
		},
		creator_details: {},
		job_details: {
			salary_currency: [],
			job_type: [],
			work_style: [],
			visa: [],
			function_areas: [],
			docs: [],
		},
		application_details: {
			status: "",
			documents: [],
			statuses: [],
			interviews: [],
			job_offers: [],
			visa_interviews: [],
			statuses: [],
		}
	},

	ProfileList: {
		data:[]
	},
	languageList: {
		data:[]
	},
	resumeDetails: {
		resume_name:"", 
		profile_type:"",
		resume_language:"",
		},

   	showDeleteJobseekerProfile: false,	
	hideDeleteJobseekerProfile: false,
	profileIdDeleted: null,
	profileIdLanguage: null,


	showMakeMasterProfile: false,
	hideMakeMasterProfile: false,
	profileIdMaster: null,
	
	showDeactiveProfile: false,
	hideDeactiveProfile: false,
	activeDeactive: null,

	showActivateWarning: false,
	hideActivateWarning: false,
	setJobDropdownList:[],
	setChatbotData:[],
	// showAddNewLanguageOther: false,
}

