export const searchJobs = ({ commit, state }, {query, query1}) => {
    /*let timezone = state.client.timezone;
    let new_query
    if(query1){
        query1 = query1.replace('?', '&')
        new_query = query + query1;
    }else{
        new_query = query;
    }
    
    return window.axios
        .get(process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/simple/search" + new_query, { headers: { "x-timezone" : timezone} 
    })
        .then((response) => {
            commit("SET_JOBS", response.data)
            return response.data;
        })
        .catch((error) => {
            commit("SET_ALERT", "warning", { root: true });
            commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
            return false;
        });*/
  };

  export const fetchEmployerById = ({ commit}, id) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/view-employer-profile/'+id).then((response) => {
        commit('setEmpById', response.data);
        return response.data;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return false;
    });   
  };

  export const fetchEmployerJob = ({ commit }, { payload, context }) => {
    return window.axios
        .get(process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/jobs-list/" + payload.id+'?page='+payload.page)
        .then((response) => {
            commit("SET_EMP_JOBS", response.data)
            return response.data;
        })
        .catch((error) => {
            commit("SET_ALERT", "warning", { root: true });
            commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
            return false;
        });
  };