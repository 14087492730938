export const JobCategoryList = ({ commit }) => {

    // return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/employer-job').then((response) => {
    //     commit('setshowJobsCategory', response.data.data);
    //     return response.data.data
    // })
}

export const JobRefferalList = ({ commit }) => {
    // return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/list-refferal').then((response) => {
    //     commit('setshowRefferalCategory', response.data.data.data);
    //     return response.data.data.data
    // })
}

export const JobFairList = ({ commit }) => {

    // return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/job-fair/list/organiser').then((response) => {
    //     commit('setshowJobFairCategory', response.data.data.data);
    //     return response.data.data.data
    // })
}

export const AdvertisementList = ({ commit }) => {

    // return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/my-advertisements').then((response) => {
    //     commit('setshowAdvertisementCategory', response.data.data.data);
    //     return response.data.data.data
        
    // })
}

export const AppliedJobs = ({ commit }) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/jobseeker/get-applied-jobs').then((response) => {
        commit('setshowAppliedJobs', response.data.jobs);
    })
}

export const MatchingJobs = ({ commit }) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/jobseeker/get-matching-jobs').then((response) => {
        commit('setshowMatchingJobs', response.data.matching_jobs.data);
        // return response.data.matching_jobs.data

    })
}

export const JobFairSuscribeList = ({ commit }) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/job-fair/list/subscribers/2').then((response) => {
        commit('setshowSubscribeJobFairCategory', response.data.data.data);
        // return response.response.data.data.data
    })
}

export const fetchDashboardAnalytics = ({commit, dispatch}) => {
    let url = process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/dashboard-analytics';
    return window.axios.get(url).then((response) => {
        commit('setDashboardAnalytics', response.data);
        return true;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    })
}