export const getShowViewPaymentModel = (state) => {
	return state.showViewPaymentModel
}

export const getPayementHistory = (state) => {
	return state.paymentHistory.data	
}
export const getPayementHistoryListLinks = (state) => {
	return state.paymentHistory.links
}

export const getPayementHistoryById = (state) => {
      return state.paymentHistoryId
}