export const showAddFeedback = (state, flag) => {
	state.showAddFeedback = flag
}

export const showLoader = (state, flag) => {
	state.showLoader = flag
}

export const setFeedbackPublishList = (state, data) => {
	return state.feedbackPublishList = data.data.data.data
}

export const setFeedbackCategoryList = (state, data) => {
	return state.category_name = data
}
