export default [
	{
        path: '/payment-history',
        component: () => import('../components/PaymentHistory.vue'),
        name: 'payment-history',
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
    
]