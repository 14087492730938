<template>
  <div class=" flex justify-between lg:flex  flex justify-between lg:flex py-4 pl-2.5 pr-8">
    <div class="pr-2.5">
      <svg class=" cursor-pointer" width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.25" y="0.25" width="29.5" height="26.5" rx="2.75" stroke="white" stroke-width="0.5"/>
        <rect x="5" y="5" width="20" height="3" rx="1" fill="white"/>
        <rect x="5" y="12" width="20" height="3" rx="1" fill="white"/>
        <rect x="5" y="19" width="20" height="3" rx="1" fill="white"/>
      </svg>
    </div> 


    <div class="lg:block lg:w-4/12 mt-7 mr-6 text-center">
        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="fill-current text-white border rounded-full h-28 p-4 mb-2.5" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
        </svg> -->
        <div class="fill-current text-white border rounded-full h-28 w-28 mb-2.5 flex justify-center items-center">
          <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg" >
          <path d="M30.0953 33.8426C39.4049 33.8426 46.958 26.2895 46.958 16.9799C46.958 7.67026 39.4049 0.117188 30.0953 0.117188C20.7857 0.117188 13.2326 7.67026 13.2326 16.9799C13.2326 26.2895 20.7857 33.8426 30.0953 33.8426ZM45.0843 37.5898H38.632C36.0324 38.7843 33.1399 39.4634 30.0953 39.4634C27.0506 39.4634 24.1699 38.7843 21.5586 37.5898H15.1062C6.82713 37.5898 0.117188 44.2998 0.117188 52.5789V54.4525C0.117188 57.5557 2.63488 60.0734 5.73808 60.0734H54.4525C57.5557 60.0734 60.0734 57.5557 60.0734 54.4525V52.5789C60.0734 44.2998 53.3634 37.5898 45.0843 37.5898Z" fill="white"/>
          </svg>
        </div>


        <span class="text-white text-sm font-normal">Add your photo</span>
    </div>
    <div class="w-7/12">
      <div class="flex justify-between text-white">
        <div>
          <span class="font-bold text-lg mb-1">Lorem Ipsum </span>
        </div>
        <div class="self-center ">
          <span class="text-right">
            <svg class="h-4 w-4 fill-current text-white" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="432.544px" height="432.544px" viewBox="0 0 432.544 432.544" style="enable-background:new 0 0 432.544 432.544;" xml:space="preserve">
              <g>
               <g>
              <path d="M0,313.775v118.77h118.771l237.541-237.541L237.539,76.232L0,313.775z M103.638,395.999L103.638,395.999l-30.55,0.004    v-36.546H36.545v-30.553l25.981-25.981l67.093,67.092L103.638,395.999z M246.683,124.77c4.182,0,6.276,2.095,6.276,6.28    c0,1.906-0.664,3.521-1.999,4.856L96.214,290.651c-1.333,1.328-2.952,1.995-4.854,1.995c-4.184,0-6.279-2.098-6.279-6.279    c0-1.906,0.666-3.521,1.997-4.856l154.747-154.743C243.154,125.436,244.773,124.77,246.683,124.77z"/>
              <path d="M421.976,77.654l-67.091-66.806C347.653,3.619,338.992,0,328.903,0c-10.283,0-18.842,3.619-25.693,10.848l-47.394,47.109    l118.773,118.77l47.394-47.392c7.042-7.043,10.561-15.608,10.561-25.697C432.54,93.743,429.022,85.08,421.976,77.654z"/>
            </g>
          </g>
        </svg>
      </span>
    </div>
      </div>
      <div class="text-base lg:text-sm text-white mb-1">
        <span class="font-bold ">First name: </span>Lourm Ipsum
      </div>
      <div class="text-base lg:text-sm text-white mb-1">
        <span class="font-bold">Last name: </span>Lorum Ipsum
      </div>
      <div class="text-base lg:text-sm text-white mb-1">
        <span class="font-bold">Email Id: </span>abc@gmail.com
      </div>
      <div class="text-base lg:text-sm text-white mb-1">
        <span class="font-bold">Phone No: </span>123456789
      </div>
      <div class="text-base lg:text-sm text-white mb-1">
        <span class="font-bold">Indusrty Type: </span>Lorum Ipsum
      </div>
      <div class="text-base lg:text-sm text-white mb-1">
        <span class="font-bold">NDA Doc File </span>
      </div>
    </div>

    <!-- <div class="flex-none Lg:flex lg:w-1/12">
      <span class="text-right">
        <svg class="h-4 w-4 fill-current text-white" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="432.544px" height="432.544px" viewBox="0 0 432.544 432.544" style="enable-background:new 0 0 432.544 432.544;" xml:space="preserve">
          <g>
            <g>
              <path d="M0,313.775v118.77h118.771l237.541-237.541L237.539,76.232L0,313.775z M103.638,395.999L103.638,395.999l-30.55,0.004    v-36.546H36.545v-30.553l25.981-25.981l67.093,67.092L103.638,395.999z M246.683,124.77c4.182,0,6.276,2.095,6.276,6.28    c0,1.906-0.664,3.521-1.999,4.856L96.214,290.651c-1.333,1.328-2.952,1.995-4.854,1.995c-4.184,0-6.279-2.098-6.279-6.279    c0-1.906,0.666-3.521,1.997-4.856l154.747-154.743C243.154,125.436,244.773,124.77,246.683,124.77z"/>
              <path d="M421.976,77.654l-67.091-66.806C347.653,3.619,338.992,0,328.903,0c-10.283,0-18.842,3.619-25.693,10.848l-47.394,47.109    l118.773,118.77l47.394-47.392c7.042-7.043,10.561-15.608,10.561-25.697C432.54,93.743,429.022,85.08,421.976,77.654z"/>
            </g>
          </g>
        </svg>
      </span>
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src

export default {
   name: "ToggleDiv",
   el:'#showModal',
  data: function() {
    return {
      roleModelBox : false,
      isShow: false,
      show: false,
    }
  },
  components: {

  }
};
</script>

<style scoped>
.show{
  width:180px;
}
</style>

