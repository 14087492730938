export default {
  state: {
    showDeleteRole: false,
    hideDeleteRole: false,
    
},
   
  getters: {
    showDeleteRole(state) {
      return state.showDeleteRole
    },
    hideDeleteRole(state) {
      return state.hideDeleteRole
    }
    
  },
  mutations: {
    showDeleteRole(state, flag) {
      state.showDeleteRole = flag
    },
    hideDeleteRole(state) {
      state.showDeleteRole = false
    }
    
  },
}