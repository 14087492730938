export const getAgreementsList = ({ commit, dispatch, state }, query = '') => {
    if(state.paymentStatus == 'Paid'){
        query = query + '&tab_type=Paid'
    }
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/agreements-list" + query).then((response) => {
        commit('SET_AGREEMENTS_DATA', response.data);
        return response.data;
    }).catch((error) => {
        return false;
    });
}
export const editAgreement = ({ commit, dispatch, state }, query) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/get-employer-profile-details?agreement_id=" + query).then((response) => {
        commit('setEmployerData', response.data);
        return response.data;
    }).catch((error) => {
        return false;
    });
}
export const settings = ({ commit, dispatch, state },payload) => {
    let query= payload ? '?paypalEdit=true':'';
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/site-setting" + query).then((response) => {
        return response.data;
    }).catch((error) => {
        return false;
    });
}
export const uploadSignedAgreement = ({ commit, dispatch, state },{payload, context}) => {
    return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/agreement-document-upload",payload).then((response) => {
        return response.data;
    }).catch((error) => {
        return false;
    });
}
/*export const getAgreementById = ({ commit }, id) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/user/membership/agreements/"+id).then((response) => {
        return response.data;
    }).catch((error) => {
        return false;
    });
}

export const getContractById = ({ commit }, id) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/contract-agreement/"+id).then((response) => {
        return response;
    }).catch((error) => {
        return false;
    });
}

export const uploadSignedAgreement = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/save-signed-contract", payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return true
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went Wrong", { root: true });
        return false;
    });
};

export const getMembershipInclusions = ({ commit }) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL +"api/user/membership/inlusions").then((response) => {
        return response.data;
    }).catch((error) => {
        return false;
    });
}

export const checkForTransaction = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/user/membership/check-for-transaction", payload).then((response) => {
        return response.data
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went Wrong", { root: true });
        return false;
    });
};

export const membershipTransaction = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post( process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/user/membership/transaction", payload).then((response) => {
        return response.data
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went Wrong", { root: true });
        return false;
    });
};*/