export const getShowAddFeedback = (state) => {
	return state.showAddFeedback
}

export const getShowLoader = (state) => {
	return state.showLoader
}

export const getFeedbackPublishList = (state) => {
	return state.feedbackPublishList
}

export const getCategoryList = (state) => {
      return state.category_name
}