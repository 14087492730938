import store from '../store/store.js'

const beforeEach = ((to, from, next) => {
    store.dispatch('auth/checkTokenExists').then(() => {
        if (to.meta.guest) {
            if (to.name == 'search' || to.name == 'view-job') {
                next()
                return
            }
            next({ name: 'home' })
            return
        }

        next()
    }).catch(() => {
        if (to.meta.needsAuth) {
            next({ name: 'sign-in' })
            return
        }

        next()
    })
})

export default beforeEach
