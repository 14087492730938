<template>
     <div>  
        <div class="py-10"> 
            <div class="container my-10 mx-2 lg:mx-auto">
                <div class="flex items-center justify-between px-2 md:px-10 gap-2">
                    <div>
                        <label class="font-bold uppercase text-2xl border-b-2 pb-2 border-red-600"><!-- REVIEWS -->{{ $t('sec11-title') }}</label>
                    </div>
                    <div>
                        <button @click="showAddFeedback(true)" class=" text-sm md:text-lg rounded-md text-center text-white px-3 py-2 md:py-1 bg-red-600"><!-- Submit Feedback/Review -->{{ $t('si-btn2') }}</button>
                    </div>
                </div>
            </div>  

            <div class="container mx-auto">
                <div class="flex lg:flex-wrap lg:mx-10 shadow-2xl">
                    <div class="w-full mx-2 md:mx-0">
                        <ul class="flex mb-0 list-none lg:flex-wrap pt-1 border-b-2 border-red-600 lg:flex-row overflow-auto">
                            <li class="flex-auto text-center">
                                <a class="text-base px-5 py-3 block leading-normal cursor-pointer" v-on:click="toggleTabs(1, 'employer')" v-bind:class="{'text-black text-base w-36 lg:w-full': openTab !== 1, 'font-bold text-base text-white bg-bgReview rounded-t-md shadow-inner w-36 lg:w-full': openTab === 1}"><!-- Employers -->{{ $t('sec10-title') }}
                                </a>
                            </li>
                            <li class="flex-auto text-center cursor-pointer">
                                <a class="text-base px-5 py-3 block leading-normal" v-on:click="toggleTabs(2, 'job-seekers')" v-bind:class="{'text-black text-base w-36 lg:w-full': openTab !== 2, 'font-bold text-base text-white bg-bgReview rounded-t-md shadow-inner w-36 lg:w-full': openTab === 2}">
                                   <!--  Job Seekers -->{{ $t('sec11-tab2-title') }}
                                </a>
                            </li>
                            <li class="flex-auto text-center cursor-pointer">
                                <a class="text-base px-5 py-3 block leading-normal" v-on:click="toggleTabs(3, 'job-fairs')" v-bind:class="{'text-black text-base w-36 lg:w-full': openTab !== 3, 'font-bold text-base text-white bg-bgReview rounded-t-md shadow-inner w-36 lg:w-full': openTab === 3}">
                                    <!-- Job Seekers -->{{ $t('sec11-tab3-title') }}
                                </a>
                            </li>
                            <li class="flex-auto text-center cursor-pointer">
                                <a class="text-base px-5 py-3 block leading-normal" v-on:click="toggleTabs(4, 'promotions')" v-bind:class="{'text-black text-base w-36 lg:w-full': openTab !== 4, 'font-bold text-base text-white bg-bgReview rounded-t-md shadow-inner w-36 lg:w-full': openTab === 4}">
                                    {{ $t('sec11-tab4-title') }}
                               </a>
                             </li>
                             <li class="flex-auto text-center cursor-pointer">
                                <a class="text-base px-5 py-3 block leading-normal" v-on:click="toggleTabs(5, 'relocation-service')" v-bind:class="{'text-black text-base w-36 lg:w-full': openTab !== 5, 'font-bold text-base text-white bg-bgReview rounded-t-md shadow-inner w-36 lg:w-full': openTab === 5}">
                                   <!-- Relocation Service -->{{ $t('head-rs') }}
                                </a>
                            </li>
                            <li class="flex-auto text-center cursor-pointer">
                                <a class="text-base px-5 py-3 block leading-normal" v-on:click="toggleTabs(6)" v-bind:class="{'text-black text-base w-36 lg:w-full': openTab !== 6, 'font-bold text-base text-white bg-bgReview rounded-t-md shadow-inner w-36 lg:w-full': openTab === 6}">
                                    {{ $t('sec11-tab5-title') }}
                                </a>
                            </li>
                        </ul>
                        <div class="relative flex flex-col min-w-0 break-words bg-white w-full h-96">
                            <div class="px-4 py-5 flex-auto">
                                <div class="tab-content tab-space">
                                    <div class="container mx-auto relative">
                                        <div v-if="getShowLoader">
                                            <FeedbackLoader/>
                                        </div>

                                        <div class="card" >
                                            <div v-if="!getFeedbackPublishList || getFeedbackPublishList.length === 0" 
                                            class="flex justify-center items-center h-20 text-gray-600">
                                                <!-- No data availble --> {{ $t('no-data-found') }}
                                            </div>
                                            <div v-else :key="getFeedbackPublishList">
                                                <Carousel :value="getFeedbackPublishList" :num-visible="3" :num-scroll="1" :responsive-options="responsiveOptions" :showIndicators="false" :autoplayInterval="3000">
                                                <template #item="{data}">
                                                    <div class="m-4">
                                                        <div class="bg-white rounded-lg shadow-md p-4 mb-4 border">
                                                   
                                                            <div class="flex items-center mb-4">
                                                                <img :src="data.profileImageUrl ? data.profileImageUrl : '/images/user.png'" alt="Profile Photo" class="w-12 h-12 rounded-full mr-4">
                                                                <div class="flex flex-col space-y-1">
                                                                    <h2 class="text-sm font-semibold">
                                                                    {{ data.first_name }} {{ data.last_name }}</h2>
                                                                    <div class="flex">
                                                                        <svg v-for="n in parseInt(data.star)" class="fill-current text-yellow-500" xmlns="http://www.w3.org/2000/
                                                                        svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#000000"><path 
                                                                        d="M0 0h24v24H0V0z" fill="none"/><path d="M12 17.27L18.18 21l-1.64-7.03L22 
                                                                        9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"/></svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="mb-4 h-40 overflow-auto border rounded-md">
                                                                <p class="text-gray-800 p-3 tesxt-sm">{{ data.message }}</p>
                                                            </div>

                                                            <div class="flex justify-between text-xs">
                                                                <h4 class="text-right capitalize underline text-black truncate">{{data.category}}</h4>
                                                                <h4 class="text-right">{{ formatDate(data.created_at) }}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                                </Carousel>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
        <!-- ~~~~~~~~model box add your feedback -->
        <div v-if="getShowAddFeedback">
            <AddFeedback/>
        </div>
        
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapMutations } from 'vuex'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import Carousel from 'primevue/carousel';
import AddFeedback from './partials/AddFeedback.vue'
import FeedbackLoader from './partials/FeedbackLoader.vue'

export default  ({
    data: function() {
        return {             
            openTab: 6,
            first_name:'',
            last_name:'',
            email:'',
            message:'',
            profileImageUrl:'',
            time:'',
            date:'',            
            star:'',
            feedbackList: [],
            
            responsiveOptions:[
                {
                    breakpoint: '2600px',
                    numVisible: 4,
                    numScroll: 1
                },
                {
                    breakpoint: '1499px',
                    numVisible: 3,
                    numScroll: 1
                },
                {
                    breakpoint: '1067px',
                    numVisible: 2,
                    numScroll: 1
                },
                {
                    breakpoint: '575px',
                    numVisible: 1,
                    numScroll: 1
                }
            ],
        }
    },
    components: {
        Carousel,
        AddFeedback,
        FeedbackLoader,
    },
    computed: {
        ...mapGetters({
            getShowAddFeedback: 'feedbackReviews/getShowAddFeedback',
            getFeedbackPublishList: 'feedbackReviews/getFeedbackPublishList',
            getShowLoader: 'feedbackReviews/getShowLoader',
        }),    
        ...mapState({
            feedbackReviews: 'feedbackReviews', 
        }),    
    },
    methods: {    
        ...mapMutations({
            'showAddFeedback':'feedbackReviews/showAddFeedback',
            'showLoader':'feedbackReviews/showLoader',
        }),
        ...mapActions({
            fetchFeedbackPublishList: 'feedbackReviews/fetchFeedbackPublishList',            
        }),

        formatDate(dateTimeString) {
            const date = new Date(dateTimeString);
            return date.toISOString().split('T')[0];
        },
        
        toggleTabs(tabNumber, category=""){
            this.openTab = tabNumber;   
            this.$store.commit('feedbackReviews/setFeedbackCategoryList', category);
            this.fetchData()
        },

        fetchData(){
            try{
                this.showLoader(true)
                this.fetchFeedbackPublishList().then((response) => {
                    if(response){
                        setTimeout(() => {
                            this.showLoader(false)
                        }, 500);
                    }
                })
                .catch((err) => {
                });
            }catch(error){
                
            }
        },

        handleRouteChange(){
            const currentPath = this.$route.path;

            const routeTabMapping = {
                '/': { tab: 2, category: 'job-seekers' },
                '/employer': { tab: 1, category: 'employer' },
                '/job-seeker': { tab: 2, category: 'job-seekers' },
                '/titp': { tab: 1, category: 'employer' },
                '/outsourcing-tender': { tab: 1, category: 'employer' },
                '/job-fair': { tab: 3, category: 'job-fairs' },
                '/advertisements':{ tab: 4, category: 'promotions' },
                '/relocation-service':{ tab: 5, category: 'relocation-service' },
                '/request-relocation':{ tab: 5, category: 'relocation-service' },
                '/dashboard-home':{ tab: 6},
            };

            if (routeTabMapping[currentPath]) {
                const { tab, category } = routeTabMapping[currentPath];
                this.toggleTabs(tab, category);
            } else {
                this.toggleTabs(2, 'job-seekers');
            }
            this.fetchData();
        }
        
    },

    watch: {
        '$route'() {
            this.handleRouteChange(); // Call the function to handle route-based tab change
        }
    },

    mounted() {
        this.handleRouteChange();
        
    },
    
});
</script>


<style>
/*~~~~~~~~~~~~~~scrollbarReview classes*/
#scrollbarReview::-webkit-scrollbarReview {
  width: 10px;
}

/* Track */
#scrollbarReview::-webkit-scrollbarReview-track {
  background: #f1f1f1; 
}

/* Handle */
#scrollbarReview::-webkit-scrollbarReview-thumb {
  background: #888; 
}

/* Handle on hover */
#scrollbarReview::-webkit-scrollbarReview-thumb:hover {
  background: #555; 
}
/*~~~~~~~~~~~~~~~~~~~~*/
</style>