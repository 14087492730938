export default [
	{
		path: '/search',
		component: () => import('../components/Search.vue'),
		name: 'searchHome',
		meta: {
		}
	},
	{
        path: '/view-employer/:id',
        // path: '/view-employer',
        component: () => import('../components/ViewEmployer.vue'),
        name: 'view-employer',
        // meta: { 
        //     guest: true,
        //     needsAuth: false,        
        // }
    },
]