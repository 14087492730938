export const setAdvertisementData = (state, data) => {
	state.advertisement.advertisementData = data.data.data;
	state.advertisement.advertisementLinks = data.data.links;

    state.advertisement.advertisementMeta = {
        current_page: data.data.current_page,
        from: data.data.from,
        to:  data.data.to,
        total: data.data.total,
        perPage: data.data.per_page
    }
}

export const setSearchAdv = (state, adv) =>{
    state.adv.ad_title = adv.ad_title
    state.adv.ad_type = adv.ad_type
}

export const setSearchedJobs = (state, jobs) =>{
    state.searchedJobs = jobs
}

export const setSearchedJobPagination = (state, jobPaginationData) =>{
    state.searchedJobsPagination.links = jobPaginationData.links
    state.searchedJobsPagination.meta = jobPaginationData.meta
}

export const setCurrentFilterType = (state, filter) =>{
    state.currentFilterType = filter
}

export const setCommonSearchQuery = (state, query) =>{
    state.commonSearchQuery = query
}
export const setLocationSearchQuery = (state, query) =>{
    state.locationSearchQuery = query
}
export const setMatchingPercentageSearchQuery = (state, query) =>{
    state.matchingPercetageSearchQuery = query
}
export const setDaysSearchQuery = (state, query) =>{
    state.daysSearchQuery = query
}
export const setTitpSearchQuery = (state, query) =>{
    state.titpSearchQuery = query
}

export const setSalaryFromQuery = (state, query) =>{
    state.salaryFromQuery = query
}
export const setSalaryToQuery = (state, query) =>{
    state.salaryToQuery = query
}
export const setSalaryCurrencyQuery = (state, query) =>{
    state.salaryCurrencyQuery = query
}

export const setSalaryRangeQuery = (state, query) =>{
    state.salaryRangeQuery = query
}

export const setJobTypeQuery = (state, query) =>{
    state.jobTypeQuery = query
}

export const setCityQuery = (state, query) =>{
    state.cityQuery = query
}

export const setStateQuery = (state, query) =>{
    state.stateQuery = query
}

export const setCountryQuery = (state, query) =>{
    state.countryQuery = query
}

export const setExperienceQuery = (state, query) =>{
    state.experienceQuery = query
}

export const setPositionQuery = (state, query) =>{
    state.positionQuery = query
}

export const setWorkStyleQuery = (state, query) =>{
    state.workStyleQuery = query
}

export const setStrengthQuery = (state, query) =>{
    state.strengthQuery = query
}

export const setSkillQuery = (state, query) =>{
    state.skillQuery = query
}

export const setTitleQuery = (state, query) =>{
    state.titleQuery = query
}

export const setFunctionQuery = (state, query) =>{
    state.functionQuery = query
}

export const setEducationQuery = (state, query) =>{
    state.educationQuery = query
}

export const setEducationIds = (state, query) =>{
    state.educationQueryIds = query
}

export const setLanguageQuery = (state, query) =>{
    state.languageQuery = query
}

export const setIndustryQuery = (state, query) =>{
    state.industryQuery = query
}

export const setCourseQuery = (state, query) =>{
    state.courseQuery = query
}

export const setIndustrySubQuery = (state, query) =>{
    state.industrySubQuery = query
}

export const setPerPageQuery = (state, query) =>{
    state.perPageQuery = query
}

export const setPageQuery = (state, query) =>{
    state.pageQuery = query
}

export const setResultCount = (state, query) =>{
    state.resultCount = query
}

export const setPositionData = (state, data) =>{
    state.position = data
}

export const setTitleData = (state, data) =>{
    state.title = data
}

export const setWorkStyleData = (state, data) =>{
    state.workstyle = data
}

export const setIndustryData = (state, data) =>{
    state.industry = data
}

export const setIndustrySubData = (state, data) =>{
    state.industrySub = data
}

export const setStrengthData = (state, data) =>{
    state.strength = data
}

export const setKeySkillData = (state, data) =>{
    state.keySkill = data
}

export const setFunctionAreaData = (state, data) =>{
    state.functionArea = data
}

export const setLanguageData = (state, data) =>{
    state.language = data
}

export const setEducationData = (state, data) =>{
    state.education = data
}

export const setCourseMinorData = (state, data) =>{
    state.courseMinor = data
}

export const setEmployerTypeQuery = (state, query) =>{
    state.employerTypeQuery = query
}

export const setTopCompanies = (state, data) =>{
    state.topCompanies = data
}

export const setCompanySearchQuery = (state, query) =>{
    state.companySearchQuery = query
}

export const setEmployerTypeData = (state, data) =>{
    state.employerType = data
}

export const setSearchedEmployers = (state, employers) =>{
    state.searchedEmployers = employers
}

export const setSearchedEmployerPagination = (state, empPaginationData) =>{
    state.searchedEmployersPagination.links = empPaginationData.links
    state.searchedEmployersPagination.meta = empPaginationData.meta
}

export const setEmployerPageQuery = (state, query) =>{
    state.empPageQuery = query
}

export const setEmployerResultCount = (state, query) =>{
    state.empResultCount = query
}

export const setPerPageEmployerQuery = (state, query) =>{
    state.perPageEmployerQuery = query
}

export const setEmployerCompanyNameSearchQuery = (state, query) =>{
    state.empCompanyNameSearchQuery = query
}

export const setEmployerLocationSearchQuery = (state, query) =>{
    state.empLocationSearchQuery = query
}

export const setEmployerTITPSearchQuery = (state, query) =>{
    state.empTitpSearchQuery = query
}

export const setBlockModule = (state, query) =>{
    state.isBlockModule = query
}

