export const getShowAddNewCandidateModel = (state) => {
	return state.showAddNewCandidateModel
}

 export const getShowViewCandidate = (state) => {
    return state.showViewCandidate
  }


export const getCandidateList = (state) => {
	return state.candidateList.data
}
export const getCandidateById = (state) => {
      return state.candidateViewId
}

export const showDeleteCandidate = (state) => {
	return state.showDeleteCandidate
}

export const getCandidateListLinks = (state) => {
    return state.candidateList.links
}

export const getCandidateListMeta = (state) => {
    return state.candidateList.meta
}
export const getcandidateView = (state) => {
    return state.candidateView
}
