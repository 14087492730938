export const fetchScoutList = ({ commit } , query = '') => {
    return window.axios
      .get(process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/scout-inbox" + query)
      .then((response) => {
        commit("set_scout_inbox_data", response)
        return response.data.data.data;
      })
      .catch((error) => {
        return false;
    });   
};

export const fetchScoutSendboxList = ({ commit } , query = '') => {
    return window.axios
      .get(process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/scout-sendbox" + query)
      .then((response) => {
        commit("set_scout_sendbox_data", response)
        return response.data.data.data;
      })
      .catch((error) => {
        return false;
    });   
};

export const postScout = ({ commit}, { payload, id }) => {
  return window.axios.post('/api/scout-send/' + id, payload).then((response) => {
    commit('SET_ALERT', 'success', { root: true })
    commit("SET_ALERT_MESSAGE", response.data.message, {root: true});
    return response.data;
  }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
  });   
};

export const deleteScoutMail = ({commit}, {payload, id, context}) => {
  return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/scout-delete/' + id, payload).then((response) => {
      commit('SET_ALERT', 'success', { root: true })
      commit("SET_ALERT_MESSAGE", response.data.message, {root: true});
      return response.data;
  }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      context.errors = error.response.data
      return false
  });   
};

export const deleteInbox = ({ commit}, id) => {
  return window.axios.delete(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/delete-inbox/'+id+'').then((response) => {
      commit('SET_ALERT', 'success', { root: true })
      commit("SET_ALERT_MESSAGE", response.data.message, {root: true});
      return response.data;
  }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
  });   
};

export const fetchScoutDataById = ({ commit } , id) => {
  return window.axios
    .get(process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/view-scout/" + id)
    .then((response) => {
      commit("setScoutDataById", response)
      return response;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
  });   
};

export const postScoutReply = ({ commit}, { payload, id }) => {
  return window.axios.post('/api/scout-reply/' + id, payload).then((response) => {
    commit('SET_ALERT', 'success', { root: true })
    commit("SET_ALERT_MESSAGE", response.data.message, {root: true});
    return response.data;
  }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
  });   
};