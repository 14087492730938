<template>
  <div class="container mx-auto">
    <div class="border border-borderGray rounded-md p-3 box-border">
      <div class="block lg:hidden py-3">
        <button @click="show = !show">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="mx-3 h-7 w-7 fill-current text-gray-500"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>

      <!-- ============= Responsive SideBar ============ -->
      <div class="block lg:hidden bg-white w-screen lg:w-auto h-auto pr-10">
        <section v-if="show" class="absolute -mt-14">
          <div class="bg-white pb-1 shadow h-auto">
            <button
              @click="show = !show"
              class="m-3 fill-current text-gray-800"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <ProfileJobSidebar />
          </div>
        </section>
      </div>
      <!--============= Responsive SideBar End ============  -->

      <!-- <div class="pt-16 box-border border-b-2 border-red-300 w-40 mx-8 h-full">Jobs
        </div> -->

      <div class="flex justify-between my-6 mx-1">
        <div
          class="
            box-border
            border-b-2 border-darkRed
            inline-block
            font-bold
            text-lg
            pb-2
            h-full
          "
        >
          {{$t('msot-sec3-txt5')}}
        </div>
        <div class="flex justify-between">
          <div class="self-center">
            <span class="text-sm">{{$t('ejl-txt2')}}</span>
          </div>

          <select
            class="
              border
              rounded-full
              border-gray-300
              w-20
              h-8
              outline-none
              self-center
              text-center
              mx-2
              py-0.5
            "
            name="cars"
            id="cars"
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="75">75</option>
            <option value="100">100</option>
          </select>
          <div class="self-center">
            <span class="text-sm">{{$t('ejl-txt3')}}</span>
          </div>
        </div>
        <!-- <div class="flex self-center ">
          <button class="border rounded-md bg-gray-400 w-7 h-7 relative">
            <svg
              class="absolute top-1.5 left-1.5"
              width="13"
              height="15"
              viewBox="0 0 13 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 0L4.0625 7.05882V12.3529L8.9375 15V7.05882L13 0H0Z"
                fill="black"
              ></path>
            </svg>
          </button>
          <div class="self-center ml-1.5 text-sm">Clear</div>
        </div> -->
        <div class="flex justify-between">
          
          <a class="
          self-center text-center
              bg-textGray
              text-white text-xs
              font-normal
              w-44
              p-2
              rounded-full
            " href="http://localhost:8080/add-new-job">{{$t('ejl-btn1')}}</a>
        </div>
      </div>

      <div
      class="modal-overlay"
      style="display: block"
      v-show="isOpen"
      @click="hidemodal"
    ></div>
     <div
      class="modal-overlay"
      style="display: block"
      v-show="isColumn"
      @click="hideColumn"
    ></div>

      <div class="flex ">
        <div class="flex w-11/12 justify-between mx-1">
        <div class="flex">
          <button
      @click.prevent="toggle"
      class="js-open-modal border rounded-md bg-gray-400 w-7 h-7 relative"
    >
      <svg
        class="absolute top-1.5 left-1.5"
        width="13"
        height="15"
        viewBox="0 0 13 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0L4.0625 7.05882V12.3529L8.9375 15V7.05882L13 0H0Z"
          fill="black"
        ></path>
      </svg>
    </button>

          <div class="self-center ml-1.5 text-sm">{{$t('ejl-btn1')}}</div>
        </div>
        <div class="flex w-28 self-center -mr-8">
          <div class="text-sm self-center mr-1.5">{{$t('emc-btn2')}}</div>

         <button
            @click.prevent="addColumn"
            class="js-open-column border rounded-md bg-gray-400 w-7 h-7 relative"
          >
            <svg
              class="absolute top-1.5 left-1.5"
              width="13"
              height="14"
              viewBox="0 0 13 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.29251 5.44922H12.523V8.50781H8.29251V13.2891H5.06986V8.50781H0.827671V5.44922H5.06986V0.867188H8.29251V5.44922Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
      </div>

       <div class="flex w-1/12 self-center justify-end ">
          <button class="border rounded-md bg-gray-400 w-7 h-7 relative">
            <svg
              class="absolute top-1.5 left-1.5"
              width="13"
              height="15"
              viewBox="0 0 13 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 0L4.0625 7.05882V12.3529L8.9375 15V7.05882L13 0H0Z"
                fill="black"
              ></path>
            </svg>
          </button>
          <!-- <div class="self-center ml-1.5 text-sm">Clear</div> -->
        </div>
      </div>

      

      <!-- DataTable section -->
      <div class="relative">
        <div class="whitespace-nowrap w-96 min-w-full overflow-auto mt-7">
          <div
            class="
              modal
          absolute
          divide-y divide-borderGray divide-solid
          text-sm
          ml-1.5
          bg-white
          w-36
          z-100
          shadow-smNew
          overflow-auto
          top-0
          -mt-6
            "
          >
            <p @click="draftJobs" class="px-4 py-2 cursor-pointer">
              Draft Jobs
            </p>
            <p @click="publishedJobs" class="px-4 py-2 cursor-pointer">
              Published Jobs
            </p>
            <p @click="closedJobs" class="px-4 py-2 cursor-pointer">
              Closed Jobs
            </p>
          </div>

           <div
            class=" addcolumn
          absolute bg-white z-100 shadow-smNew absolute right-0 mr-4 top-0 -mt-6"
          >
            <div class="h-64 overflow-auto">
              <div class="text-sm">
               
                <div class="px-3.5 py-2 border-b border-borderGray">
                  <input
                    class="mr-3"
                    type="checkbox"
                    id="department"
                    name="department"
                    value="Department"
                  />
                  <label for="department">Department</label>
                </div>
                <div class="px-3.5 py-2 border-b border-borderGray">
                  <input
                    class="mr-3"
                    type="checkbox"
                    id="jobLocation"
                    name="jobLocation"
                    value="Job Location"
                  />
                  <label for="jobLocation">Job Location</label>
                </div>
                <div class="px-3.5 py-2 border-b border-borderGray">
                  <input
                    class="mr-3"
                    type="checkbox"
                    id="hiringManager"
                    name="hiringManager"
                    value="Hiring Manager"
                  />
                  <label for="hiringManager">Hiring Manager</label>
                </div>
                <div class="px-3.5 py-2 border-b border-borderGray">
                  <input
                    class="mr-3"
                    type="checkbox"
                    id="education"
                    name="education"
                    value="Education"
                  />
                  <label for="education">Education</label>
                </div>
                <div class="px-3.5 py-2 border-b border-borderGray">
                  <input
                    class="mr-3"
                    type="checkbox"
                    id="experience"
                    name="experience"
                    value="Experience"
                  />
                  <label for="experience">Experience</label>
                </div>
                <div class="px-3.5 py-2 border-b border-borderGray">
                  <input
                    class="mr-3"
                    type="checkbox"
                    id="workStyle"
                    name="workStyle"
                    value="Work Style"
                  />
                  <label for="workStyle">Work Style</label>
                </div>
                <div class="px-3.5 py-2">
                  <input
                    class="mr-3"
                    type="checkbox"
                    id="status"
                    name="status"
                    value="Status"
                  />
                  <label for="status">Status</label>
                </div>
              </div>
            </div>
            <div class="p-3 flex justify-center">
              <button
                class="
                  border border-tabGray
                  bg-tagGray
                  rounded-3xl
                  text-xs
                  py-0.5
                  px-2
                  mr-2
                "
              >
                Submit
              </button>
              <button
                class="
                  border border-tabGray
                  bg-tagGray
                  rounded-3xl
                  text-xs
                  py-0.5
                  px-2
                "
                @click="hideColumn()"
              >
                Cancel
              </button>
            </div>
          </div>

          <table
            class="
              table-fixed
              whitespace-nowrap
              w-full
              overflow-auto
              table_main
            "
          >
            <!-- table headings -->
            <thead>
            <tr>
              <th
                class="
                  w-32
                  text-left text-sm
                  relative
                  border border-gray-300
                  text-center
                "
              >
                {{$t('ejl-txt4')}}
                <div type="text" class="w-full h-4 px-1 mt-1 mb-2"></div>
              </th>

              <!-- <th
              class="
                  w-32
                  text-left text-sm
                  relative
                  border border-gray-300
                  text-center
                ">
                <div>
                  <div>
                    <span>Position</span>
                  </div>
                  <div></div>
                </div>
              </th> -->

              <th
                class="
                  w-32
                  relative
                  text-sm text-left
                  box-border
                  px-2
                  border border-gray-300
                "
              >
                <span class="cursor-pointer">{{$t('mjc-tbl1-col1-row2-txt')}}</span>

                <button>
                  <svg
                    class="absolute right-2 top-2"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="5" cy="5" r="5" fill="#C4C4C4" />
                    <path
                      d="M5.19328 5.99648C5.09151 5.90697 4.92599 5.92046 4.83648 6.02223L3.89239 7.08648V2.83315C3.89239 2.69338 3.77714 2.57812 3.63736 2.57812C3.49759 2.57812 3.38233 2.69338 3.38233 2.83315V7.08648L2.43824 6.02223C2.33647 5.92046 2.18321 5.90697 2.08145 5.99648C1.97968 6.09825 1.96619 6.25151 2.0557 6.35327L3.44609 7.90919C3.55031 8.03793 3.73422 8.01831 3.81637 7.90919L5.21903 6.35327C5.30853 6.25273 5.29627 6.08598 5.19328 5.99648Z"
                      fill="white"
                    />
                    <path
                      d="M7.9241 3.65157L6.54597 2.09566C6.42336 1.97182 6.2701 1.96446 6.16343 2.09566L4.76078 3.65157C4.67127 3.75334 4.68476 3.91886 4.78652 4.00836C4.94347 4.13343 5.10163 4.0476 5.14332 3.98262L6.08741 2.91837V7.15943C6.08741 7.29921 6.20267 7.41446 6.34244 7.41446C6.48222 7.41446 6.59747 7.31269 6.59747 7.17169V2.91959L7.54156 3.98384C7.64333 4.08561 7.79659 4.0991 7.89836 4.00959C8.00012 3.9066 8.01361 3.75334 7.9241 3.65157Z"
                      fill="white"
                    />
                  </svg>
                </button>

                <div class="flex h-4 border rounded-lg px-1 mt-1 mb-2">
                  <input type="text" class="outline-none text-xs w-full pl-1" />
                  <svg
                    width="10"
                    height="12"
                    viewBox="0 0 7 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.6207 6.99305L4.95936 5.33034C5.35244 4.84823 5.58939 4.23465 5.58939 3.56491C5.58939 2.0241 4.33619 0.769531 2.79401 0.769531C1.25183 0.769531 0 2.0241 0 3.56491C0 5.10572 1.25319 6.35892 2.79538 6.35892C3.46375 6.35892 4.0787 6.12197 4.56081 5.72889L6.22215 7.39023C6.33582 7.50391 6.50839 7.50391 6.62207 7.39023C6.73575 7.27656 6.73575 7.10672 6.6207 6.99305ZM0.557432 3.56491C0.557432 2.33089 1.55999 1.32833 2.79401 1.32833C4.02803 1.32833 5.03195 2.33226 5.03195 3.56628C5.03195 4.8003 4.02803 5.80422 2.79401 5.80422C1.55999 5.80422 0.557432 4.79893 0.557432 3.56491Z"
                      fill="#C4C4C4"
                    />
                  </svg>
                </div>
              </th>
              <th
                class="
                  w-32
                  relative
                  text-sm text-left
                  box-border
                  px-2
                  border border-gray-300
                "
              >
               <span class="cursor-pointer">{{$t('mjc-tbl1-col2-row1')}}</span>
                <button>
                  <svg
                    class="absolute right-2 top-2"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="5" cy="5" r="5" fill="#C4C4C4" />
                    <path
                      d="M5.19328 5.99648C5.09151 5.90697 4.92599 5.92046 4.83648 6.02223L3.89239 7.08648V2.83315C3.89239 2.69338 3.77714 2.57812 3.63736 2.57812C3.49759 2.57812 3.38233 2.69338 3.38233 2.83315V7.08648L2.43824 6.02223C2.33647 5.92046 2.18321 5.90697 2.08145 5.99648C1.97968 6.09825 1.96619 6.25151 2.0557 6.35327L3.44609 7.90919C3.55031 8.03793 3.73422 8.01831 3.81637 7.90919L5.21903 6.35327C5.30853 6.25273 5.29627 6.08598 5.19328 5.99648Z"
                      fill="white"
                    />
                    <path
                      d="M7.9241 3.65157L6.54597 2.09566C6.42336 1.97182 6.2701 1.96446 6.16343 2.09566L4.76078 3.65157C4.67127 3.75334 4.68476 3.91886 4.78652 4.00836C4.94347 4.13343 5.10163 4.0476 5.14332 3.98262L6.08741 2.91837V7.15943C6.08741 7.29921 6.20267 7.41446 6.34244 7.41446C6.48222 7.41446 6.59747 7.31269 6.59747 7.17169V2.91959L7.54156 3.98384C7.64333 4.08561 7.79659 4.0991 7.89836 4.00959C8.00012 3.9066 8.01361 3.75334 7.9241 3.65157Z"
                      fill="white"
                    />
                  </svg>
                </button>

                <div class="flex h-4 border rounded-lg px-1 mt-1 mb-2">
                  <input type="text" class="outline-none text-xs w-full pl-1" />
                  <svg
                    width="10"
                    height="12"
                    viewBox="0 0 7 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.6207 6.99305L4.95936 5.33034C5.35244 4.84823 5.58939 4.23465 5.58939 3.56491C5.58939 2.0241 4.33619 0.769531 2.79401 0.769531C1.25183 0.769531 0 2.0241 0 3.56491C0 5.10572 1.25319 6.35892 2.79538 6.35892C3.46375 6.35892 4.0787 6.12197 4.56081 5.72889L6.22215 7.39023C6.33582 7.50391 6.50839 7.50391 6.62207 7.39023C6.73575 7.27656 6.73575 7.10672 6.6207 6.99305ZM0.557432 3.56491C0.557432 2.33089 1.55999 1.32833 2.79401 1.32833C4.02803 1.32833 5.03195 2.33226 5.03195 3.56628C5.03195 4.8003 4.02803 5.80422 2.79401 5.80422C1.55999 5.80422 0.557432 4.79893 0.557432 3.56491Z"
                      fill="#C4C4C4"
                    />
                  </svg>
                </div>
              </th>
              <th
                class="
                  w-32
                  relative
                  text-sm text-left
                  box-border
                  px-2
                  border border-gray-300
                "
              >
              <span class="cursor-pointer">{{$t('ejl-col4-title')}}</span>
               
                <button>
                  <svg
                    class="absolute right-2 top-2"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="5" cy="5" r="5" fill="#C4C4C4" />
                    <path
                      d="M5.19328 5.99648C5.09151 5.90697 4.92599 5.92046 4.83648 6.02223L3.89239 7.08648V2.83315C3.89239 2.69338 3.77714 2.57812 3.63736 2.57812C3.49759 2.57812 3.38233 2.69338 3.38233 2.83315V7.08648L2.43824 6.02223C2.33647 5.92046 2.18321 5.90697 2.08145 5.99648C1.97968 6.09825 1.96619 6.25151 2.0557 6.35327L3.44609 7.90919C3.55031 8.03793 3.73422 8.01831 3.81637 7.90919L5.21903 6.35327C5.30853 6.25273 5.29627 6.08598 5.19328 5.99648Z"
                      fill="white"
                    />
                    <path
                      d="M7.9241 3.65157L6.54597 2.09566C6.42336 1.97182 6.2701 1.96446 6.16343 2.09566L4.76078 3.65157C4.67127 3.75334 4.68476 3.91886 4.78652 4.00836C4.94347 4.13343 5.10163 4.0476 5.14332 3.98262L6.08741 2.91837V7.15943C6.08741 7.29921 6.20267 7.41446 6.34244 7.41446C6.48222 7.41446 6.59747 7.31269 6.59747 7.17169V2.91959L7.54156 3.98384C7.64333 4.08561 7.79659 4.0991 7.89836 4.00959C8.00012 3.9066 8.01361 3.75334 7.9241 3.65157Z"
                      fill="white"
                    />
                  </svg>
                </button>
                <div class="flex h-4 border rounded-lg px-1 mt-1 mb-2">
                  <input type="text" class="outline-none text-xs w-full pl-1" />
                  <svg
                    width="10"
                    height="12"
                    viewBox="0 0 7 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.6207 6.99305L4.95936 5.33034C5.35244 4.84823 5.58939 4.23465 5.58939 3.56491C5.58939 2.0241 4.33619 0.769531 2.79401 0.769531C1.25183 0.769531 0 2.0241 0 3.56491C0 5.10572 1.25319 6.35892 2.79538 6.35892C3.46375 6.35892 4.0787 6.12197 4.56081 5.72889L6.22215 7.39023C6.33582 7.50391 6.50839 7.50391 6.62207 7.39023C6.73575 7.27656 6.73575 7.10672 6.6207 6.99305ZM0.557432 3.56491C0.557432 2.33089 1.55999 1.32833 2.79401 1.32833C4.02803 1.32833 5.03195 2.33226 5.03195 3.56628C5.03195 4.8003 4.02803 5.80422 2.79401 5.80422C1.55999 5.80422 0.557432 4.79893 0.557432 3.56491Z"
                      fill="#C4C4C4"
                    />
                  </svg>
                </div>
              </th>
              <th
                class="
                  w-32
                  relative
                  text-sm text-left
                  box-border
                  px-2
                  border border-gray-300
                "
              >
              <span class="cursor-pointer">{{$t('ejl-col5-title')}}</span>
               
                <button>
                  <svg
                    class="absolute right-1 top-2"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="5" cy="5" r="5" fill="#C4C4C4" />
                    <path
                      d="M5.19328 5.99648C5.09151 5.90697 4.92599 5.92046 4.83648 6.02223L3.89239 7.08648V2.83315C3.89239 2.69338 3.77714 2.57812 3.63736 2.57812C3.49759 2.57812 3.38233 2.69338 3.38233 2.83315V7.08648L2.43824 6.02223C2.33647 5.92046 2.18321 5.90697 2.08145 5.99648C1.97968 6.09825 1.96619 6.25151 2.0557 6.35327L3.44609 7.90919C3.55031 8.03793 3.73422 8.01831 3.81637 7.90919L5.21903 6.35327C5.30853 6.25273 5.29627 6.08598 5.19328 5.99648Z"
                      fill="white"
                    />
                    <path
                      d="M7.9241 3.65157L6.54597 2.09566C6.42336 1.97182 6.2701 1.96446 6.16343 2.09566L4.76078 3.65157C4.67127 3.75334 4.68476 3.91886 4.78652 4.00836C4.94347 4.13343 5.10163 4.0476 5.14332 3.98262L6.08741 2.91837V7.15943C6.08741 7.29921 6.20267 7.41446 6.34244 7.41446C6.48222 7.41446 6.59747 7.31269 6.59747 7.17169V2.91959L7.54156 3.98384C7.64333 4.08561 7.79659 4.0991 7.89836 4.00959C8.00012 3.9066 8.01361 3.75334 7.9241 3.65157Z"
                      fill="white"
                    />
                  </svg>
                </button>
                <div class="flex h-4 border rounded-lg px-1 mt-1 mb-2">
                  <input type="text" class="outline-none text-xs w-full pl-1" />
                  <svg
                    width="10"
                    height="12"
                    viewBox="0 0 7 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.6207 6.99305L4.95936 5.33034C5.35244 4.84823 5.58939 4.23465 5.58939 3.56491C5.58939 2.0241 4.33619 0.769531 2.79401 0.769531C1.25183 0.769531 0 2.0241 0 3.56491C0 5.10572 1.25319 6.35892 2.79538 6.35892C3.46375 6.35892 4.0787 6.12197 4.56081 5.72889L6.22215 7.39023C6.33582 7.50391 6.50839 7.50391 6.62207 7.39023C6.73575 7.27656 6.73575 7.10672 6.6207 6.99305ZM0.557432 3.56491C0.557432 2.33089 1.55999 1.32833 2.79401 1.32833C4.02803 1.32833 5.03195 2.33226 5.03195 3.56628C5.03195 4.8003 4.02803 5.80422 2.79401 5.80422C1.55999 5.80422 0.557432 4.79893 0.557432 3.56491Z"
                      fill="#C4C4C4"
                    />
                  </svg>
                </div>
              </th>
              <th
                class="
                  w-32
                  relative
                  text-sm text-left
                  box-border
                  px-2
                  border border-gray-300
                "
              >
               <span class="cursor-pointer">{{$t('head-c')}}</span>
                
                <button>
                  <svg
                    class="absolute right-2 top-2"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="5" cy="5" r="5" fill="#C4C4C4" />
                    <path
                      d="M5.19328 5.99648C5.09151 5.90697 4.92599 5.92046 4.83648 6.02223L3.89239 7.08648V2.83315C3.89239 2.69338 3.77714 2.57812 3.63736 2.57812C3.49759 2.57812 3.38233 2.69338 3.38233 2.83315V7.08648L2.43824 6.02223C2.33647 5.92046 2.18321 5.90697 2.08145 5.99648C1.97968 6.09825 1.96619 6.25151 2.0557 6.35327L3.44609 7.90919C3.55031 8.03793 3.73422 8.01831 3.81637 7.90919L5.21903 6.35327C5.30853 6.25273 5.29627 6.08598 5.19328 5.99648Z"
                      fill="white"
                    />
                    <path
                      d="M7.9241 3.65157L6.54597 2.09566C6.42336 1.97182 6.2701 1.96446 6.16343 2.09566L4.76078 3.65157C4.67127 3.75334 4.68476 3.91886 4.78652 4.00836C4.94347 4.13343 5.10163 4.0476 5.14332 3.98262L6.08741 2.91837V7.15943C6.08741 7.29921 6.20267 7.41446 6.34244 7.41446C6.48222 7.41446 6.59747 7.31269 6.59747 7.17169V2.91959L7.54156 3.98384C7.64333 4.08561 7.79659 4.0991 7.89836 4.00959C8.00012 3.9066 8.01361 3.75334 7.9241 3.65157Z"
                      fill="white"
                    />
                  </svg>
                </button>
                <div class="flex h-4 border rounded-lg px-1 mt-1 mb-2">
                  <input type="text" class="outline-none text-xs w-full pl-1" />
                  <svg
                    width="10"
                    height="12"
                    viewBox="0 0 7 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.6207 6.99305L4.95936 5.33034C5.35244 4.84823 5.58939 4.23465 5.58939 3.56491C5.58939 2.0241 4.33619 0.769531 2.79401 0.769531C1.25183 0.769531 0 2.0241 0 3.56491C0 5.10572 1.25319 6.35892 2.79538 6.35892C3.46375 6.35892 4.0787 6.12197 4.56081 5.72889L6.22215 7.39023C6.33582 7.50391 6.50839 7.50391 6.62207 7.39023C6.73575 7.27656 6.73575 7.10672 6.6207 6.99305ZM0.557432 3.56491C0.557432 2.33089 1.55999 1.32833 2.79401 1.32833C4.02803 1.32833 5.03195 2.33226 5.03195 3.56628C5.03195 4.8003 4.02803 5.80422 2.79401 5.80422C1.55999 5.80422 0.557432 4.79893 0.557432 3.56491Z"
                      fill="#C4C4C4"
                    />
                  </svg>
                </div>
              </th>
              <th
                class="
                  w-32
                  relative
                  text-sm text-left
                  box-border
                  px-2
                  border border-gray-300
                "
              >
                <span class="pr-4 cursor-pointer">{{$t('p-tab2-title')}}</span>
                <button>
                  <svg
                    class="absolute right-2 top-2"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="5" cy="5" r="5" fill="#C4C4C4" />
                    <path
                      d="M5.19328 5.99648C5.09151 5.90697 4.92599 5.92046 4.83648 6.02223L3.89239 7.08648V2.83315C3.89239 2.69338 3.77714 2.57812 3.63736 2.57812C3.49759 2.57812 3.38233 2.69338 3.38233 2.83315V7.08648L2.43824 6.02223C2.33647 5.92046 2.18321 5.90697 2.08145 5.99648C1.97968 6.09825 1.96619 6.25151 2.0557 6.35327L3.44609 7.90919C3.55031 8.03793 3.73422 8.01831 3.81637 7.90919L5.21903 6.35327C5.30853 6.25273 5.29627 6.08598 5.19328 5.99648Z"
                      fill="white"
                    />
                    <path
                      d="M7.9241 3.65157L6.54597 2.09566C6.42336 1.97182 6.2701 1.96446 6.16343 2.09566L4.76078 3.65157C4.67127 3.75334 4.68476 3.91886 4.78652 4.00836C4.94347 4.13343 5.10163 4.0476 5.14332 3.98262L6.08741 2.91837V7.15943C6.08741 7.29921 6.20267 7.41446 6.34244 7.41446C6.48222 7.41446 6.59747 7.31269 6.59747 7.17169V2.91959L7.54156 3.98384C7.64333 4.08561 7.79659 4.0991 7.89836 4.00959C8.00012 3.9066 8.01361 3.75334 7.9241 3.65157Z"
                      fill="white"
                    />
                  </svg>
                </button>

                <div class="flex h-4 border rounded-lg px-1 mt-1 mb-2">
                  <input type="text" class="outline-none text-xs w-full pl-1" />
                  <svg
                    width="10"
                    height="12"
                    viewBox="0 0 7 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.6207 6.99305L4.95936 5.33034C5.35244 4.84823 5.58939 4.23465 5.58939 3.56491C5.58939 2.0241 4.33619 0.769531 2.79401 0.769531C1.25183 0.769531 0 2.0241 0 3.56491C0 5.10572 1.25319 6.35892 2.79538 6.35892C3.46375 6.35892 4.0787 6.12197 4.56081 5.72889L6.22215 7.39023C6.33582 7.50391 6.50839 7.50391 6.62207 7.39023C6.73575 7.27656 6.73575 7.10672 6.6207 6.99305ZM0.557432 3.56491C0.557432 2.33089 1.55999 1.32833 2.79401 1.32833C4.02803 1.32833 5.03195 2.33226 5.03195 3.56628C5.03195 4.8003 4.02803 5.80422 2.79401 5.80422C1.55999 5.80422 0.557432 4.79893 0.557432 3.56491Z"
                      fill="#C4C4C4"
                    />
                  </svg>
                </div>
              </th>
              <th
                class="
                  w-32
                  relative
                  text-sm text-left
                  box-border
                  px-2
                  border border-gray-300
                "
              >
                <span class="pr-4 cursor-pointer">{{$t('p-tab2-title')}}</span>
                <button>
                  <svg
                    class="absolute right-2 top-2"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="5" cy="5" r="5" fill="#C4C4C4" />
                    <path
                      d="M5.19328 5.99648C5.09151 5.90697 4.92599 5.92046 4.83648 6.02223L3.89239 7.08648V2.83315C3.89239 2.69338 3.77714 2.57812 3.63736 2.57812C3.49759 2.57812 3.38233 2.69338 3.38233 2.83315V7.08648L2.43824 6.02223C2.33647 5.92046 2.18321 5.90697 2.08145 5.99648C1.97968 6.09825 1.96619 6.25151 2.0557 6.35327L3.44609 7.90919C3.55031 8.03793 3.73422 8.01831 3.81637 7.90919L5.21903 6.35327C5.30853 6.25273 5.29627 6.08598 5.19328 5.99648Z"
                      fill="white"
                    />
                    <path
                      d="M7.9241 3.65157L6.54597 2.09566C6.42336 1.97182 6.2701 1.96446 6.16343 2.09566L4.76078 3.65157C4.67127 3.75334 4.68476 3.91886 4.78652 4.00836C4.94347 4.13343 5.10163 4.0476 5.14332 3.98262L6.08741 2.91837V7.15943C6.08741 7.29921 6.20267 7.41446 6.34244 7.41446C6.48222 7.41446 6.59747 7.31269 6.59747 7.17169V2.91959L7.54156 3.98384C7.64333 4.08561 7.79659 4.0991 7.89836 4.00959C8.00012 3.9066 8.01361 3.75334 7.9241 3.65157Z"
                      fill="white"
                    />
                  </svg>
                </button>

                <div class="flex h-4 border rounded-lg px-1 mt-1 mb-2">
                  <input type="text" class="outline-none text-xs w-full pl-1" />
                  <svg
                    width="10"
                    height="12"
                    viewBox="0 0 7 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.6207 6.99305L4.95936 5.33034C5.35244 4.84823 5.58939 4.23465 5.58939 3.56491C5.58939 2.0241 4.33619 0.769531 2.79401 0.769531C1.25183 0.769531 0 2.0241 0 3.56491C0 5.10572 1.25319 6.35892 2.79538 6.35892C3.46375 6.35892 4.0787 6.12197 4.56081 5.72889L6.22215 7.39023C6.33582 7.50391 6.50839 7.50391 6.62207 7.39023C6.73575 7.27656 6.73575 7.10672 6.6207 6.99305ZM0.557432 3.56491C0.557432 2.33089 1.55999 1.32833 2.79401 1.32833C4.02803 1.32833 5.03195 2.33226 5.03195 3.56628C5.03195 4.8003 4.02803 5.80422 2.79401 5.80422C1.55999 5.80422 0.557432 4.79893 0.557432 3.56491Z"
                      fill="#C4C4C4"
                    />
                  </svg>
                </div>
              </th>
              <th
                class="
                  w-32
                  relative
                  text-sm text-left
                  box-border
                  px-2
                  border border-gray-300
                "
              >
                <span class="pr-4 cursor-pointer">{{$t('p-tab2-title')}}</span>
                <button>
                  <svg
                    class="absolute right-2 top-2"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="5" cy="5" r="5" fill="#C4C4C4" />
                    <path
                      d="M5.19328 5.99648C5.09151 5.90697 4.92599 5.92046 4.83648 6.02223L3.89239 7.08648V2.83315C3.89239 2.69338 3.77714 2.57812 3.63736 2.57812C3.49759 2.57812 3.38233 2.69338 3.38233 2.83315V7.08648L2.43824 6.02223C2.33647 5.92046 2.18321 5.90697 2.08145 5.99648C1.97968 6.09825 1.96619 6.25151 2.0557 6.35327L3.44609 7.90919C3.55031 8.03793 3.73422 8.01831 3.81637 7.90919L5.21903 6.35327C5.30853 6.25273 5.29627 6.08598 5.19328 5.99648Z"
                      fill="white"
                    />
                    <path
                      d="M7.9241 3.65157L6.54597 2.09566C6.42336 1.97182 6.2701 1.96446 6.16343 2.09566L4.76078 3.65157C4.67127 3.75334 4.68476 3.91886 4.78652 4.00836C4.94347 4.13343 5.10163 4.0476 5.14332 3.98262L6.08741 2.91837V7.15943C6.08741 7.29921 6.20267 7.41446 6.34244 7.41446C6.48222 7.41446 6.59747 7.31269 6.59747 7.17169V2.91959L7.54156 3.98384C7.64333 4.08561 7.79659 4.0991 7.89836 4.00959C8.00012 3.9066 8.01361 3.75334 7.9241 3.65157Z"
                      fill="white"
                    />
                  </svg>
                </button>

                <div class="flex h-4 border rounded-lg px-1 mt-1 mb-2">
                  <input type="text" class="outline-none text-xs w-full pl-1" />
                  <svg
                    width="10"
                    height="12"
                    viewBox="0 0 7 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.6207 6.99305L4.95936 5.33034C5.35244 4.84823 5.58939 4.23465 5.58939 3.56491C5.58939 2.0241 4.33619 0.769531 2.79401 0.769531C1.25183 0.769531 0 2.0241 0 3.56491C0 5.10572 1.25319 6.35892 2.79538 6.35892C3.46375 6.35892 4.0787 6.12197 4.56081 5.72889L6.22215 7.39023C6.33582 7.50391 6.50839 7.50391 6.62207 7.39023C6.73575 7.27656 6.73575 7.10672 6.6207 6.99305ZM0.557432 3.56491C0.557432 2.33089 1.55999 1.32833 2.79401 1.32833C4.02803 1.32833 5.03195 2.33226 5.03195 3.56628C5.03195 4.8003 4.02803 5.80422 2.79401 5.80422C1.55999 5.80422 0.557432 4.79893 0.557432 3.56491Z"
                      fill="#C4C4C4"
                    />
                  </svg>
                </div>
              </th>
            </tr>
            </thead>
<tbody>
            <!-- Table data  -->
            <tr v-for="(manageJob, k) in manageJobData" :key="k">
              <td
                class="
                  border border-gray-200
                  text-xs text-center
                  flex
                  justify-between
                  py-6
                  px-5
                "
              >
                <button>
                  <svg
                    width="12"
                    height="9"
                    viewBox="0 0 12 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.9134 4.5443C10.8216 2.414 8.66009 0.972656 6.1851 0.972656C3.71011 0.972656 1.548 2.415 0.456772 4.5445C0.410715 4.6356 0.386719 4.73626 0.386719 4.83834C0.386719 4.94043 0.410715 5.04108 0.456772 5.13219C1.5486 7.26249 3.71011 8.70383 6.1851 8.70383C8.66009 8.70383 10.8222 7.26148 11.9134 5.13199C11.9595 5.04088 11.9835 4.94023 11.9835 4.83814C11.9835 4.73606 11.9595 4.6354 11.9134 4.5443ZM6.1851 7.73743C5.61169 7.73743 5.05116 7.5674 4.57439 7.24883C4.09762 6.93026 3.72603 6.47747 3.50659 5.94772C3.28716 5.41796 3.22975 4.83503 3.34161 4.27264C3.45348 3.71025 3.7296 3.19367 4.13506 2.78821C4.54052 2.38275 5.05711 2.10663 5.61949 1.99476C6.18188 1.88289 6.76482 1.94031 7.29457 2.15974C7.82433 2.37917 8.27713 2.75077 8.59569 3.22754C8.91426 3.70431 9.0843 4.26484 9.0843 4.83824C9.08448 5.21902 9.00962 5.59611 8.86399 5.94793C8.71835 6.29976 8.50481 6.61944 8.23555 6.88869C7.9663 7.15795 7.64662 7.37149 7.29479 7.51712C6.94296 7.66276 6.56588 7.73762 6.1851 7.73743ZM6.1851 2.90545C6.01258 2.90786 5.84118 2.93353 5.67553 2.98175C5.81207 3.16732 5.8776 3.39567 5.86022 3.6254C5.84284 3.85514 5.74371 4.07103 5.5808 4.23394C5.41789 4.39685 5.20199 4.49599 4.97226 4.51337C4.74253 4.53074 4.51417 4.46522 4.32861 4.32867C4.22294 4.71796 4.24202 5.13059 4.38315 5.50848C4.52428 5.88637 4.78035 6.21048 5.11534 6.43521C5.45032 6.65993 5.84735 6.77395 6.25053 6.76121C6.65371 6.74848 7.04274 6.60963 7.36287 6.36421C7.68301 6.11879 7.91812 5.77915 8.03512 5.39311C8.15211 5.00707 8.1451 4.59406 8.01508 4.21221C7.88505 3.83037 7.63855 3.49891 7.31028 3.26449C6.982 3.03007 6.58848 2.9045 6.1851 2.90545Z"
                      fill="#ED1B23"
                    />
                  </svg>
                </button>

                <button>
                  <svg
                    width="11"
                    height="10"
                    viewBox="0 0 11 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0775 3.38539L9.20733 4.16855C9.11862 4.24839 8.97516 4.24839 8.88644 4.16855L6.79123 2.28286C6.70251 2.20302 6.70251 2.07391 6.79123 1.99407L7.6614 1.21091C8.01438 0.893237 8.58821 0.893237 8.94307 1.21091L10.0775 2.2319C10.4324 2.54958 10.4324 3.06602 10.0775 3.38539ZM6.04375 2.6668L1.08696 7.12788L0.686791 9.19193C0.632051 9.47054 0.901975 9.71177 1.21154 9.6642L3.50495 9.30236L8.46174 4.84128C8.55045 4.76143 8.55045 4.63232 8.46174 4.55248L6.36652 2.6668C6.27592 2.58695 6.13246 2.58695 6.04375 2.6668ZM3.02173 6.74564C2.91791 6.65221 2.91791 6.50271 3.02173 6.40928L5.9286 3.79311C6.03242 3.69967 6.19853 3.69967 6.30234 3.79311C6.40616 3.88654 6.40616 4.03604 6.30234 4.12947L3.39547 6.74564C3.29165 6.83908 3.12555 6.83908 3.02173 6.74564ZM2.34031 8.17434H3.24635V8.79101L2.02886 8.98298L1.44182 8.45465L1.65512 7.35891H2.34031V8.17434Z"
                      fill="#ED1B23"
                    />
                  </svg>
                </button>

                <button>
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.36155 1.51626H7.03185L6.84935 1.19859C6.81069 1.13068 6.75115 1.07355 6.6774 1.03364C6.60366 0.993723 6.51866 0.972605 6.43195 0.972659H4.2129C4.12639 0.972368 4.04153 0.993407 3.96805 1.03337C3.89457 1.07332 3.83544 1.13059 3.79744 1.19859L3.61494 1.51626H1.28524C1.20285 1.51626 1.12384 1.54489 1.06559 1.59587C1.00734 1.64684 0.974609 1.71597 0.974609 1.78806L0.974609 2.33165C0.974609 2.40374 1.00734 2.47287 1.06559 2.52385C1.12384 2.57482 1.20285 2.60345 1.28524 2.60345H9.36155C9.44394 2.60345 9.52295 2.57482 9.5812 2.52385C9.63945 2.47287 9.67218 2.40374 9.67218 2.33165V1.78806C9.67218 1.71597 9.63945 1.64684 9.5812 1.59587C9.52295 1.54489 9.44394 1.51626 9.36155 1.51626ZM2.00745 8.90579C2.02226 9.1128 2.12668 9.30709 2.29945 9.44911C2.47221 9.59113 2.70034 9.6702 2.93739 9.67023H7.7094C7.94645 9.6702 8.17458 9.59113 8.34734 9.44911C8.52011 9.30709 8.62453 9.1128 8.63934 8.90579L9.05093 3.14705H1.59586L2.00745 8.90579Z"
                      fill="#ED1B23"
                    />
                  </svg>
                </button>
              </td>
              <td class="w-32 border border-gray-200 text-xs text-center p-3">
                {{ manageJob.position }}
              </td>
              <td class="w-32 border border-gray-200 text-xs text-center p-3">
                {{ manageJob.jobTitle }}
              </td>
              <td class="w-32 border border-gray-200 text-xs text-center p-3">
                {{ manageJob.postedDate }}
              </td>
              <td class="w-32 border border-gray-200 text-xs text-center p-3">
                {{ manageJob.closingDate }}
              </td>
              <td class="w-32 border border-gray-200 text-xs text-center p-3">
                {{ manageJob.country }}
              </td>
              <td class="w-32 border border-gray-200 text-xs text-center p-3">
                {{ manageJob.education }}
              </td>
              <td class="w-32 border border-gray-200 text-xs text-center p-3">
                {{ manageJob.education }}
              </td>
              <td class="w-32 border border-gray-200 text-xs text-center p-3">
                {{ manageJob.education }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- pagination section  -->
      <div class="mx-4 py-2 my-4 flex justify-between">
        <div class="flex justify-between w-40 self-center ml-48">
          <span class="text-xs">{{$t('ro-txt6')}}</span>
          <span class="text-xs">1</span>
          <span class="text-xs">to</span>
          <span class="text-xs">10</span>
          <span class="text-xs">{{$t('ro-txt8')}}</span>
          <span class="text-xs">30</span>
          <span class="text-xs">{{$t('ejl-txt3')}}</span>
        </div>

        <div class="flex justify-between box-border">
          <button
            type="button"
            class="cursor-not-allowed text-xs self-center mr-1"
            disabled
          >
           {{$t('ro-txt11')}}
          </button>
          <button
            class="text-xs border border-gray-300 rounded-full px-4 py-2 mx-1"
          >
            1
          </button>
          <button
            class="text-xs border border-gray-300 rounded-full px-4 py-2 mx-1"
          >
            2
          </button>
          <button
            class="text-xs border border-gray-300 rounded-full px-4 py-2 mx-1"
          >
            3
          </button>
          <button
            class="text-xs border border-gray-300 rounded-full px-4 py-2 mx-1"
          >
            4
          </button>
          <button class="text-xs self-center ml-1">{{$t('p-tbl4-button2')}}</button>
        </div>
      </div>
    </div>
  </div>
  <!-- <div v-click-outside
="externalClick">

      Hello, I am the div to hide!
  
</div> -->
</template>

<script>
import VueMultiselect from "vue-multiselect";
import ProfileJobSidebar from "../components/ProfileJobSidebar.vue";
// import VueClosable from 'vue-closable';
// app.use(VueClosable)
export default {
  name: "AddNewJobContainer",
  el: "#showModal",
  data: function () {
    return {
      roleModelBox: false,
      // isShow: false,
      isColumn: false,
      // isFilter: false,
      show: false,
      sidebarview: false,
      isOpen: false,

      manageJobData: [
        {
          id: "1",
          position: "Sr. Developer",
          jobTitle: "Developer",
          postedDate: "07/01/2022",
          closingDate: "12/02/2022",
          country: "India",
          education: "B.E",
        },
        {
          id: "2",
          position: "Asst. Manager",
          jobTitle: "Manager",
          postedDate: "07/01/2022",
          closingDate: "15/02/2022",
          country: "Australia",
          education: "B.Tech",
        },
        {
          id: "3",
          position: "Team leader",
          jobTitle: "Software Developer",
          postedDate: "07/01/2022",
          closingDate: "20/02/2022",
          country: "India",
          education: "BCA",
        },
        {
          id: "4",
          position: "Accountant",
          jobTitle: "Accountant",
          postedDate: "07/01/2022",
          closingDate: "12/02/2022",
          country: "pakistan",
          education: "BSC",
        },
        {
          id: "5",
          position: "Security incharge",
          jobTitle: "incharge",
          postedDate: "07/01/2022",
          closingDate: "12/02/2022",
          country: "USA",
          education: "B.E",
        },
      ],
    };
  },
  methods: {
    openSidebar() {
      this.sidebarview = true;
    },
    closeSidebar() {
      this.sidebarview = false;
    },
    toggle() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.showmodal();
      } else {
        this.hidemodal();
      }
    },
    showmodal() {
      document.querySelector(".modal").classList.add("visible");
      this.isOpen = true;
    },
    hidemodal() {
      document.querySelector(".modal").classList.remove("visible");
      this.isOpen = false;
    },
    addColumn() {
      this.isColumn = !this.isColumn;
      if (this.isColumn) {
        this.showColumn();
      } else {
        this.hideColumn();
      }
    },
    showColumn() {
      document.querySelector(".addcolumn").classList.add("visible");
      this.isColumn = true;
    },
    hideColumn() {
      document.querySelector(".addcolumn").classList.remove("visible");
      this.isColumn = false;
    },
    clickoutside() {
      window.addEventListener("mousedown", (event) => {
        if (!event.target.closest(".modal,.js-open-modal")) {
          document
            .getElementsByClassName("modal visible")[0]
            .classList.remove("visible");
          this.isOpen = false;
        }
      });
    },

    draftJobs() {
      this.hidemodal();
    },
    publishedJobs() {
      this.hidemodal();
    },
    closedJobs() {
      this.hidemodal();
    },
  },
  components: {
    VueMultiselect,
    ProfileJobSidebar,
  },

  props: {},

  beforeMount() {},
};
</script>

<style>
.table_main {
  border-collapse: separate;
  border-spacing: 0px;
  border: solid #b9b9b9 0.5px;
  border-radius: 10px;
  /* width: 47rem; */
  /* overflow: hidden; */
}
.modal {
  z-index: 100;
  display: none;
  position: absolute;
}
.addcolumn {
  z-index: 100;
  display: none;
}
.visible {
  display: block;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  opacity: 0;
  background: black;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
