<template>

  <div class="box-border border-t-2 border-red-300  h-auto space-y-8 lg:space-y-6 overflow-auto">  
                    <!-- ============ profile search tabs ============ --> 
    <div>
        <button  class="mt-6 w-full border-2 border-gray-400  h-14 lg:h-10 rounded-full text-left text-black active:text-white focus:text-white hover:text-white hover:bg-gray-700 active:bg-gray-700 focus:bg-gray-700 focus:outline-none text-base lg:text-sm font-medium text-gray-700 pl-3 "> Dashboard
        </button>
    </div>
    <div>
        <button  class="mt-1 w-full border-2 border-gray-400 h-14 lg:h-10 rounded-full text-left text-black active:text-white focus:text-white hover:text-white hover:bg-gray-700 active:bg-gray-700 focus:bg-gray-700 focus:outline-none text-base lg:text-sm font-medium text-gray-700 pl-3">Manage Jobs 
        </button>
    </div>
    <div>
        <button  class="mt-1 w-full border-2 border-gray-400 h-14 lg:h-10 rounded-full text-left text-black active:text-white focus:text-white hover:text-white hover:bg-gray-700 active:bg-gray-700 focus:bg-gray-700 focus:outline-none text-base lg:text-sm font-medium text-gray-700 pl-3">Job fare
        </button>
    </div>
    <div>
        <button  class="mt-1 w-full border-2 border-gray-400  h-14 lg:h-10 rounded-full text-left text-black active:text-white focus:text-white hover:text-white hover:bg-gray-700 active:bg-gray-700 focus:bg-gray-700 focus:outline-none text-base lg:text-sm font-medium text-gray-700 pl-3">Payment History
        </button> 
    </div>
    <div>
        <button  class="mt-1 w-full border-2 border-gray-400 h-14 lg:h-10 rounded-full text-left text-black active:text-white focus:text-white hover:text-white hover:bg-gray-700 active:bg-gray-700 focus:bg-gray-700 focus:outline-none text-base lg:text-sm font-medium text-gray-700 pl-3">Account Details
        </button>
    </div>
    <div>
        <button class="mt-1 w-full border-2 border-gray-400 h-14 lg:h-10 rounded-full text-left text-black active:text-white focus:text-white hover:text-white hover:bg-gray-700 active:bg-gray-700 focus:bg-gray-700 focus:outline-none text-base lg:text-sm font-medium text-gray-700 pl-3">All aplications
        </button>
    </div>
    <div>
        <button  class="mt-1 w-full border-2 border-gray-400 h-14 lg:h-10 rounded-full text-left text-black active:text-white focus:text-white hover:text-white hover:bg-gray-700 active:bg-gray-700 focus:bg-gray-700 focus:outline-none text-base lg:text-sm font-medium text-gray-700 pl-3">Candidates
        </button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  data: function() {
    return {

    }
  },
  components: {

  }
};
</script>


