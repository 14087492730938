import store from '../store/store.js'

const directive = {
  mounted: function (el, binding, vnode, prevVnode) {
    if (binding.value) {
      store.dispatch('auth/fetchUser').then((res) => {
        let permissions = store.getters['auth/user']['data']['details']['roles'][0]['permissions'].map(a => a.name)
        const hasPermission = permissions.some(element => {
          let yes = false; 
          if (element.includes(binding.value)) {
            yes = true;
          }
          return yes
        });
        if (!hasPermission) {
          el.parentNode.removeChild(el)
        }
      })
      
    } else {
      console.error('You must specify a permission slug')
    }
  }
}

export default directive