export default [
	{
        path: '/job-seeker',
        component: () => import('../components/JobSeeker.vue'),
        name: 'jobSeekerHome',
        // meta: { 
        //     guest: true,
        //     needsAuth: false,        
        // }
    },
	{
        path: '/employer',
        component: () => import('../components/Employer.vue'),
        name: 'employerHome',
        // meta: { 
        //     guest: true,
        //     needsAuth: false,        
        // }
    },
	{
        path: '/titp',
        component: () => import('../components/Titp.vue'),
        name: 'titpHome',
        // meta: { 
        //     guest: true,
        //     needsAuth: false,        
        // }
    },
	{
        path: '/job-fair',
        component: () => import('../components/JobFair.vue'),
        name: 'jobFairHome',
        // meta: { 
        //     guest: true,
        //     needsAuth: false,        
        // }
    },
	{
        path: '/advertisements',
        component: () => import('../components/Advertisements.vue'),
        name: 'AdvertisementsHome',
        // meta: { 
        //     guest: true,
        //     needsAuth: false,        
        // }
    },
	{
        path: '/outsourcing-tender',
        component: () => import('../components/OutsourcingTender.vue'),
        name: 'outsourcingTender',
        // meta: { 
        //     guest: true,
        //     needsAuth: false,        
        // }
    },
	{
        path: '/dashboard-home',
        component: () => import('../components/Dashboard.vue'),
        name: 'dashboardHome',
        // meta: { 
        //     guest: true,
        //     needsAuth: false,        
        // }
    },
    
]