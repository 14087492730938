export default {
	search: {
		skill:"",
		location:"",
		job_type:"",
		last_updated:"",
		search_category:"",
	},
	

	
}

