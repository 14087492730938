export const showAddNewCandidateModel = (state, flag) => {
	state.showAddNewCandidateModel = flag
}

export const showViewCandidate = (state, {flag, value}) => {
    state.showViewCandidate = flag
    state.candidateViewId = value  
}

export const showDeleteCandidate = (state, {flag, value} ) => {
    state.showDeleteCandidate = flag
    state.candidateDelete = value
}


export const setCandidatesList = (state, data) => {
	state.candidateList.data = data.data
    state.candidateList.links = data.links
    state.candidateList.meta = data.meta
}
