export const addCandidate = ({ commit  }, {context, payload }) => {
    return window.axios.post (process.env.VUE_APP_SMART_RECRUIT_API_URL +'api/my-candidate', payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit("SET_ALERT_MESSAGE", response.data.message, {root: true});
        return response.data.success
    }).catch((error) => {
       	commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}

export const fetchCandidateList = ({ commit, dispatch }, query = '') => {
    // return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/list-candidates' + query).then((response) => {  
    //     // commit('setAuthenticated', true);
    //     commit('setCandidatesList', response.data);
    //     return response

    // })
}

export const fetchCandidateById = ({commit}, id) => {
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + `api/candidatebyid/${id}`).then((response) => {            
        return response.data
    })
}

export const deleteCandidate = ({ commit, state, dispatch }, id) => {
    id = state.candidateDelete
    return window.axios.delete (process.env.VUE_APP_SMART_RECRUIT_API_URL + `api/candidate-delete/${id}`).then((response) => {
        commit('setAuthenticated', true);
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        dispatch("fetchCandidateList")
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true })
        context.errors = error.response.data
        return false
    })
}
