export const SET_JOBS = (state, data) => {
    state.jobs = data
}
export const setJobSearchParam = (state, {skill, location, job_type, last_updated, search_category}) => {
    state.search.skill = skill;
    state.search.location = location;
    state.search.job_type = job_type;
    state.search.last_updated = last_updated;
    state.search.search_category = search_category;
} 

export const setEmpById = (state, data) => {
    state.emp = data;
};
export const SET_EMP_JOBS = (state, data) => {
    state.empJobs.data = data.data
}
export const setClientTimezone = (state, data) => {
    state.client.timezone = data.timezone;
   }