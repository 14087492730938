import localforage from 'localforage'
import { isEmpty } from 'lodash'

const IS_LOADING = (state, flag) => {
	state.isLoading = flag
}

const SET_LOADER_MESSAGE = (state, value) => {
	state.loaderMessage = value
}

const UNSET_ALERT = (state, alertName) => {
	state.alerts[alertName] = false
}

const SET_ALERT = (state, alertName) => {
	// Reset all alerts
	Object.entries(state.alerts).forEach((key, value) => {
		state.alerts[key] = false
	})
	state.alerts[alertName] = true

	// Fadeout the alert in configured time
	setTimeout(function() {
		state.alerts[alertName] = false
    }, window.alertDuration);
}

const SET_ALERT_MESSAGE = (state, message) => {
	state.alerts.message = message
}

const HANDLE_MAIN_HEADER_BASED_ON_AUTH = (state) => {
	return localforage.getItem('authtoken').then((token) => {
        if (token) {
            // state.headers.mainHeaderLinks[8].show = true;
	 		// state.headers.mainHeaderLinks[9].show = false;
        }else{
			// state.headers.mainHeaderLinks[8].show = true;
			// state.headers.mainHeaderLinks[9].show = true;
		}

    })
}

const feedback_review = (state, data) => {
	state.feedback.feedbackCategory = data
}

const SET_CITY_DATA = (state, data) => {
    state.cities = data
}

const SET_COUNTRY_DATA = (state, data) => {
    state.countries = data
}

const SET_SIDEBAR = (state, data) => {
    state.sidebar = data
}

const DECIDE_TO_SHOW_SIDEBARLINKS_OR_NOT = (state, flag) => {
    state.showSidebar = flag
}

const SET_USER_BRIEF = (state, value) => {
	let location = ''
	if (value.data.city != null)
		location += value.data.city + ' | '
	if (value.data.state)
		location += value.data.state + ' | '
	if (value.data.country)
		location += value.data.country
	state.userBrief.login_date = new Date(value.data.last_login).toDateString()
	state.userBrief.login_time = new Date(value.data.last_login).toTimeString()
	state.userBrief.role = value.data.sso_user_role
	state.userBrief.exp = value.data.total_years_exp
	state.userBrief.location 
	state.userBrief.avail = (value.data.status == 1) ? 'Immediate' : 'NA'
	state.userBrief.industry = value.data.industry_name
	state.userBrief.membership_plan = value.data.memb_plan_name
	state.userBrief.membership_expiry = value.data.membership_exp
}

 const setLanguageList = (state, data) => {
	state.languageList = data
	
}
const setMemberShipPlan = (state, data) => {
	localStorage.setItem('membership_plan',data);
	state.membershipPlan = data;
}
const setMemberShipPlanDate = (state, data) => {
	localStorage.setItem('membership_plan_tille_date',data);
	state.membershipPlanData = data;
}
const setMemberShipPlanCurrentStepNo = (state, data) => {
	localStorage.setItem('currentStepNo',data);
}
export default {
	IS_LOADING,
	SET_LOADER_MESSAGE,
	UNSET_ALERT,
	SET_ALERT,
	SET_ALERT_MESSAGE,
	HANDLE_MAIN_HEADER_BASED_ON_AUTH,
	SET_CITY_DATA,
	SET_COUNTRY_DATA,
	SET_SIDEBAR,
	DECIDE_TO_SHOW_SIDEBARLINKS_OR_NOT,
	SET_USER_BRIEF,
	isAuthenticated(state, flag) {
		state.isAuth = flag
	},
	changeLanguageSlug(state, locale) {
		state.locale = locale
		localforage.setItem('locale', locale);
		window.location = window.location.href
 	},
	setMemberShipPlan,
	setMemberShipPlanDate,
	setMemberShipPlanCurrentStepNo
}
