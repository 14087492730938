<template>
    <div>
        <div class="">
            <div class="relative ">
                <img src="/images/footer-02.webp" class="w-full h-60 md:h-full">
                <div class="absolute inset-0">
                    <div class="flex xl:px-20 2xl:px-32">
                        <div class="w-3/12 md:w-2/12 2xl:w-3/12 mx-auto">
                            <div class="my-10 md:my-8 lg:my-16 2xl:my-24">
                                <img src="/images/smartrecruit.webp" class="rounded-full md:pr-0 md:pt-0 xl:pr-8 md:ml-8 xl:ml-0 w-96">
                            </div>
                        </div>
                        <div class="w-6/12 md:w-8/12 2xl:w-9/12 px-4">
                            <div class="flex justify-between md:mx-24 lg:mx-32 xl:mx-24 text-small lg:text-sm 2xl:text-lg my-2 lg:my-6 xl:my-8 2xl:my-16">
                                <div class="space-y-2 md:space-y-4">
                                    <div><router-link to="about-us" class="text-white hover:text-red-400 cursor-pointer"><!-- About Us -->{{$t('foot-col1-text1')}}</router-link></div>
                                    <div><a href="#contactUs" class="text-white hover:text-red-400 cursor-pointer"><!-- Contact Us -->{{$t('sec12-col2-cu')}}</a></div>
                                    <div><router-link to="select-role" v-if="!setAuth" class="text-white hover:text-red-400 cursor-pointer"><!-- Register Now -->{{$t('foot-col2-text1')}}</router-link></div>
                                    <div><router-link to="sign-in" v-if="!setAuth" class="text-white hover:text-red-400 cursor-pointer"><!-- Login -->{{$t('foot-col2-text3')}}</router-link></div>
                                    <div><router-link :to="{name:'advanced-search-job'}" class="text-white hover:text-red-400 cursor-pointer"><!-- Explore Top 100 Companies -->{{$t('explore-top-100-companies')}}</router-link></div>
                                    <div><router-link to="employer" class="text-white hover:text-red-400 cursor-pointer"><!-- Hire Top Talents -->{{$t('hire-top-talents')}}</router-link></div>                            
                                </div>
                                <div class="space-y-2 md:space-y-4">                            
                                    <div><router-link :to="{name:'advanced-search-job'}" class="text-white hover:text-red-400 cursor-pointer"><!-- Detail Search -->{{$t('detail-search')}}</router-link></div>
                                    <div><router-link :to="{name:'advanced-search-job'}" class="text-white hover:text-red-400 cursor-pointer"><!-- Search -->{{$t('foot-col2-text3')}}</router-link></div>
                                    <div><router-link :to="{name:'AdvertisementsHome'}" class="text-white hover:text-red-400 cursor-pointer"><!-- Publish Advertisement -->{{$t('publish-advertisement')}}</router-link></div>
                                    <div><router-link :to="{name:'jobFairHome'}" class="text-white hover:text-red-400 cursor-pointer"><!-- Attend Job Fair -->{{$t('attend-job-fair')}}</router-link></div>
                                    <div><router-link :to="{name:'outsourcingTender'}" class="text-white hover:text-red-400 cursor-pointer"><!-- Outsource Projects -->{{$t('outsource-projects')}}</router-link></div>
                                    <div><router-link :to="{name:'relocationServiceHome'}" class="text-white hover:text-red-400 cursor-pointer"><!-- Explore Relocation Services -->{{$t('explore-relocation-services')}}</router-link></div>
                                </div> 
                                <div class="hidden md:block space-y-4">
                                    <div><label class="text-white" >{{$t('foot-col3-text1')}}</label></div>
                                    <div class="xl:flex gap-2 space-y-2 xl:space-y-0">
                                        <div class="flex items-center">
                                            <router-link to="#" class="">
                                                <svg class="w-5" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.78125 2.14904C0.78125 0.962779 1.77428 0 2.99854 0H28.564C29.7886 0 30.7813 0.962779 30.7813 2.14904V27.8514C30.7813 29.038 29.7886 30 28.564 30H2.99854C1.77428 30 0.78125 29.038 0.78125 27.8517V2.14865V2.14904Z" fill="#3B5998"/><path d="M10.5295 24.9996V11.5051H6.11949V24.9996H10.5299H10.5295ZM8.32527 9.66293C9.86283 9.66293 10.8201 8.62664 10.8201 7.33166C10.7912 6.00729 9.86283 5 8.35455 5C6.84511 5 5.85938 6.00729 5.85938 7.33166C5.85938 8.62664 6.81621 9.66293 8.29636 9.66293H8.32488H8.32527ZM12.9703 24.9996H17.38V17.4645C17.38 17.0616 17.4089 16.6579 17.5252 16.3702C17.8439 15.564 18.5696 14.7296 19.7881 14.7296C21.3834 14.7296 22.022 15.9669 22.022 17.7812V24.9996H26.4316V17.2623C26.4316 13.1175 24.2562 11.1888 21.3549 11.1888C18.9761 11.1888 17.9314 12.541 17.3511 13.462H17.3804V11.5054H12.9707C13.0281 12.7714 12.9703 25 12.9703 25V24.9996Z" fill="white"/>
                                                </svg>

                                            </router-link>
                                        </div>
                                        <div class="flex items-center">
                                            <router-link to="#" class="">
                                                <svg class="w-5" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="0.5" width="30" height="30" rx="5" fill="#FF0000"/><path fill-rule="evenodd" clip-rule="evenodd" d="M25.0395 8.64839C25.3721 8.98097 25.6117 9.39488 25.7346 9.8489C26.442 12.6964 26.2785 17.1937 25.7483 20.1511C25.6254 20.6051 25.3858 21.019 25.0532 21.3516C24.7206 21.6842 24.3067 21.9238 23.8527 22.0467C22.1906 22.5 15.501 22.5 15.501 22.5C15.501 22.5 8.81149 22.5 7.1494 22.0467C6.69538 21.9238 6.28147 21.6842 5.94889 21.3516C5.6163 21.019 5.37665 20.6051 5.2538 20.1511C4.54226 17.3159 4.73731 12.8159 5.24006 9.86264C5.36291 9.40862 5.60257 8.99471 5.93515 8.66212C6.26774 8.32954 6.68165 8.08989 7.13566 7.96704C8.79775 7.51373 15.4873 7.5 15.4873 7.5C15.4873 7.5 22.1769 7.5 23.839 7.95329C24.293 8.07615 24.7069 8.31581 25.0395 8.64839ZM18.9076 15L13.3582 18.2143V11.7857L18.9076 15Z" fill="white"/>
                                                </svg>
                                            </router-link>
                                        </div>
                                        <div class="flex items-center">
                                            <router-link to="#" class="">
                                                <svg class="w-5" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="0.5" width="30" height="30" rx="5" fill="#3B5998"/><path fill-rule="evenodd" clip-rule="evenodd" d="M18.8259 10.1985C18.4009 10.1135 17.8269 10.05 17.4659 10.05C16.4884 10.05 16.425 10.475 16.425 11.155V12.3655H18.8684L18.6554 14.873H16.425V22.5H13.3655V14.873H11.793V12.3655H13.3655V10.8145C13.3655 8.69 14.364 7.5 16.871 7.5C17.742 7.5 18.3794 7.6275 19.2079 7.7975L18.8259 10.1985Z" fill="white"/>
                                                </svg>
                                            </router-link>
                                        </div>
                                        <div class="flex items-center">
                                            <router-link to="#" class="">
                                                <svg fill="none" class="w-5" viewBox="0 0 31 30">
                                                    <rect width="30" height="30" x=".5" fill="#C13584" rx="5"/>
                                                    <path fill="#fff" d="M15.5 8.85135c2.0026 0 2.2398.00765 3.0307.04373.7312.03335 1.1283.15553 1.3926.25824.3501.13605.5999.29858.8624.56101.2624.26246.425.51227.561.86237.1027.2643.2249.6614.2583 1.3927.036.7908.0437 1.028.0437 3.0306s-.0077 2.2398-.0437 3.0306c-.0334.7313-.1556 1.1284-.2583 1.3927-.136.3501-.2986.5999-.561.8623-.2625.2625-.5123.425-.8624.5611-.2643.1027-.6614.2249-1.3926.2582-.7907.0361-1.0279.0438-3.0307.0438-2.0027 0-2.2399-.0077-3.0306-.0438-.7313-.0333-1.1284-.1555-1.3927-.2582-.3501-.1361-.5999-.2986-.8623-.5611-.26247-.2624-.42502-.5122-.56108-.8623-.10271-.2643-.22489-.6614-.25823-1.3927-.03609-.7908-.04374-1.028-.04374-3.0306s.00765-2.2398.04374-3.0306c.03334-.7313.15552-1.1284.25823-1.3927.13606-.3501.29858-.59991.56108-.86234.2624-.26246.5122-.42499.8623-.56104.2643-.10271.6614-.22489 1.3927-.25824.7908-.03608 1.028-.04373 3.0306-.04373ZM15.5 7.5c-2.0369 0-2.2923.00863-3.0922.04513-.7983.03641-1.3435.16321-1.8206.3486-.4932.19166-.91143.44811-1.32841.86506-.41694.41698-.67339.83523-.86503 1.32841-.18542.4771-.31222 1.0223-.34863 1.8206C8.00863 12.7077 8 12.9631 8 15s.00863 2.2923.04513 3.0922c.03641.7983.16321 1.3435.34863 1.8206.19164.4932.44809.9114.86503 1.3284.41698.417.83521.6734 1.32841.865.4771.1855 1.0223.3123 1.8206.3487.7999.0365 1.0553.0451 3.0922.0451s2.2923-.0086 3.0923-.0451c.7983-.0364 1.3435-.1632 1.8205-.3487.4932-.1916.9115-.448 1.3285-.865.4169-.417.6734-.8352.865-1.3284.1854-.4771.3122-1.0223.3486-1.8206.0365-.7999.0452-1.0553.0452-3.0922s-.0087-2.2923-.0452-3.0922c-.0364-.7983-.1632-1.3435-.3486-1.8206-.1916-.49318-.4481-.91143-.865-1.32841-.417-.41695-.8353-.6734-1.3285-.86506-.477-.18539-1.0222-.31219-1.8205-.3486-.8-.0365-1.0554-.04513-3.0923-.04513Z"/>
                                                    <path fill="#fff" d="M15.5037 11.1523c-2.1271 0-3.8514 1.7243-3.8514 3.8514 0 2.127 1.7243 3.8513 3.8514 3.8513s3.8514-1.7243 3.8514-3.8513c0-2.1271-1.7243-3.8514-3.8514-3.8514Zm0 6.3514c-1.3807 0-2.5-1.1193-2.5-2.5s1.1193-2.5 2.5-2.5 2.5 1.1193 2.5 2.5-1.1193 2.5-2.5 2.5Zm4.9018-6.506c0 .497-.403.9-.9.9-.4971 0-.9-.403-.9-.9 0-.4971.4029-.9.9-.9.497 0 .9.4029.9.9Z"/>
                                                </svg>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </div>

                        <div class="w-3/12 md:w-2/12 2xl:w-3/12">
                            <div class="md:space-y-0 lg:space-y-0 2xl:space-y-2 my-2 md:my-0 lg:my-0 xl:my-6 2xl:my-28">
                                <div class="flex justify-center">
                                    <img src="/images/jiipa.png" class="w-10 md:w-16 lg:w-auto 2xl:w-auto">
                                </div>
                                <div class="flex justify-center">
                                    <img src="/images/hand.png" class="md:w-16 lg:w-auto 2xl:w-auto">
                                </div>
                                <div class="flex justify-center">
                                    <img src="/images/sekailogo.webp" class="">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="md:hidden block space-2-4 flex flex-col justify-center items-center">
                        <div><label class="text-white" >{{$t('foot-col3-text1')}}</label></div>
                        <div class="flex gap-2">
                            <div class="flex items-center">
                                <router-link to="#" class="">
                                    <svg class="w-5" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.78125 2.14904C0.78125 0.962779 1.77428 0 2.99854 0H28.564C29.7886 0 30.7813 0.962779 30.7813 2.14904V27.8514C30.7813 29.038 29.7886 30 28.564 30H2.99854C1.77428 30 0.78125 29.038 0.78125 27.8517V2.14865V2.14904Z" fill="#3B5998"/><path d="M10.5295 24.9996V11.5051H6.11949V24.9996H10.5299H10.5295ZM8.32527 9.66293C9.86283 9.66293 10.8201 8.62664 10.8201 7.33166C10.7912 6.00729 9.86283 5 8.35455 5C6.84511 5 5.85938 6.00729 5.85938 7.33166C5.85938 8.62664 6.81621 9.66293 8.29636 9.66293H8.32488H8.32527ZM12.9703 24.9996H17.38V17.4645C17.38 17.0616 17.4089 16.6579 17.5252 16.3702C17.8439 15.564 18.5696 14.7296 19.7881 14.7296C21.3834 14.7296 22.022 15.9669 22.022 17.7812V24.9996H26.4316V17.2623C26.4316 13.1175 24.2562 11.1888 21.3549 11.1888C18.9761 11.1888 17.9314 12.541 17.3511 13.462H17.3804V11.5054H12.9707C13.0281 12.7714 12.9703 25 12.9703 25V24.9996Z" fill="white"/>
                                    </svg>

                                </router-link>
                            </div>
                            <div class="flex items-center">
                                <router-link to="#" class="">
                                    <svg class="w-5" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" width="30" height="30" rx="5" fill="#FF0000"/><path fill-rule="evenodd" clip-rule="evenodd" d="M25.0395 8.64839C25.3721 8.98097 25.6117 9.39488 25.7346 9.8489C26.442 12.6964 26.2785 17.1937 25.7483 20.1511C25.6254 20.6051 25.3858 21.019 25.0532 21.3516C24.7206 21.6842 24.3067 21.9238 23.8527 22.0467C22.1906 22.5 15.501 22.5 15.501 22.5C15.501 22.5 8.81149 22.5 7.1494 22.0467C6.69538 21.9238 6.28147 21.6842 5.94889 21.3516C5.6163 21.019 5.37665 20.6051 5.2538 20.1511C4.54226 17.3159 4.73731 12.8159 5.24006 9.86264C5.36291 9.40862 5.60257 8.99471 5.93515 8.66212C6.26774 8.32954 6.68165 8.08989 7.13566 7.96704C8.79775 7.51373 15.4873 7.5 15.4873 7.5C15.4873 7.5 22.1769 7.5 23.839 7.95329C24.293 8.07615 24.7069 8.31581 25.0395 8.64839ZM18.9076 15L13.3582 18.2143V11.7857L18.9076 15Z" fill="white"/>
                                    </svg>
                                </router-link>
                            </div>
                            <div class="flex items-center">
                                <router-link to="#" class="">
                                    <svg class="w-5" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" width="30" height="30" rx="5" fill="#3B5998"/><path fill-rule="evenodd" clip-rule="evenodd" d="M18.8259 10.1985C18.4009 10.1135 17.8269 10.05 17.4659 10.05C16.4884 10.05 16.425 10.475 16.425 11.155V12.3655H18.8684L18.6554 14.873H16.425V22.5H13.3655V14.873H11.793V12.3655H13.3655V10.8145C13.3655 8.69 14.364 7.5 16.871 7.5C17.742 7.5 18.3794 7.6275 19.2079 7.7975L18.8259 10.1985Z" fill="white"/>
                                    </svg>
                                </router-link>
                            </div>
                            <div class="flex items-center">
                                <router-link to="#" class="">
                                    <svg fill="none" class="w-5" viewBox="0 0 31 30">
                                      <rect width="30" height="30" x=".5" fill="#C13584" rx="5"/>
                                      <path fill="#fff" d="M15.5 8.85135c2.0026 0 2.2398.00765 3.0307.04373.7312.03335 1.1283.15553 1.3926.25824.3501.13605.5999.29858.8624.56101.2624.26246.425.51227.561.86237.1027.2643.2249.6614.2583 1.3927.036.7908.0437 1.028.0437 3.0306s-.0077 2.2398-.0437 3.0306c-.0334.7313-.1556 1.1284-.2583 1.3927-.136.3501-.2986.5999-.561.8623-.2625.2625-.5123.425-.8624.5611-.2643.1027-.6614.2249-1.3926.2582-.7907.0361-1.0279.0438-3.0307.0438-2.0027 0-2.2399-.0077-3.0306-.0438-.7313-.0333-1.1284-.1555-1.3927-.2582-.3501-.1361-.5999-.2986-.8623-.5611-.26247-.2624-.42502-.5122-.56108-.8623-.10271-.2643-.22489-.6614-.25823-1.3927-.03609-.7908-.04374-1.028-.04374-3.0306s.00765-2.2398.04374-3.0306c.03334-.7313.15552-1.1284.25823-1.3927.13606-.3501.29858-.59991.56108-.86234.2624-.26246.5122-.42499.8623-.56104.2643-.10271.6614-.22489 1.3927-.25824.7908-.03608 1.028-.04373 3.0306-.04373ZM15.5 7.5c-2.0369 0-2.2923.00863-3.0922.04513-.7983.03641-1.3435.16321-1.8206.3486-.4932.19166-.91143.44811-1.32841.86506-.41694.41698-.67339.83523-.86503 1.32841-.18542.4771-.31222 1.0223-.34863 1.8206C8.00863 12.7077 8 12.9631 8 15s.00863 2.2923.04513 3.0922c.03641.7983.16321 1.3435.34863 1.8206.19164.4932.44809.9114.86503 1.3284.41698.417.83521.6734 1.32841.865.4771.1855 1.0223.3123 1.8206.3487.7999.0365 1.0553.0451 3.0922.0451s2.2923-.0086 3.0923-.0451c.7983-.0364 1.3435-.1632 1.8205-.3487.4932-.1916.9115-.448 1.3285-.865.4169-.417.6734-.8352.865-1.3284.1854-.4771.3122-1.0223.3486-1.8206.0365-.7999.0452-1.0553.0452-3.0922s-.0087-2.2923-.0452-3.0922c-.0364-.7983-.1632-1.3435-.3486-1.8206-.1916-.49318-.4481-.91143-.865-1.32841-.417-.41695-.8353-.6734-1.3285-.86506-.477-.18539-1.0222-.31219-1.8205-.3486-.8-.0365-1.0554-.04513-3.0923-.04513Z"/>
                                      <path fill="#fff" d="M15.5037 11.1523c-2.1271 0-3.8514 1.7243-3.8514 3.8514 0 2.127 1.7243 3.8513 3.8514 3.8513s3.8514-1.7243 3.8514-3.8513c0-2.1271-1.7243-3.8514-3.8514-3.8514Zm0 6.3514c-1.3807 0-2.5-1.1193-2.5-2.5s1.1193-2.5 2.5-2.5 2.5 1.1193 2.5 2.5-1.1193 2.5-2.5 2.5Zm4.9018-6.506c0 .497-.403.9-.9.9-.4971 0-.9-.403-.9-.9 0-.4971.4029-.9.9-.9.497 0 .9.4029.9.9Z"/>
                                  </svg>
                              </router-link>
                          </div>
                      </div>
                    </div> 
                </div>
            </div>

          <div class="lg:flex md:flex justify-between px-4 py-2 uppercase text-xs md:text-sm space-x-6">
            <div>
              <label class="font-semibold">{{ $t('bottom-text1') }} © {{new Date().getFullYear()}} <!--Japan India Industry Promotion Association (NPO) All Rights Reserved.-->{{$t('all-rights-reserved')}}</label>
            </div>
            <div>
              <label class="font-semibold">{{ $t('bottom-text2') }}</label>
            </div>
          </div>
        </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'
import { mapMutations } from 'vuex';
import { mapState } from 'vuex';
import localforage from 'localforage'
export default {
  data: function() {
    return {
        setAuth : false,
    }
  },
  components: {

  },
   computed: {
     ...mapGetters({ 
        user: 'auth/user',
    }),
  },
   methods: {
    tokenCheck() {
        clearInterval(this.intervalId);
        localforage.getItem('authtoken').then((token) => {
            if(token) {
                this.setAuth = true;
            }else{
                this.setAuth = false;
            }
        })
    },
   },
   mounted() {
        this.tokenCheck();
    }
};
</script>


