<template>

  	<div class="vld-parent">
		<!-- <loading v-model:active="isLoading"
		:can-cancel="true"
		:color="color"
		:loader="loader"
		:opacity="opacity"
		:on-cancel="onCancel"
		:is-full-page="fullPage"/> -->

        <div class="min-h-screen w-screen bg-white bg-gradient-to-r from-teal-500 to-transparent fixed top--100 z-50  opacity-95 flex items-center justify-center" v-if="isLoading">
            <svg class="animate-spin mr-3 h-10 w-10 text-red-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25 text-red-300" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <span class="text-xs text-gray-500" v-html="loaderMessage" />
        </div>
        <div id="template_loader_indicator" class="blackground">
            <div class="template-loader-indicator"></div>
        </div>
		<div class="font-nunito header fixed w-full z-20 " id="navbar">
	    	<Header/>
	  	</div>

        <div class="font-nunito flex pt-24" v-if="user.authenticated && isNoSidebarPages()">
            <Sidebar class="fixed h-full" :class="[sidebarMenuClass]" id="sidebar" />
            <div :class="[sidebarMenuClass]"></div>

            <div class="mt-7 lg:mt-0 block lg:hidden">   
                <MobileSidebar/>
            </div>
            <div class="router-container mt-16 lg:mt-0" :class="[containerClass]" id="content-container">
                <div>
                    <router-view :key="$route.fullPath" /> 
                </div>
                <CustomerFooter/>
            </div>
        </div>

		<div class="font-nunito" v-else>            
            <router-view :key="$route.fullPath" />
            <FeedbackReviews/>    
            <ContactUs/>    
            <Footer/>
        </div>
       
         
	    <Alert/>
  	</div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Header from './components/partial/Header.vue'
import Footer from './components/partial/Footer.vue'
import CustomerFooter from './components/partial/CustomerFooter.vue'
import Alert from './components/partial/Alert.vue'
import FeedbackReviews from './app/feedbackReviews/components/FeedbackReviews.vue'


import ContactUs from './app/contactUs/components/ContactUs.vue'
import Sidebar from './components/partial/Sidebar.vue'
import MobileSidebar from './components/partial/MobileSidebar.vue'
import { mapGetters } from 'vuex'

export default {
	data() {
	    return {
	    	sidebarMenu: true,
	        sidebarMenuClass: 'w-sideWidth 2xl:w-2/12 lg:block hidden',
	        containerClass: 'w-full lg:w-sideWidth2 2xl:w-10/12',
	        // footerClass: 'w-full lg:w-10/12 2xl:w-12/12 mb-6 pl-1 xl:pl-14 2xl:pl-0',
	        // reviewClass: 'max-w-6xl 2xl:max-w-full pl-customPadding',
	    }
	},
	components: {
		Header,
   		FeedbackReviews,
   		ContactUs,
		Footer,
		CustomerFooter,
		Loading,
        Alert,
        Sidebar,
	    MobileSidebar,


	},
	methods: {
		onCancel() {
		},
        isNoSidebarPages() {
            var noSidebarPaths = ['/','/home', '/employer-profile/employer-membership-plan-tab','/employer-profile/employer-personal-tab','/employer-profile/employer-company-tab',
             '/employer-profile/employer-payment-tab','/change-password', '/job-seeker-profile/personal','/job-seeker-profile/education','/job-seeker-profile/employment','/job-seeker-profile/job-matching-criteria',
             '/about-us', '/employer', '/advertisements', '/advanced-search/job', '/job-seeker', '/titp', '/outsourcing-tender', '/job-fair', '/relocation-service', '/success','/paypal-payment-success','/paypal-payment-error', '/dashboard-home', '/request-relocation']
            if (noSidebarPaths.includes(this.$route.path)) {
                return false
            }
            return true
        },
		collapseSidebar: function() {
             this.sidebarMenu = !this.sidebarMenu
             if (this.sidebarMenu){
                this.sidebarMenuClass = 'w-sideWidth 2xl:w-2/12 lg:block hidden';
                this.containerClass = 'w-full lg:w-sideWidth2 2xl:w-10/12';
                // this.footerClass = 'w-full lg:w-10/12 2xl:w-12/12 mb-6 pl-1 xl:pl-14 2xl:pl-0';
                // this.reviewClass = 'max-w-6xl 2xl:max-w-full pl-customPadding';
             }
             else {
                this.sidebarMenuClass = 'w-16 lg:block hidden';
                this.containerClass = 'w-full lg:w-12/12 pl-10';                
                // this.footerClass = 'w-full';                
                // this.reviewClass = 'max-w-6xl 2xl:max-w-full xl:pl-customCollapsePadding';                
             }
        }  
	},
	computed: {
        ...mapGetters({
          user: 'auth/user',
        }),
        ...mapState([
            'isLoading',
            'loaderMessage',
            'color',
            'loader',
            'opacity',
            'fullPage',
            'isAuth',
            'isCollapse',
        ]),
    },
	mounted() {
		this.$store.commit('IS_LOADING', true)
		// simulate AJAX
		setTimeout(() => {
			this.$store.commit('IS_LOADING', false)
		}, 50);
        
        let hideScroll = window.pageYOffset;
        window.onscroll = function() {
            let currentScrollPos = window.pageYOffset;

            if (currentScrollPos < 3) {
                let navbar = document.getElementById("navbar");
                let sidebar = document.getElementById("sidebar");
                let hideBtns = document.getElementById("hideBtns");
                let titleFixed = document.getElementById("title_fixed");

                if (navbar) {
                    navbar.style.top = "0";
                }
                if (sidebar) {
                    sidebar.style.top = "120px";
                }
                if (hideBtns) {
                    hideBtns.style.display = "block";
                }
                if (titleFixed) {
                    titleFixed.style.top = "2.8rem";
                    titleFixed.style.position = "static";
                    titleFixed.style.boxShadow = "none";
                }

            } else {
                let navbar = document.getElementById("navbar");
                let sidebar = document.getElementById("sidebar");
                let hideBtns = document.getElementById("hideBtns");
                let titleFixed = document.getElementById("title_fixed");

                if (navbar) {
                    navbar.style.top = "-165px";
                }
                if (sidebar) {
                    sidebar.style.top = "0";
                }
                if (hideBtns) {
                    hideBtns.style.display = "none";
                }
                if (titleFixed) {
                    titleFixed.style.top = "0";
                    titleFixed.style.position = "sticky";
                    titleFixed.style.boxShadow = "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)";
                }
            }
        };

	}
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: 0.1s all ease-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

#navbar {
  transition: top 0.3s;
}

#sidebar {
  transition: top 0.3s;
}
.router-container{    
    transition: 0.4s ease;      
  }
.template-loader-indicator {
  border: 16px solid #ef4444;
  border-top: 16px solid #fff;
  border-radius: 50%;
  width: 136px;
  height: 136px;
  animation: spin 2s linear infinite;
  position: relative;
  top: 200px;
  margin: auto;
}
.blackground{
    position: fixed;
	z-index: 999999;
	padding-top:100px;
	left: 0;
	top:0;
	width: 100%;
	height:100%;
	overflow: auto;
	background-color:rgba(0 ,0 ,0 ,0.4);
    display: none;
}
</style>