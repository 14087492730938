// GET INCLUSIONS 
export const getShowInclusion = (state)  => {
  return state.inclusions.showInclusion
}
export const getShowWithPlanInclusion = (state) => {
  return state.inclusions.showWithPlanInclusion;
}
export const getShowTitpInclusion = (state) =>  {
  return state.inclusions.showTitpInclusion
}
export const getShowSmartPlannerInclusion = (state)  => {
  return state.inclusions.showSmartPlannerInclusion
}
export const getEmployerData = (state)  => {
  return state.employerdata;
}
export const getPaymentData = (state) => {
  return state.paymentData;
}
