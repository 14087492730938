export default {
	user: {
		token: null,
		authenticated: false,
		data: [],
		roleName: null,
		roleSlug: null,
		email: "",
		uuid: "",
		type: "",
		webLang:null,
		bellNotifications: {
			count: 0,
			messages: []
		}
	},
	state:{
		showTermsConditions:false,
		hideTermsConditions:false,
		showVerifyOtpModel:false,
	},
}
