export const getScoutInboxData = (state) => {
	return state.scout_inbox.data
}

export const getScoutInboxLinks = (state) => {
	return state.scout_inbox.links
}

export const getScoutInboxMeta = (state) => {
	return state.scout_inbox.meta
}

export const getScoutSendboxData = (state) => {
	return state.scout_sendbox.data
}

export const getScoutSendboxLinks = (state) => {
	return state.scout_sendbox.links
}

export const getScoutSendboxMeta = (state) => {
	return state.scout_sendbox.meta
}

export const getShowScoutMailModal = (state) =>{
	return state.showScoutMailModal
}

export const getHideScoutMailModal = (state) =>{
	return state.hideScoutMailModal
}

export const getUserDetail = (state) => {
	return state.scout_mail.detail
}

export const getJobId = (state) => {
	return state.scout_mail.jobId
}

export const getShowDeleteScoutModal = (state) =>{
	return state.showDeleteScoutModal
}

export const getHideDeleteScoutModal = (state) =>{
	return state.hideDeleteScoutModal
}

export const getId = (state) => {
	return state.scout_mail.id
}

export const getType = (state) => {
	return state.scout_mail.type
}

export const getScoutDataById = (state) => {
	return state.scout_mail.data
}