export const getshowJobsCategory = (state) => {
	return state.showJobsCategory 
}

export const getshowRefferalCategory = (state) => {
	return state.showRefferalCategory 
}

export const getshowJobFairCategory = (state) => {
	return state.showJobFairCategory 
}

export const getshowAdvertisementCategory = (state) => {
	return state.showAdvertisementCategory 
}

export const getshowAppliedJobs = (state) => {
	return state.showAppliedJobs
}

export const getshowMatchingJobs = (state) => {
	return state.showMatchingJobs
}

export const getshowSubscribeJobFairCategory = (state) => {
	return state.showSubscribeJobFairCategory
}
export const getDashboardAnalytics = (state) => {
	return state.dashboardAnalytics
}