export default [
	{
		path: '/users',
		name: 'user-list',
		component: () => import('../components/UserList.vue'),
		meta: {
			guest: false,
			needsAuth: true
		}
	},
	{
		path: '/users/new-user',
		name: 'new-user',
		component: () => import('../components/AddNewUser.vue'),
		meta: {
			guest: false,
			needsAuth: true
		}
	},
	{
		path: '/users/edit/:id',
		name: 'edit-user-by-id',
		component: () => import('../components/EditUserList.vue'),
		meta: {
			guest: false,
			needsAuth: true
		}
	},
	{
		path: '/users/view/:id',
		name: 'view-user-by-id',
		component: () => import('../components/EditUserList.vue'),
		meta: {
			guest: false,
			needsAuth: true
		}
	},
	{
		path: '/users/deleted-users',
		name: 'deleted-user-list',
		component: () => import('../components/DeleteduserList.vue'),
		meta: {
			guest: false,
			needsAuth: true
		}
	},
];