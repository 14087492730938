export const postPersonalData = ({ commit }, {context, payload}) => {
  return window.axios
    .post(process.env.VUE_APP_SMART_RECRUIT_API_URL+'api/employer-profile-personal',payload)
    .then((response) => {
      
      return response.data;
    })
    .catch((error) => {
      context.isbeingSaved = false;
      context.isbeingNext = false;
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });       
};
// secondary post api(sso api) for employer personal and company
export const ssoUserDataPost = ({ commit }, {context, payload}) => {
  return window.axios.post(
    process.env.VUE_APP_SSO_API_URL+"api/sso-user/update-details/"+payload.email,payload
  ).then((response) => {
    return response.data;
  }).catch((error) => {
     commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
     context.isbeingSaved = false;
     context.isbeingNext = false;
     return false;
  });
}
export const postCompanyData = ({ commit }, {context, payload}) => {
  return window.axios
    .post(process.env.VUE_APP_SMART_RECRUIT_API_URL+'api/employer-profile-company',payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", error.response.data.message, { root: true });
      context.isbeingSaved = false;
      context.isbeingNext = false;
      return false;
    });       
};
export const savePaymentDetails = ({ commit }, {context, payload}) => {
  return window.axios
    .post(process.env.VUE_APP_SMART_RECRUIT_API_URL+'api/employer-profile-payment',payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      context.isbeingSaved = false;
      context.isbeingNext = false;
      return false;
    });       
};
export const getUserAgreementSubscriptionData = ({ commit }, payload) => {
  return window.axios
    .post(process.env.VUE_APP_SMART_RECRUIT_API_URL+'api/stripe/create',payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });       
};
export const convertCurrency = ({ commit }) => {
  return window.axios
    .get(process.env.VUE_APP_SSO_API_URL+'api/get-converted-usd-to-jpy-rate')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });       
};
export const retriveStripData = ({ commit }, {payload}) => {
  return window.axios
    .get(process.env.VUE_APP_SMART_RECRUIT_API_URL+'api/retrive-stripe-data?session_id='+payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });       
};
export const stripeSuccess = ({ commit }, {payload}) => {
  return window.axios
    .post(process.env.VUE_APP_SMART_RECRUIT_API_URL+'api/stripe-payment-success',payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
    });       
};

